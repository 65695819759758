import '@/i18n/i18n';
import { RootStoreType } from '@/RootStoreTypes';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';

export const ui = (store: RootStoreType) => {
    const el = window.document.getElementById('root');
    if (!el) {
        throw new Error('root element is missing in html');
    }

    const root = createRoot(el);
    root.render(<App {...{ store }} />);
    return root;
};
