import { PointType } from './Layouter.types';

const cmpBy = <T>(a: T, b: T, selector: (t: T) => number) => {
    const _a = selector(a);
    const _b = selector(b);

    if (_a < _b) {
        return -1;
    } else if (_a > _b) {
        return 1;
    } else {
        return 0;
    }
};

const cmpPoint = (a: PointType, b: PointType) => {
    const byTime = cmpBy(a, b, p => p.time);
    if (!byTime) {
        return cmpBy(a, b, p => p.price);
    } else {
        return 0;
    }
};

export class Layouter {
    calcPositions(
        points: PointType[],
        serverTime?: number,
        force?: boolean,
    ): PointType[] {
        const sorted = [...points].sort(cmpPoint);
        const outputPoints: PointType[] = [];
        for (let i = sorted.length - 1; i >= 0; i--) {
            outputPoints.unshift(
                this.calcPoint(sorted[i], outputPoints, serverTime, force),
            );
        }
        return outputPoints;
    }

    private calcPoint(
        target: PointType,
        outputPoints: PointType[],
        serverTime?: number,
        force?: boolean,
    ): PointType {
        if (force && serverTime) target.time = serverTime;
        const relatedPoint = outputPoints.find(point =>
            this.areRelated(point, target),
        );

        if (relatedPoint) {
            return {
                price: target.price,
                time: Math.min(
                    target.time,
                    relatedPoint.time - this.candleStickMove(target),
                ),
                width: target.width,
                height: target.height,
            };
        } else {
            return {
                ...target,
                time: target.time - this.candleStickMove(target),
            };
        }
    }

    private areRelated(a: PointType, b: PointType) {
        if (b.height) return Math.abs(a.price - b.price) < b.height;
    }

    private candleStickMove(target: PointType) {
        if (target?.width) return Math.ceil(target.width);
        return 0;
    }
}
