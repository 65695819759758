import ArrowDown from '@/assets/icons/arrowDownRed.svg';
import ArrowUp from '@/assets/icons/arrowUpGreen.svg';
import { formatVolume } from '@/tools/format';
import { ToastContainer } from '@/view/Toasts/ToastContainer';
import { TFuncKey } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { betToastStyles } from './BetToast.css';
import { BetCreatedProps } from './BetToast.types';

export const BetToast = ({
    isUp,
    symbol,
    volume,
    borderColor,
    icon,
    header,
    headerValues,
    volumeText,
    win,
    decimals,
    resolve,
}: BetCreatedProps) => {
    const { t } = useTranslation();

    const direction = (isUp ? 'toasts.higher' : 'toasts.lower') as TFuncKey;

    const arrowIcon = isUp ? ArrowUp : ArrowDown;

    const volumeColor = win ? 'green' : 'white';

    return (
        <ToastContainer
            header={header}
            headerValues={headerValues}
            borderColor={borderColor}
            icon={icon}
            onClose={() => resolve()}>
            <div className={betToastStyles.description}>
                <span className={betToastStyles.special('white')}>
                    {symbol}
                </span>
                &nbsp;
                <span>{t('toasts.toGo')}</span>&nbsp;
                <img
                    src={arrowIcon}
                    className={betToastStyles.arrow}
                    alt="direction"
                />
                &nbsp;
                <span className={betToastStyles.special('white')}>
                    {t(direction).toString()}
                </span>
            </div>
            <p>
                {t(volumeText).toString()}:{' '}
                <span className={betToastStyles.special(volumeColor)}>
                    ${formatVolume(volume, decimals)}
                </span>
            </p>
        </ToastContainer>
    );
};
