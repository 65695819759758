import { BetConfigType, MarketType } from '@/markets/MarketsStore.types';
import { RootStoreType } from '@/RootStoreTypes';
import { min, mulFloat } from '@/tools/big-math';
import { toBI } from '@/tools/big-number';
import { mayBeThen } from '@/tools/maybe';
import { Subject } from 'rxjs';
import { BetInputStoreTypes, Direction } from './BetInputStore.types';

export class BetInputStore implements BetInputStoreTypes {
    volumeStr: string | null = null;
    volume: bigint | null = null;
    volume$ = new Subject<bigint>();

    volumePercentage = [0.05, 0.1, 0.2, 0.5, 1];

    get betConfigs() {
        return this.market?.configs ?? [];
    }

    betConfig$ = new Subject<BetConfigType | null>();

    betConfigIdx: number | null = null;

    get betConfig() {
        return this.betConfigIdx === null
            ? null
            : this.betConfigs[this.betConfigIdx];
    }

    get isPriceDefined() {
        return this.market?.price !== undefined;
    }

    setBetConfig(betConfig: BetConfigType) {
        const idx = this.betConfigs.indexOf(betConfig);
        this.betConfigIdx = idx < 0 ? null : idx;
        this.betConfig$.next(betConfig);

        if (this.volumeStr) {
            this.setVolumeFromStr(this.volumeStr);
        }
    }

    hoverDirection$ = new Subject<Direction | null>();

    constructor(private root: RootStoreType) {
        this.setDefaultConfig();
        this.setDefaultAmount();
    }

    private get defaultVolume() {
        return mulFloat(
            min(
                this.balance ?? 0n,
                this.root.config.defaultValues.user.defaultVolume,
            ),
            this.volumePercentage[0],
        );
    }

    private setDefaultConfig() {
        this.root.markets.ready.then(() => {
            this.root.ui.betsPage.market$.subscribe(() => {
                if (!this.betConfig) this.setBetConfig(this.betConfigs[0]);
            });
        });
    }

    private setDefaultAmount() {
        this.root.user.user$.subscribe(user => {
            user?.ready.then(() => {
                this.setVolume(this.defaultVolume);
            });
        });
    }

    private setVolume(volume: bigint) {
        this.setVolumeStr(this.asset?.toReadableString(volume) ?? '');
    }

    private setVolumeFromStr(volumeStr: string) {
        const volume = this.numberString2number(volumeStr);

        this.volume = volume;

        this.volume$.next(volume);
    }

    setVolumeStr(volumeStr: string): void {
        this.volumeStr = volumeStr;
        this.setVolumeFromStr(volumeStr);
    }

    get user() {
        return this.root.user.user;
    }

    get asset() {
        return (
            mayBeThen(this.betConfig?.settlementAssetId, id =>
                this.root.user.user?.assets.get(id),
            ) ?? null
        );
    }

    private get balance() {
        return mayBeThen(this.asset, a => this.user?.getBalance(a)) ?? null;
    }

    private numberString2number(volumeStr: string): bigint {
        if (!volumeStr) {
            return 0n;
        }
        return this.asset?.fromReadable(volumeStr) ?? 0n;
    }

    onBetButtonHover(value: Direction | null) {
        this.hoverDirection$.next(value);
    }

    get canTakeBet() {
        return (
            this.betConfig !== null &&
            this.volume !== null &&
            this.volume >= toBI(this.betConfig.betMin) &&
            this.volume <= toBI(this.betConfig.betMax) &&
            this.volume <= this.availableCollateral
        );
    }

    get market() {
        return this.root.ui.betsPage.market;
    }

    get period() {
        return this.betConfig?.betDurationSec ?? null;
    }

    private checkBeforeTakeBet(
        take: (
            market: MarketType,
            volume: bigint,
            config: BetConfigType,
        ) => void,
    ) {
        if (!this.asset) {
            throw new Error('asset is not defined');
        }
        // This logic may be moved to use-cases
        if (this.enoughBalance < 0)
            return this.root.ui.toasts.betInsufficientFunds(
                this.enoughBalance,
                this.asset.decimals,
            );
        if (this.volume === null)
            throw new Error('missing parameters: no volume');
        if (!this.betConfig)
            throw new Error('missing parameters: no betConfig');
        if (!this.market) throw new Error('missing parameters: no market');

        const betMin = toBI(this.betConfig.betMin);
        const betMax = toBI(this.betConfig.betMax);

        if (this.volume < betMin || this.volume > betMax) {
            this.incorrectAmountToast(betMin, betMax, this.asset.decimals);
            // TODO: add deposit use case
            return;
        }

        return take(this.market, this.volume, this.betConfig);
    }

    private takeBet(isUp: boolean) {
        return this.checkBeforeTakeBet((market, volume, betConfig) => {
            this.root.useCases.takeBet(market, volume, betConfig, isUp);
        });
    }

    takeBetDown() {
        this.takeBet(false);
    }
    takeBetUp() {
        this.takeBet(true);
    }

    get availableCollateral() {
        return this.balance ?? 0n;
    }

    setVolumeByAvailable(proc: number) {
        this.setVolume(mulFloat(this.availableCollateral, proc));
    }

    get activeVolumePercentage() {
        return this.volumePercentage.find(
            pc => this.volume === mulFloat(this.availableCollateral, pc),
        );
    }

    incorrectAmountToast(min: bigint, max: bigint, decimals: number) {
        this.root.ui.toasts.betIncorrectAmount(min, max, decimals);
    }

    get enoughBalance(): bigint {
        return this.availableCollateral - (this.volume ?? 0n);
    }
}
