import { RootStoreType } from '@/RootStoreTypes';
import { toBI } from '@/tools/big-number';
import { formatVolume } from '@/tools/format';
import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const useAmount = (
    store: RootStoreType | null,
): string | ReactNode | undefined => {
    const { t } = useTranslation();

    if (!store || !store?.user.user?.isAuthorised) {
        return;
    }

    const betInput = store.ui.betsPage.betInput;

    if (!betInput.betConfig) return;

    const availableCollateral = betInput.availableCollateral;
    const volume = betInput.volume;

    const betMin = toBI(betInput.betConfig.betMin);
    const betMax = toBI(betInput.betConfig.betMax);

    if (!availableCollateral) {
        return t('inputError.noCollateral').toString();
    }

    if (!volume) {
        return t('inputError.noVolume').toString();
    }

    if (volume < betMin || volume > betMax) {
        return (
            <Trans
                i18nKey="inputError.wrongAmount"
                values={{
                    min: formatVolume(betMin, betInput.asset?.decimals),
                    max: formatVolume(betMax, betInput.asset?.decimals),
                }}
            />
        );
    }

    if (volume > availableCollateral) {
        return t('inputError.noCollateral').toString();
    }
};
