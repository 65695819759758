import { RootStoreType } from '@/RootStoreTypes';
import routes from '@/config/routes';
import { SerializableValue } from '@/storage/Storage.types';
import { ResolutionString, widget } from '@charting-library/';
import { Subscription } from 'rxjs';
import { CenterChart } from './CenterChart';
import { DatafeedStub } from './DataFeed';
import { StrikePrice } from './StrikePrice/StrikePrice';
import { StrikePriceType } from './StrikePrice/StrikePrice.types';
import { StrikePriceRectangle } from './StrikePriceRectangle';

export class StubWidget extends widget {
    marketSubscription?: Subscription;
    hoverSubscription?: Subscription;
    strikePrice: StrikePriceType | null = null;
    addToFavorite = false;

    strikePriceRectangle?: StrikePriceRectangle | null;
    centerChart?: CenterChart;

    constructor(
        private root: RootStoreType,
        private container: HTMLDivElement,
        symbol: string,
    ) {
        super({
            container,
            locale: 'en',
            symbol: symbol,
            library_path: '/charting_library/charting_library/',
            interval: root.config.defaultValues.chart
                .timeframe as ResolutionString,
            datafeed: new DatafeedStub(root),
            autosize: true,
            theme: 'Dark',
            custom_css_url: '/chart.css',
            overrides: {
                'mainSeriesProperties.style':
                    root.config.defaultValues.chart.type,
                'mainSeriesProperties.priceLineColor': '#3B496C',
                'mainSeriesProperties.priceLineWidth': 2,
                'mainSeriesProperties.showCountdown': true,
                'paneProperties.background': '#1C1F28',
                'paneProperties.backgroundType': 'solid',
            },
            enabled_features: ['seconds_resolution'],
            disabled_features: root.device.isMobile
                ? [
                      'left_toolbar',
                      'control_bar',
                      'legend_widget',
                      'context_menus',
                      'header_chart_type',
                      'header_settings',
                      'header_indicators',
                      'header_compare',
                      'header_undo_redo',
                      'header_fullscreen_button',
                  ]
                : undefined,
            symbol_search_complete: symbol => {
                this.root.ui.betsPage.favoriteMarkets.addBySymbol(symbol);

                return Promise.resolve(symbol);
            },
        });

        this.init();
    }

    private setResolution(resolution: ResolutionString) {
        this.activeChart().setResolution(resolution);
        localStorage.removeItem(
            this.root.config.defaultValues.chart.tradingviewResolutionKey,
        );
    }

    private setOverrides(symbol: string | undefined) {
        if (this.root.config.defaultValues.chart.overrides && symbol) {
            const overrides =
                this.root.config.defaultValues.chart.overrides[
                    symbol.toLowerCase()
                ];

            let localStorageTimeframe: SerializableValue | null = null;

            try {
                localStorageTimeframe = this.root.storage.getItem(
                    this.root.config.defaultValues.chart
                        .tradingviewResolutionKey,
                );
            } catch (e) {
                // do nothing
            }

            // (!localStorageTimeframe) = do not update resolution, because user changed it by yourself

            if (!overrides && !localStorageTimeframe) {
                this.setResolution(
                    this.root.config.defaultValues.chart.timeframe,
                );
            }

            if (overrides && overrides.timeframe && !localStorageTimeframe) {
                this.setResolution(overrides.timeframe);
            }
        }
    }

    init() {
        this.onChartReady(() => {
            this.marketSubscription = this.root.ui.betsPage.market$?.subscribe({
                next: market => {
                    market && this.activeChart().setSymbol(market?.symbol);
                },
            });
            this.activeChart()
                .onSymbolChanged()
                .subscribe(null, () => {
                    const { symbol } = this.activeChart().symbolExt();

                    const market =
                        this.root.markets.marketsByFormattedSymbol.get(symbol);

                    this.root.router.goTo(routes.bets, {
                        symbol: market?.symbol,
                    });

                    this.setOverrides(market?.symbol);
                });

            this.setOverrides(this.root.ui.betsPage.market?.symbol);

            this.root.ui.betsPage.favoriteMarkets.addSelected$.subscribe(() => {
                this.activeChart().executeActionById('symbolSearch');
            });

            this.centerChart = new CenterChart(
                this.activeChart(),
                this.container,
            );

            this.strikePrice = new StrikePrice(
                this.root,
                this.activeChart(),
                this.subscribe.bind(this),
                this.unsubscribe.bind(this),
            );
            this.strikePrice.drawStrikePrice();

            this.hoverSubscription =
                this.root.ui.betsPage.betInput.hoverDirection$.subscribe({
                    next: direction => {
                        this.strikePriceRectangle?.destroy();
                        if (direction && !this.root.device.isMobile) {
                            this.strikePriceRectangle =
                                new StrikePriceRectangle(
                                    this.root,
                                    this.activeChart(),
                                    direction,
                                );
                        } else {
                            this.strikePriceRectangle = null;
                        }
                    },
                });
        });
    }

    remove(): void {
        this.strikePrice?.destroy();
        this.marketSubscription?.unsubscribe();
        this.hoverSubscription?.unsubscribe();
        this.strikePriceRectangle?.destroy();
        this.centerChart?.destroy();
        this.root.ui.betsPage.highlightSymbol.destroy();
        super.remove();
    }
}
