import { RootStoreType } from '@/RootStoreTypes';
import { addr } from '@/tools/addr';
import { TFunction } from 'i18next';
import { ProfileStoreType } from './ProfileStore.types';

export class ProfileStore implements ProfileStoreType {
    isOpen = false;

    constructor(private root: RootStoreType) {}

    async onTriggerClick() {
        if (this.address || this.root.mode === 'local') {
            this.isOpen = !this.isOpen;
        } else {
            return this.root.useCases.startWalletConnection();
        }
    }

    get address() {
        return this.root.wallet.address;
    }

    get walletName() {
        return this.root.wallet.name;
    }

    get availableAssets() {
        return [...(this.root.user.user?.assets.values() ?? [])];
    }

    get ballance() {
        return (
            this.root.contracts.ierc20Usdc.mainWalletBallance?.toString() ?? '-'
        );
    }

    get isDemo() {
        return this.root.mode === 'local';
    }

    getWalletAddressStr(t: TFunction) {
        if (this.address) {
            return addr(this.address);
        } else {
            if (this.isDemo) {
                return t('profilePopup.demoBtn');
            }
        }
    }

    onClickChange() {
        this.isOpen = false;
        return this.root.useCases.startWalletConnection();
    }

    onClickDeposit() {
        return this.root.useCases.startDeposit();
    }

    onClickWithdraw() {
        return this.root.useCases.startWithdraw();
    }

    onClickTopUp() {
        this.isOpen = false;
        return this.root.useCases.startTopUp();
    }

    onClickReset() {
        this.isOpen = false;
        return this.root.useCases.resetVirtualAccount();
    }

    closeProfile() {
        this.isOpen = false;
    }
}
