import { joinClassnames } from '@/tools/css';

export const TabStyles = {
    tab: (isActive: boolean, isMobile: boolean) =>
        joinClassnames([
            'text-sm',
            'h-full',
            'px-3',
            isMobile ? 'py-4' : 'py-2.5',
            'cursor-pointer',
            'transition-all',
            isActive ? 'text-greyscale-primary' : 'text-greyscale-placeholder',
            'first:pl-4',
            'last:pr-0',
            isMobile ? 'text-mobile-buttons-medium' : '',
            isActive ? 'font-semibold' : 'font-medium',
        ]),
};
