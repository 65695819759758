import { className, joinClassnames } from '@/tools/css';
import { Mode } from './WalletConnector.types';

export const walletConnectorStyles = {
    mode(mode?: Mode) {
        switch (mode) {
            case 'error':
                return 'border border-error-default';
            case 'success':
                return 'border border-success-default';
            case 'selected':
                return 'border border-greyscale-secondary';
            default:
                return null;
        }
    },
    main(mode?: Mode) {
        return className(
            'rounded-md',
            'flex',
            'flex-row',
            'justify-between',
            'items-center',
            'text-greyscale-primary',
            'bg-greyscale-input',
            'gap-4',
            'p-2',
            'text-sm',
            'leading-8',
            'capitalize',
            'cursor-pointer',
            this.mode(mode),
        );
    },
    icon: joinClassnames([
        'flex',
        'justify-center',
        'items-center',
        'rounded-md',
        'w-8',
        'h-8',
        'bg-greyscale-disabled',
    ]),
    iconImg: joinClassnames(['max-w-6', 'max-h-6']),
};
