import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { Labeled } from '@/components/Labeled';
import { ModalContainer } from '@/components/Modal/ModalContainer';
import { addr } from '@/tools/addr';
import { useVolumeRifm } from '@/tools/rifm/volumeRifm';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../storeContext';
import { LOGO_MAP } from './walletIcon';

export const DepositModal: React.FC = observer(() => {
    const store = useStore();
    const { t } = useTranslation();

    const modal = store?.ui.modals.activeModal;
    const profile = store?.ui.nav.profile;

    const networkFee = store?.contracts.ierc20Usdc.networkFee;

    if (modal && modal.type === 'deposit') {
        const onClose = () => modal.resolve(0n);

        return (
            <ModalContainer
                header={t('depositModal.modalHeading')}
                onClose={onClose}
                footer={
                    <Button
                        disabled={!modal.value}
                        className="w-full"
                        onClick={() => modal.resolve(modal.value ?? 0n)}>
                        {t('depositModal.deposit')}
                    </Button>
                }>
                <div className="bg-greyscale-input p-2 rounded-md mb-6 flex justify-between items-center">
                    <div className="flex flex-col">
                        <p className="text-greyscale-placeholder text-xs font-medium">
                            <span>{t('depositModal.connectedWith')} </span>
                            <span className="capitalize">
                                {profile?.walletName}
                            </span>
                        </p>
                        <span className="text-greyscale-primary text-base font-semibold">
                            {addr(profile?.address ?? '')}
                        </span>
                    </div>
                    <div className="w-8 h-8 bg-greyscale-disabled rounded-md flex items-center justify-center">
                        <img
                            src={LOGO_MAP[profile?.walletName ?? '']}
                            className="max-w-6 max-h-6"
                            alt="wallet"
                        />
                    </div>
                </div>
                <Labeled label={t('depositModal.amount')}>
                    <Input
                        {...useVolumeRifm(modal.valueStr ?? '', v =>
                            modal.setValueStr(v),
                        )}
                        inputMode="decimal"
                    />
                </Labeled>

                <div className="border border-greyscale-line p-2 mt-6 rounded-md">
                    <div className="flex justify-between items-center mb-2">
                        <span className="text-greyscale-placeholder text-xs font-medium">
                            {t('depositModal.amount')}
                        </span>
                        <span className="text-greyscale-primary text-xs font-semibold">
                            {' '}
                            {modal.valueStr ?? '-'} USDC
                        </span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-greyscale-placeholder text-xs font-medium">
                            {t('depositModal.networkFee')}
                        </span>
                        <span className="text-greyscale-primary text-xs font-semibold">
                            {networkFee} GWEI
                        </span>
                    </div>
                </div>
            </ModalContainer>
        );
    } else {
        return null;
    }
});
