import { BetType } from '@/bets/BetsStore.types';
import routes from '@/config/routes';
import { MarketType } from '@/markets/MarketsStore.types';
import { RootStoreType } from '@/RootStoreTypes';
import { FavoriteMarketType } from './FavoriteMarketsStore.types';

export class FavoriteMarket implements FavoriteMarketType {
    constructor(private root: RootStoreType, public market: MarketType) {}

    private get firstBet(): BetType | undefined {
        return this.root.bets.bets.find(
            bet => bet.market?.symbol === this.market.symbol && !bet.isOld,
        );
    }

    get isSelected() {
        return this.market.symbol === this.root.ui.betsPage.market?.symbol;
    }

    get isWinning() {
        switch (this.firstBet?.status) {
            case 'active':
                return this.firstBet.isWinning;
            case 'win':
                return true;
            case 'lose':
                return false;
        }
    }

    get volumeChange() {
        switch (this.firstBet?.status) {
            case 'win':
                return this.firstBet.mayWin;
            case 'lose':
                return this.firstBet.volume;
            default:
                return undefined;
        }
    }

    select() {
        const symbol = this.market.symbol;
        this.root.ui.betsPage.highlightSymbol.highlight(
            this.root.config.defaultValues.tradingViewIframe,
            this.root.config.defaultValues.pairHighlight,
        );
        this.root.router.goTo(routes.bets, { symbol });
    }

    remove() {
        return this.root.ui.betsPage.favoriteMarkets.remove(this.market);
    }
}
