import CloseIcon from '@/assets/icons/close.svg';
import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { getContainerClassname, toastStyles } from './Toast.css';
import { ToastProps } from './Toast.types';

export const ToastContainer: FC<ToastProps> = ({
    icon,
    header,
    headerValues,
    borderColor,
    children,
    onClose,
}) => {
    return (
        <div className={getContainerClassname(borderColor)}>
            <div className={toastStyles.main}>
                <div className={toastStyles.header}>
                    <img src={icon} alt="type" />
                    <span className={toastStyles.headerText}>
                        <Trans i18nKey={header} values={headerValues} />
                    </span>
                </div>
                {children}
            </div>
            <button
                name="toast-close"
                className={toastStyles.close}
                onClick={onClose}>
                <img src={CloseIcon} alt="close" />
            </button>
        </div>
    );
};

ToastContainer.displayName = 'ToastContainer';
