import linesImage from '@/assets/lines.png';
import notFoundImage from '@/assets/not-found.svg';
import { Button } from '@/components/Button';
import routes from '@/config/routes';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../storeContext';
import { notFoundStyles } from './NotFound.css';

export const NotFound = observer(() => {
    const { t } = useTranslation();
    const store = useStore();

    const handleClick = () => {
        const symbol = store?.markets.markets.values().next().value?.symbol;
        store?.router.goTo(routes.bets, { symbol });
    };

    const isMobile = store?.device.isMobile;

    return (
        <div className={notFoundStyles.container(isMobile)}>
            <div className={notFoundStyles.textContainer(isMobile)}>
                <h1 className={notFoundStyles.heading}>
                    {t('notFound.heading')}
                </h1>
                <p className={notFoundStyles.text}>{t('notFound.text')}</p>
                <Button className={notFoundStyles.btn} onClick={handleClick}>
                    {t('notFound.btn')}
                </Button>
            </div>
            {!isMobile && (
                <div className={notFoundStyles.imageContainer}>
                    <img
                        src={linesImage}
                        alt="lines"
                        className={notFoundStyles.lines}
                    />
                    <img
                        src={notFoundImage}
                        alt="not found"
                        className={notFoundStyles.textImage}
                    />
                </div>
            )}
        </div>
    );
});
