import LoadingIcon from '@/assets/icons/loading.svg';
import { useClassName } from '@/tools/css';
import React, { FC } from 'react';
import { getButtonClassname, loadingStyles } from './Button.css';
import { ButtonProps } from './Button.types';

const Loading = () => {
    return (
        <div className={loadingStyles.loadingWrapper}>
            <img className="animate-spin" src={LoadingIcon} alt="loading" />
        </div>
    );
};

export const Button: FC<ButtonProps> = ({
    children,
    variant = 'primary',
    className,
    isLoading = false,
    isMobile = false,
    href,
    ...props
}) => {
    const cssClassName = useClassName(
        getButtonClassname(variant, isMobile),
        className,
    );

    if (href) {
        return (
            <a
                href={href}
                className={cssClassName}
                target="_blank"
                rel="noreferrer">
                {isLoading ? <Loading /> : children}
            </a>
        );
    }
    return (
        <button className={cssClassName} {...props}>
            {isLoading ? <Loading /> : children}
        </button>
    );
};

Button.displayName = 'Button';
