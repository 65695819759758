import { Input } from '@/components/Input';
import { MobileOverlay } from '@/components/MobileOverlay/MobileOverlay';
import { NavigationTabs } from '@/components/NavigationTabs/NavigationTabs';
import { useOnClickOutside } from '@/tools/hooks/useOnClickOutside';
import { useVolumeRifm } from '@/tools/rifm/volumeRifm';
import { useStore } from '@/view/storeContext';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAppend } from '../InputAppend/InputAppend';

export const Amount = observer(() => {
    const store = useStore();
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    if (!store) return null;

    const betInput = store.ui.betsPage.betInput;

    const volumePercentage = betInput?.volumePercentage ?? [];

    const items = volumePercentage.map(item => ({
        value: item,
        label: item === 1 ? t('betInput.max') : `${item * 100}%`,
    }));

    const switchOpen = useCallback(() => {
        setOpen(prev => !prev);
    }, []);

    const handleClick = useCallback(
        (value: number) => {
            switchOpen();
            betInput.setVolumeByAvailable(value);
        },
        [betInput, switchOpen],
    );

    useOnClickOutside(ref, () => {
        setOpen(false);
    });

    return (
        <div ref={ref}>
            <Input
                {...useVolumeRifm(betInput.volumeStr ?? '', v =>
                    betInput.setVolumeStr(v),
                )}
                className="!h-10"
                inputMode="decimal"
                append={<InputAppend onClick={switchOpen} isActive={isOpen} />}
            />
            <NavigationTabs
                items={items}
                onClick={handleClick}
                active={betInput?.activeVolumePercentage ?? null}
                isOpen={isOpen}
                className="absolute bottom-full left-0 z-10"
            />
            <MobileOverlay active={isOpen} />
        </div>
    );
});
