import { AnyToast } from '@/toasts/ToastsStore.types';
import { useStore } from '@/view/storeContext';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { BetIncorrectAmount } from './BetIncorrectAmount/BetIncorrectAmount';
import { BetInsufficientFunds } from './BetInsufficientFunds/BetInsufficientFunds';
import { BetToast } from './BetToast';
import { ServerErrorToast } from './ServerErrorToast/ServerErrorToast';
import { toastsStyles } from './Toasts.css';

export const Toast = observer((toast: AnyToast) => {
    switch (toast.type) {
        case 'betCreated':
        case 'multipleBetsCreated':
        case 'multipleBetsResolved':
        case 'betResolved':
            return <BetToast {...toast} />;
        case 'betIncorrectAmount':
            return <BetIncorrectAmount {...toast} />;
        case 'betInsufficientFunds':
            return <BetInsufficientFunds {...toast} />;
        case 'serverError':
            return <ServerErrorToast {...toast} />;
    }
});

export const Toasts = observer(() => {
    const store = useStore();

    if (!store) return null;

    return (
        <div className={toastsStyles.container}>
            <AnimatePresence>
                {store.ui.toasts.queue.map(toast => {
                    return (
                        <motion.div
                            key={toast.id}
                            initial={{ x: '-150%' }}
                            animate={{ x: 0 }}
                            exit={{ x: '-150%' }}>
                            <Toast {...toast} />
                        </motion.div>
                    );
                })}
            </AnimatePresence>
        </div>
    );
});
