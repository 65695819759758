import { Button } from '@/components/Button';
import { ModalContainer } from '@/components/Modal/ModalContainer';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../storeContext';

export const OutOfServiceModal = observer(() => {
    const store = useStore();
    const { t } = useTranslation();

    const modal = store?.ui.modals.activeModal;

    if (!modal || modal.type !== 'outOfService') return null;

    return (
        <ModalContainer
            header={t('outOfService.heading')}
            footer={
                <Button className="w-full" onClick={() => modal.resolve()}>
                    {t('outOfService.button')}
                </Button>
            }>
            <div className="text-greyscale-secondary text-sm py-2 flex flex-1 items-center">
                <p className="text-center">{t('outOfService.text')}</p>
            </div>
        </ModalContainer>
    );
});
