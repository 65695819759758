import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow.svg';
import React, { useCallback } from 'react';
import { ItemProps } from './Item.types';

export const Item = <T,>({ item, active, onClick }: ItemProps<T>) => {
    const handlClick = useCallback(() => {
        onClick(item.value);
    }, [item.value, onClick]);

    const ifActive = useCallback(
        (trueValue: string, falseValue: string) => {
            if (item.value === active) return trueValue;
            return falseValue;
        },
        [active, item.value],
    );

    return (
        <div
            onClick={handlClick}
            className="w-full p-4 flex justify-between bg-greyscale-background border-b border-greyscale-line items-center cursor-pointer">
            <span
                className={`${ifActive(
                    'text-greyscale-disabled',
                    'text-greyscale-primary',
                )} text-mobile-inputs-medium`}>
                {item.label}
            </span>
            <ArrowIcon
                className={`${ifActive(
                    'fill-greyscale-disabled',
                    'fill-greyscale-secondary',
                )} rotate-[-90deg] w-3 h-3 `}
            />
        </div>
    );
};
