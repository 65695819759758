import { joinClassnames } from '@/tools/css';

const container = [
    'flex',
    'gap-2',
    'py-3',
    'px-4',
    'rounded-lg',
    'bg-greyscale-input',
    'border-l-[3px]',
    'border-primary-medium',
    'border-solid',
];

export const toastStyles = {
    container: joinClassnames([
        'flex',
        'gap-2',
        'py-3',
        'px-4',
        'rounded-lg',
        'bg-greyscale-input',
        'border-l-[3px]',
        'border-primary-medium',
        'border-solid',
    ]),
    main: joinClassnames([
        'text-xs',
        'font-medium',
        'text-[#6B728E]',
        'min-w-[185px]',
    ]),
    header: joinClassnames(['flex']),
    headerText: joinClassnames(['text-consts-white', 'ml-2']),

    close: joinClassnames(['h-5', 'cursor-pointer']),
};

export const getContainerClassname = (borderColor: string) => {
    return joinClassnames([...container, borderColor]);
};
