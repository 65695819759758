import { FirebaseApp } from 'firebase/app';
import { RouterStore } from 'mobx-router';
import { AnalyticsStoreType } from './analytics/AnalyticsStore.types';
import { BetsStoreType } from './bets/BetsStore.types';
import { BetsStoreProxy } from './bets/BetsStoreProxy';
import { BinanceStoreType } from './binance/BinanceStore.types';
import { ServerTimeStoreType } from './binance/ServerTimeStore.types';
import { ConfigStoreType } from './config/ConfigStore.types';
import { ContractsStoreType } from './contracts/ContractsStore.types';
import { DeviceStoreType } from './Device/DeviceStore.types';
import { ErrorsStoreType } from './errors/ErrorsStore.types';
import { LayoutSizeStoreType } from './layoutSize/LayoutSizeStore.types';
import { MarketsStoreType } from './markets/MarketsStore.types';
import { MarketsStoreProxy } from './markets/MarketsStoreProxy';
import { NewsletterStoreType } from './newsletter/NewsletterStore.types';
import { AppMode, RootStoreType } from './RootStore.types';
import { ServerInfoProxy } from './ServerInfo/ServerInfoProxy';
import { ServerInfoStoreType } from './ServerInfo/ServerInfoStore.types';
import { StorageType } from './storage/Storage.types';
import { TRpcAPiType } from './tRpcApi/tRpcApi.types';
import { UIStoreType } from './ui/UIStore.types';
import { UseCasesStoreTypes } from './useCases/UseCasesStore.types';
import { UserStoreType } from './user/UserStore.types';
import { UserStoreProxy } from './user/UserStoreProxy';
import { WalletStoreType } from './wallet/WalletStore.types';

export type DynamicFactoriesType = {
    marketsStoreFactory: (root: RootStoreType) => MarketsStoreType;
    betsStoreFactory: (root: RootStoreType) => BetsStoreType;
    userStoreFactory: (root: RootStoreType) => UserStoreType;
    serverInfoStoreFactory: (root: RootStoreType) => ServerInfoStoreType;
};
export class RootStore implements RootStoreType {
    config: ConfigStoreType;
    device: DeviceStoreType;
    serverInfo: ServerInfoProxy;
    ui: UIStoreType;
    wallet: WalletStoreType;
    useCases: UseCasesStoreTypes;
    binance: BinanceStoreType;
    router: RouterStore<RootStoreType>;
    storage: StorageType;
    errors: ErrorsStoreType;
    bets: BetsStoreProxy;
    markets: MarketsStoreProxy;
    serverTime: ServerTimeStoreType;
    user: UserStoreProxy;
    analytics: AnalyticsStoreType;
    layoutSize: LayoutSizeStoreType;
    newsletter: NewsletterStoreType;
    firebase: FirebaseApp;

    _api: TRpcAPiType;
    // Workaround;
    // make api lazy;
    // so it will not be initialized in local mode
    // this code may be removed as only production server will be deployed
    get api(): TRpcAPiType {
        if (!this._api) {
            this._api = this.trpcApiFactory(this);
        }
        return this._api;
    }

    _contracts: ContractsStoreType;

    get contracts(): ContractsStoreType {
        if (!this._contracts) {
            this._contracts = this.contractsFactory(this);
        }
        return this._contracts;
    }
    constructor(
        serverInfoStoreFactory: (root: RootStoreType) => ServerInfoProxy,
        deviceStoreFactory: (root: RootStoreType) => DeviceStoreType,
        configStoreFactory: (root: RootStoreType) => ConfigStoreType,
        uiStoreFactory: (root: RootStoreType) => UIStoreType,
        walletStoreFactory: (root: RootStoreType) => WalletStoreType,
        useCasesStoreFactory: (root: RootStoreType) => UseCasesStoreTypes,
        storageFactory: (root: RootStoreType) => StorageType,
        private contractsFactory: (root: RootStoreType) => ContractsStoreType,
        errorsStoreFactory: (root: RootStoreType) => ErrorsStoreType,
        betsStoreFactory: (root: RootStoreType) => BetsStoreProxy,
        binanceStoreFactory: (root: RootStoreType) => BinanceStoreType,
        marketsStoreFactory: (root: RootStoreType) => MarketsStoreProxy,
        serverTimeStoreFactory: (root: RootStoreType) => ServerTimeStoreType,
        userStoreFactory: (root: RootStoreType) => UserStoreProxy,
        analyticsStoreFactory: (root: RootStoreType) => AnalyticsStoreType,
        layoutSizeStoreFactory: (root: RootStoreType) => LayoutSizeStoreType,
        newsletterStoreFactory: (root: RootStoreType) => NewsletterStoreType,
        firebaseAppFactory: (root: RootStoreType) => FirebaseApp,
        private trpcApiFactory: (root: RootStoreType) => TRpcAPiType,
        private getDynamicFactories: (
            mode: AppMode,
        ) => Promise<DynamicFactoriesType>,
        public mode: AppMode,
    ) {
        this.errors = errorsStoreFactory(this);
        this.storage = storageFactory(this);
        this.config = configStoreFactory(this);
        this.serverInfo = serverInfoStoreFactory(this);
        this.device = deviceStoreFactory(this);
        this.firebase = firebaseAppFactory(this);
        this.layoutSize = layoutSizeStoreFactory(this);
        this.wallet = walletStoreFactory(this);

        this.markets = marketsStoreFactory(this);
        this.binance = binanceStoreFactory(this);
        this.serverTime = serverTimeStoreFactory(this);
        this.user = userStoreFactory(this);
        this.bets = betsStoreFactory(this);
        this.router = new RouterStore<RootStoreType>(this);
        this.analytics = analyticsStoreFactory(this);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        this.useCases = useCasesStoreFactory(this);

        this.ui = uiStoreFactory(this);

        this.newsletter = newsletterStoreFactory(this);
    }

    async setMode(mode: AppMode): Promise<void> {
        this.mode = mode;
        this.setDynamicStores(await this.getDynamicFactories(mode));
    }

    private setDynamicStores({
        betsStoreFactory,
        marketsStoreFactory,
        userStoreFactory,
        serverInfoStoreFactory,
    }: DynamicFactoriesType) {
        this.markets.setTarget(marketsStoreFactory(this));
        this.bets.setTarget(betsStoreFactory(this));
        this.user.setTarget(userStoreFactory(this));
        this.serverInfo.setTarget(serverInfoStoreFactory(this));
    }
}
