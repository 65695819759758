import { joinClassnames } from '@/tools/css';

export const componentHeaderStyles = {
    container: joinClassnames([
        'w-full',
        'h-11',
        'flex',
        'justify-between',
        'items-center',
        'pt-0',
        'pr-1',
        'border-b',
        'border-b-greyscale-line',
    ]),
    textStyles: joinClassnames([
        'font-semibold',
        'text-sm',
        'text-consts-white',
        'ml-3',
    ]),
};
