import { abs } from '@/tools/big-math';
import { formatVolume } from '@/tools/format';
import React from 'react';
import { Trans } from 'react-i18next';
import { ToastContainer } from '../ToastContainer';
import { BetInsufficientFundsProps } from './BetInsufficientFunds.types';

export const BetInsufficientFunds = ({
    needed,
    borderColor,
    icon,
    header,
    decimals,
    resolve,
}: BetInsufficientFundsProps) => {
    return (
        <ToastContainer
            header={header}
            borderColor={borderColor}
            icon={icon}
            onClose={resolve}>
            <div className="mt-3 mb-1.5">
                <Trans
                    i18nKey="toasts.insufficientFundsText"
                    values={{
                        needed: formatVolume(abs(needed), decimals),
                    }}
                    components={[
                        <span key={0} className="text-consts-white" />,
                    ]}
                />
            </div>
        </ToastContainer>
    );
};
