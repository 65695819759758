import ErrorIcon from '@/assets/icons/error.svg';
import SuccessIcon from '@/assets/icons/success.svg';
import { BetType } from '@/bets/BetsStore.types';
import { TFuncKey } from 'i18next';
import { BetResolvedType, ToastsStoreType } from '../ToastsStore.types';
import { getBaseToast } from './baseToast';

export const betResolved = (
    toasts: ToastsStoreType,
    bet: BetType,
    decimals: number | undefined,
): BetResolvedType => {
    const statusOptions = <W, L>(winOption: W, loseOption: L) => {
        return bet.status === 'win' ? winOption : loseOption;
    };
    const base = {
        type: 'betResolved' as const,
        header: statusOptions(
            'toasts.winHeader',
            'toasts.loseHeader',
        ) as TFuncKey,
        icon: statusOptions(SuccessIcon, ErrorIcon),
        borderColor: statusOptions(
            'border-success-default',
            '!border-error-default',
        ),
    };
    return {
        ...getBaseToast(toasts, base),
        symbol: bet.market?.formattedSymbol ?? '-',
        isUp: bet.direction === 'up' ? true : false,
        volume: statusOptions(bet.mayWin, 0n),
        decimals,
        win: statusOptions(true, false),
        volumeText: 'toasts.profit',
    };
};
