import { ErrorMessageBuilder, ErrorsMap } from './ErrorsStore.types';

const builders: ErrorMessageBuilder = {
    WALLET_NOT_CONNECTED: () => 'Wallet is not connected',
    ID_ALREADY_USED: id => `The id [${id}] is already`,
    NOT_FOUND_UNDER_ID: id => `Nothig found under the [${id}]`,
};

export const getMessage = <T extends keyof ErrorsMap>(
    errorCode: T,
    ...args: ErrorsMap[T]
) => builders[errorCode]?.(...args) ?? `Application Error [${errorCode}]`;
