import { Button } from '@/components/Button';
import { ModalContainer } from '@/components/Modal/ModalContainer';
import { addr } from '@/tools/addr';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../storeContext';
import { LOGO_MAP } from './walletIcon';

export const LogiumCoreAllowanceModal: React.FC = observer(() => {
    const store = useStore();
    const { t } = useTranslation();

    const modal = store?.ui.modals.activeModal;
    const profile = store?.ui.nav.profile;

    if (modal && modal.type === 'approveLogiumCore') {
        return (
            <ModalContainer
                header={t('allowanceModal.modalHeading')}
                onClose={() => modal.resolve(false)}
                footer={
                    <Button
                        className="w-full"
                        onClick={() => modal.resolve(true)}>
                        {t('allowanceModal.modalHeading')}
                    </Button>
                }>
                <div className="bg-greyscale-input p-2 rounded-md mb-6 flex justify-between items-center">
                    <div className="flex flex-col">
                        <p className="text-greyscale-placeholder text-xs font-medium">
                            <span>{t('allowanceModal.connectedWith')} </span>
                            <span className="capitalize">
                                {profile?.walletName}
                            </span>
                        </p>
                        <span className="text-greyscale-primary text-base font-semibold">
                            {addr(profile?.address ?? '')}
                        </span>
                    </div>
                    <div className="w-8 h-8 bg-greyscale-disabled rounded-md flex items-center justify-center">
                        <img
                            src={LOGO_MAP[profile?.walletName ?? '']}
                            className="max-w-6 max-h-6"
                            alt="wallet"
                        />
                    </div>
                </div>
                <p className="text-sm mb-4 font-medium text-greyscale-primary">
                    {t('allowanceModal.description')}
                </p>
                <p className="mb-1 text-xs font-medium text-greyscale-secondary">
                    {t('allowanceModal.usdcContract')}
                </p>
                <p className="text-sm font-medium text-greyscale-primary">
                    {t('allowanceModal.enableOnce')}
                </p>
            </ModalContainer>
        );
    } else {
        return null;
    }
});
