import tableIcon from '@/assets/icons/table.svg';
import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { noDataStyles } from './NoData.css';
import { NoDataProps } from './NoData.types';

export const NoData: FC<NoDataProps> = ({ tab, show }) => {
    return (
        <div className={noDataStyles.container(show)}>
            <div className={noDataStyles.iconContainer}>
                <img src={tableIcon} alt="table" width={18} height={18} />
            </div>
            <p className={noDataStyles.text}>
                <Trans
                    i18nKey="tableNoData.text"
                    values={{
                        type: tab,
                    }}
                />
            </p>
        </div>
    );
};
