import { ReactComponent as Arrow } from '@/assets/icons/arrow.svg';
import { useClassName } from '@/tools/css';
import React from 'react';
import { DropdownProps } from './Dropdown.types';

export const Dropdown = ({ activeEl, onClick, isOpen }: DropdownProps) => {
    const arrowClassName = useClassName(
        'fill-greyscale-secondary transition-all',
        isOpen ? 'rotate-180' : 'rotate-0',
    );

    return (
        <div
            className="h-full bg-greyscale-input rounded-md flex items-center justify-between px-3 cursor-pointer"
            onClick={onClick}>
            <span className="text-mobile-inputs-medium text-greyscale-primary">
                {activeEl}
            </span>
            <Arrow className={arrowClassName} />
        </div>
    );
};
