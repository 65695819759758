import { RootStoreType } from '@/RootStoreTypes';
import { ContractsStoreType } from './ContractsStore.types';
import { Ierc20UsdcType } from './Ierc20/Ierc20UsdcContract.types';
import { LogiumV2CoreContractType } from './LogiumV2Core/LogiumV2CoreContract.types';

export class ContractsStore implements ContractsStoreType {
    ierc20Usdc: Ierc20UsdcType;
    logiumCore: LogiumV2CoreContractType;

    constructor(
        root: RootStoreType,
        ierc20Factory: (root: RootStoreType) => Ierc20UsdcType,
        logiumCoreFactory: (root: RootStoreType) => LogiumV2CoreContractType,
    ) {
        this.ierc20Usdc = ierc20Factory(root);
        this.logiumCore = logiumCoreFactory(root);
    }
}
