import { TypeSystem } from "@sinclair/typebox/system";
// eslint-disable-next-line
import { BigNumber as BigNumberOriginal } from "bignumber.js";

import { mapenum } from "../utils";
import { valueof } from "../utils/types";

export type ALLOWED_CHAINS = valueof<typeof ALLOWED_CHAINS>;
export const ALLOWED_CHAINS = {
  goerli: "goerli",
  mainnet: "mainnet",
} as const;

export const CHAIN_TO_ID = mapenum<ALLOWED_CHAINS>()({
  mainnet: 1,
  goerli: 5,
} as const);

export class AddressVO {
  private readonly _address: string;

  constructor(address: string) {
    this._address = address;
  }

  public get address(): string {
    return this._address.toLowerCase();
  }
}

/** Capable of storing 256 bit numbers */
export const numericPrecision = 78;

export const BigNumber = BigNumberOriginal.clone({
  DECIMAL_PLACES: numericPrecision,
  EXPONENTIAL_AT: [-200, 200],
});

export type BigNumber = BigNumberOriginal;

export type RoundingMode = BigNumberOriginal.RoundingMode;

export const BigNumberString = TypeSystem.CreateType<string, never>(
  "BigNumberString",
  (_options, value) => {
    if (typeof value !== "string") return false;
    try {
      BigNumber(value);
    } catch (e) {
      return false;
    }
    return true;
  }
);

export const ZERO = new BigNumber(0);
export const ONE = new BigNumber(1);
export const DB_DEFAULT_PASS = "test";
