import { ModalContainer } from '@/components/Modal/ModalContainer';
import { WalletConnector } from '@/components/WalletConnector';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../storeContext';
import { LOGO_MAP } from './walletIcon';
import { WalletModalFooter } from './WalletModalFooter';

export const SelectWalletModal: React.FC = observer(() => {
    const store = useStore();
    const { t } = useTranslation();
    const modal = store?.ui.modals.activeModal;

    if (modal && modal.type === 'selectWallet') {
        return (
            <ModalContainer
                header={t('selectWallet.header')}
                footer={<WalletModalFooter />}
                onClose={() => modal.resolve(null)}>
                <div className="flex flex-col gap-4">
                    {modal.wallets.map(wallet => (
                        <WalletConnector
                            key={wallet}
                            name={wallet}
                            iconUrl={LOGO_MAP[wallet]}
                            onClick={() => modal.resolve(wallet)}
                        />
                    ))}
                </div>
            </ModalContainer>
        );
    } else {
        return null;
    }
});
