import React, { FC } from 'react';
import { If } from '../If/If';
import { walletConnectorStyles } from './WalletConnector.css';
import { WalletConnectorProps } from './WalletConnector.types';

export const WalletConnector: FC<WalletConnectorProps> = ({
    name,
    iconUrl,
    mode,
    onClick,
}) => {
    return (
        <button
            onClick={onClick}
            className={walletConnectorStyles.main(mode)}
            name={`wallet-connector-${name}`}>
            {name}
            <div className={walletConnectorStyles.icon}>
                <If condition={!!iconUrl}>
                    <img
                        className={walletConnectorStyles.iconImg}
                        src={iconUrl}
                    />
                </If>
            </div>
        </button>
    );
};

WalletConnector.displayName = 'WalletConnector';
