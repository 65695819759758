import { Direction } from '@/bets/BetsStore.types';
import { MarketType } from '@/markets/MarketsStore.types';
import { RootStoreType } from '@/RootStoreTypes';
import { getResolutionInSeconds } from '@/tools/chart';
import {
    EntityId,
    IChartWidgetApi,
    ResolutionString,
    ShapePoint,
} from '@charting-library/';
import { map, merge, of, Subscription, switchMap } from 'rxjs';

export class StrikePriceRectangle {
    static UpColor = 'rgba(55, 173, 128, 0.07)'; // '#37ad80'
    static DownColor = 'rgba(235, 46, 46, 0.07)'; // '#eb2e2e'
    private entityId: EntityId | null = null;
    private subscription: Subscription;
    constructor(
        private root: RootStoreType,
        private chart: IChartWidgetApi,
        private direction: Direction,
    ) {
        this.draw();
        this.subscription = this.getUpdate$().subscribe(price =>
            this.update(price),
        );
    }

    get color() {
        if (this.direction === 'up') {
            return StrikePriceRectangle.UpColor;
        } else {
            return StrikePriceRectangle.DownColor;
        }
    }

    getMarket$() {
        return merge(
            this.root.ui.betsPage.market$,
            of(this.root.ui.betsPage.market),
        );
    }

    getPrice$(resolution: ResolutionString, market?: MarketType) {
        const resolutionS = getResolutionInSeconds(resolution);

        if (market) {
            return merge(
                this.root.binance.trade$(market.symbol).pipe(map(t => t.price)),
                this.root.binance
                    .kline$(market.symbol, resolutionS)
                    .pipe(map(t => t.close)),
                of(market.price?.toFixed()),
            );
        } else {
            return of(undefined);
        }
    }

    getUpdate$() {
        return this.getMarket$().pipe(
            switchMap(market =>
                this.getPrice$(this.chart.resolution(), market),
            ),
        );
    }
    update(price?: string) {
        const shape = this.entityId
            ? this.chart.getShapeById(this.entityId)
            : null;
        shape?.setPoints(this.getPoints(price));
    }

    getPricePoint(time: number, price?: string): ShapePoint {
        return {
            time: time,
            channel: price ? undefined : 'close',
            price: price ? parseFloat(price) : undefined,
        };
    }
    getOverPricePoints(price?: string): [ShapePoint, ShapePoint] {
        const timeRange = this.chart.getVisibleRange();
        const priceRange = this.getPriceRange();
        return [
            {
                price: priceRange?.to,
                time: timeRange.from,
            },
            this.getPricePoint(timeRange.to, price),
        ];
    }
    getUnderPricePoints(price?: string): [ShapePoint, ShapePoint] {
        const timeRange = this.chart.getVisibleRange();
        const priceRange = this.getPriceRange();
        return [
            {
                price: priceRange?.from,
                time: timeRange.from,
            },
            this.getPricePoint(timeRange.to, price),
        ];
    }
    getPriceRange() {
        return this.chart
            .getPanes()[0]
            .getMainSourcePriceScale()
            ?.getVisiblePriceRange();
    }
    getPoints(price?: string): [ShapePoint, ShapePoint] {
        if (this.direction === 'up') {
            return this.getOverPricePoints(price);
        } else {
            return this.getUnderPricePoints(price);
        }
    }

    draw() {
        this.entityId = this.chart.createMultipointShape(this.getPoints(), {
            shape: 'rectangle',
            lock: true,
            disableSelection: true,
            disableSave: true,
            disableUndo: true,
            showInObjectsTree: false,
            zOrder: 'bottom',
            overrides: {
                backgroundColor: this.color,
                linewidth: 0,
                extendsLeft: true,
                extendsRight: true,
            },
        });
    }

    destroy() {
        this.entityId && this.chart.removeEntity(this.entityId);
        this.subscription.unsubscribe();
    }
}
