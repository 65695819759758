const volumeFormatterMax = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
});

const volumeFormatterMin = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const procFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const formatVolume = (
    volume: string | number | bigint | null | undefined,
    decimals?: number,
) => {
    if (volume === null || volume === undefined) {
        return '-';
    } else if (typeof volume === 'string') {
        volume = parseFloat(volume);
    } else if (typeof volume === 'bigint') {
        volume = Number(volume);
    }

    if (decimals) volume = volume / Math.pow(10, decimals);

    if (volume % 1 === 0) {
        return volumeFormatterMin.format(volume);
    } else return volumeFormatterMax.format(volume);
};

export const formatProc = (
    volume: string | number | bigint | null | undefined,
) => {
    if (volume === null || volume === undefined) {
        return '-%';
    } else if (typeof volume === 'string') {
        return procFormatter.format(parseFloat(volume) * 100) + '%';
    } else if (typeof volume === 'bigint') {
        return procFormatter.format(volume * BigInt(100)) + '%';
    } else {
        return procFormatter.format(volume * 100) + '%';
    }
};
