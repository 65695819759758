import { ConfigStore } from 'config/config';
import { makeAutoObservable, observable } from 'mobx';
import { AnalyticsStore } from './analytics/AnalyticsStore';
import { AnalyticsStoreType } from './analytics/AnalyticsStore.types';
import { FirebaseStore } from './analytics/firebase/Firebase';
import { FirebaseStoreType } from './analytics/firebase/Firebase.types';
import { GoogleStore } from './analytics/google/GoogleStore';
import { GoogleStoreType } from './analytics/google/GoogleStore.types';
import { HotjarStoreType } from './analytics/hotjar/Hotjar.types';
import { HotJarStore } from './analytics/hotjar/HotjarStore';
import { BetsStoreProxy } from './bets/BetsStoreProxy';
import { BinanceAggregateStore } from './binance/BinanceAggregateStore';
import { BinanceStoreType } from './binance/BinanceStore.types';
import { ServerTimeStore } from './binance/ServerTimeStore';
import { ConfigStoreType } from './config/ConfigStore.types';
import { DefaultConfig } from './config/DefaultConfig/DefaultConfig';
import { DefaultConfigType } from './config/DefaultConfig/DefaultConfig.types';
import { ContractsStore } from './contracts/ContractsStore';
import { ContractsStoreType } from './contracts/ContractsStore.types';
import { Ierc20Usdc } from './contracts/Ierc20/Ierc20UsdcContract';
import { Ierc20UsdcType } from './contracts/Ierc20/Ierc20UsdcContract.types';
import { LogiumV2CoreContract } from './contracts/LogiumV2Core/LogiumV2CoreContract';
import { LogiumV2CoreContractType } from './contracts/LogiumV2Core/LogiumV2CoreContract.types';
import { DeviceStore } from './Device/DeviceStore';
import { DeviceStoreType } from './Device/DeviceStore.types';
import { ErrorsStore } from './errors/ErrorsStore';
import { ErrorsStoreType } from './errors/ErrorsStore.types';
import { firebaseAppFactory } from './firebase/firebase';
import { LayoutSizeStore } from './layoutSize/LayoutSizeStore';
import { LayoutSizeStoreType } from './layoutSize/LayoutSizeStore.types';
import { BinanceMarket } from './markets/BinanceMarket';
import { MarketsStore } from './markets/MarketsStore';
import { MarketType } from './markets/MarketsStore.types';
import { MarketsStoreProxy } from './markets/MarketsStoreProxy';
import { ModalsStore } from './modals/ModalsStore';
import { ModalsStoreType } from './modals/ModalsStore.types';
import { NewsletterStore } from './newsletter/NewsletterStore';
import { RootStore } from './RootStore';
import { AppMode, RootStoreType } from './RootStore.types';
import { ServerInfoProxy } from './ServerInfo/ServerInfoProxy';
import { RamStorage } from './storage/Storage';
import { StorageType } from './storage/Storage.types';
import { ToastsStore } from './toasts/ToastsStore';
import { ToastsStoreType } from './toasts/ToastsStore.types';
import { tRpcApiFactory } from './tRpcApi/tRpcApi';
import { BetConfiguration } from './tRpcApi/tRpcApi.types';
import { BetInputStore } from './ui/betsPageStore/betInput/BetInputStore';
import { BetInputStoreTypes } from './ui/betsPageStore/betInput/BetInputStore.types';
import { BetsPageStore } from './ui/betsPageStore/BetsPageStore';
import { BetsPageStoreType } from './ui/betsPageStore/BetsPageStore.types';
import { BetsTableStore } from './ui/betsPageStore/betsTable/BetsTableStore';
import { BetsTableStoreType } from './ui/betsPageStore/betsTable/BetsTableStore.types';
import { FavoriteMarket } from './ui/betsPageStore/favoriteMarkets/FavoriteMarket';
import { FavoriteMarketsStore } from './ui/betsPageStore/favoriteMarkets/FavoriteMarketsStore';
import {
    FavoriteMarketsStoreType,
    FavoriteMarketType,
} from './ui/betsPageStore/favoriteMarkets/FavoriteMarketsStore.types';
import { HighlightSymbol } from './ui/betsPageStore/highlightSymbol/HighlightSymbol';
import { HighlightSymbolType } from './ui/betsPageStore/highlightSymbol/HighlightSymbol.types';
import { MobileNotificationStore } from './ui/nav/mobileNotification/MobileNotificationStore';
import { MobileNotificationStoreType } from './ui/nav/mobileNotification/MobileNotificationStore.types';
import { NavStore } from './ui/nav/NavStore';
import { NavStoreType } from './ui/nav/NavStore.types';
import { ProductsStore } from './ui/nav/products/ProductsStore';
import { ProductsStoreType } from './ui/nav/products/ProductsStore.types';
import { ProfileStore } from './ui/nav/profile/ProfileStore';
import { ProfileStoreType } from './ui/nav/profile/ProfileStore.types';
import { UIStore } from './ui/UIStore';
import { UIStoreType } from './ui/UIStore.types';
import { UseCasesStore } from './useCases/UseCasesStore';
import { UseCasesStoreTypes } from './useCases/UseCasesStore.types';
import { UserStoreProxy } from './user/UserStoreProxy';
import { WalletStore } from './wallet/WalletStore';
import { WalletStoreType } from './wallet/WalletStore.types';

export const defaultConfigFactory = (): DefaultConfigType =>
    makeAutoObservable(new DefaultConfig());

export const configStoreFactory = (): ConfigStoreType =>
    makeAutoObservable(new ConfigStore(defaultConfigFactory));

export const ierc20Factory = (root: RootStoreType): Ierc20UsdcType =>
    makeAutoObservable(new Ierc20Usdc(root));

export const logiumCoreFactory = (
    root: RootStoreType,
): LogiumV2CoreContractType =>
    makeAutoObservable(new LogiumV2CoreContract(root));

export const contractsFactory = (root: RootStoreType): ContractsStoreType =>
    makeAutoObservable(
        new ContractsStore(root, ierc20Factory, logiumCoreFactory),
    );

export const productsStoreFactory = (root: RootStoreType): ProductsStoreType =>
    makeAutoObservable(new ProductsStore(root));
export const profileStoreFactory = (root: RootStoreType): ProfileStoreType =>
    makeAutoObservable(new ProfileStore(root));
export const mobileNotificationFactory = (
    root: RootStoreType,
): MobileNotificationStoreType =>
    makeAutoObservable(new MobileNotificationStore(root));

export const navStoreFactory = (root: RootStoreType): NavStoreType =>
    makeAutoObservable(
        new NavStore(
            root,
            productsStoreFactory,
            profileStoreFactory,
            mobileNotificationFactory,
        ),
    );

export const betInputFactory = (root: RootStoreType): BetInputStoreTypes =>
    makeAutoObservable(new BetInputStore(root));

export const favoriteMarketFactory = (
    root: RootStoreType,
    market: MarketType,
): FavoriteMarketType => makeAutoObservable(new FavoriteMarket(root, market));

export const favoriteMarketsStoreFactory = (
    root: RootStoreType,
): FavoriteMarketsStoreType =>
    makeAutoObservable(new FavoriteMarketsStore(root, favoriteMarketFactory));

export const highlightSymbolFactory = (
    root: RootStoreType,
): HighlightSymbolType => makeAutoObservable(new HighlightSymbol(root));

export const betsTableStoreFactory = (
    root: RootStoreType,
): BetsTableStoreType => makeAutoObservable(new BetsTableStore(root));

export const betsPageFactory = (root: RootStoreType): BetsPageStoreType =>
    makeAutoObservable(
        new BetsPageStore(
            root,
            betInputFactory,
            favoriteMarketsStoreFactory,
            highlightSymbolFactory,
            betsTableStoreFactory,
        ),
    );

export const modalsStoreFactory = (root: RootStoreType): ModalsStoreType =>
    makeAutoObservable(new ModalsStore(root));

export const toastsStoreFactory = (root: RootStoreType): ToastsStoreType =>
    makeAutoObservable(new ToastsStore(root));

export const uiStoreFactory = (root: RootStoreType): UIStoreType =>
    makeAutoObservable(
        new UIStore(
            root,
            navStoreFactory,
            betsPageFactory,
            modalsStoreFactory,
            toastsStoreFactory,
        ),
    );

export const walletStoreFactory = (root: RootStoreType): WalletStoreType =>
    makeAutoObservable(new WalletStore(root));

export const useCasesStoreFactory = (root: RootStoreType): UseCasesStoreTypes =>
    makeAutoObservable(new UseCasesStore(root));

export const storageFactory = (): StorageType => new RamStorage(localStorage);
export const errorsStoreFactory = (root: RootStoreType): ErrorsStoreType =>
    new ErrorsStore(root);

export const binanceStoreFactory = (root: RootStoreType): BinanceStoreType =>
    makeAutoObservable(new BinanceAggregateStore(root));

export const marketFactory = (
    root: RootStoreType,
    cfg: BetConfiguration[],
): MarketType => makeAutoObservable(new BinanceMarket(root, cfg));

export const marketsStoreFactory = (root: RootStoreType) =>
    makeAutoObservable(new MarketsStore(root, marketFactory));

export const serverTimeStoreFactory = (root: RootStoreType) =>
    makeAutoObservable(new ServerTimeStore(root));

export const googleStoreFactory = (root: RootStoreType): GoogleStoreType =>
    makeAutoObservable(new GoogleStore(root, window));

export const hotjarStoreFactory = (root: RootStoreType): HotjarStoreType =>
    makeAutoObservable(new HotJarStore(root, window, document));

export const firebaseStoreFactor = (root: RootStoreType): FirebaseStoreType =>
    makeAutoObservable(new FirebaseStore(root));

export const analyticsStoreFactory = (
    root: RootStoreType,
): AnalyticsStoreType =>
    makeAutoObservable(
        new AnalyticsStore(
            root,
            googleStoreFactory,
            hotjarStoreFactory,
            firebaseStoreFactor,
        ),
    );

export const layoutSizeStoreFactory = (
    root: RootStoreType,
): LayoutSizeStoreType => makeAutoObservable(new LayoutSizeStore(root, window));

export const newsletterStoreFactory = (root: RootStoreType) =>
    makeAutoObservable(new NewsletterStore(root, window));

export const deviceStoreFactory = (root: RootStoreType): DeviceStoreType =>
    makeAutoObservable(new DeviceStore(root));

const getDynamicFactories = async (mode: AppMode) => {
    switch (mode) {
        case 'local':
            return import('./di-mobx-local');
        case 'server':
            return import('./di-mobx-server');
    }
};

// Workaround
// There is no publicly deployed backend yet.
// So application works in local-only mode by default.
const defaultAppMode: AppMode =
    import.meta.env.VITE_BACKEND_FEATURE === 'yes' ? 'server' : 'local';

export const rootStoreFactory = async (): Promise<RootStoreType> => {
    const {
        betsStoreFactory,
        marketsStoreFactory,
        userStoreFactory,
        serverInfoStoreFactory,
    } = await getDynamicFactories(defaultAppMode);

    const betsStoreProxyFactory = (root: RootStoreType) =>
        makeAutoObservable(new BetsStoreProxy(betsStoreFactory(root)));

    const userStoreProxyFactory = (root: RootStoreType) =>
        makeAutoObservable(new UserStoreProxy(userStoreFactory(root)));

    const marketsStoreProxyFactory = (root: RootStoreType) =>
        makeAutoObservable(new MarketsStoreProxy(marketsStoreFactory(root)));

    const serverInfoProxyFactory = (root: RootStoreType) =>
        makeAutoObservable(new ServerInfoProxy(serverInfoStoreFactory(root)));

    return makeAutoObservable(
        new RootStore(
            serverInfoProxyFactory,
            deviceStoreFactory,
            configStoreFactory,
            uiStoreFactory,
            walletStoreFactory,
            useCasesStoreFactory,
            storageFactory,
            contractsFactory,
            errorsStoreFactory,
            betsStoreProxyFactory,
            binanceStoreFactory,
            marketsStoreProxyFactory,
            serverTimeStoreFactory,
            userStoreProxyFactory,
            analyticsStoreFactory,
            layoutSizeStoreFactory,
            newsletterStoreFactory,
            firebaseAppFactory,
            tRpcApiFactory,
            getDynamicFactories,
            defaultAppMode,
        ),
        {
            // Api is a function with injected properties
            // So we have to force mobx use it as ref but not action
            _api: observable.ref,
        },
    );
};
