import { RootStoreType } from '@/RootStoreTypes';
import '@/assets/index.css';
import { MobxRouter } from 'mobx-router';
import * as React from 'react';
import { Modals } from './Modals/Modals';
import { StoreContext } from './storeContext';

export const App: React.FC<{ store: RootStoreType }> = ({ store }) => (
    <StoreContext.Provider value={store}>
        <MobxRouter store={store} />
        <Modals />
    </StoreContext.Provider>
);

App.displayName = 'App';
