import { Api } from './Api';
import {
    BinanceServerTimeType,
    KlineInterval,
    SingleKline,
    TickerPrice,
    UnformattedSingleKline,
} from './BinanceStore.types';

export class BinanceRestApi extends Api {
    private parseKline(kline: UnformattedSingleKline): SingleKline {
        return {
            time: kline[0],
            open: parseFloat(kline[1]),
            high: parseFloat(kline[2]),
            low: parseFloat(kline[3]),
            close: parseFloat(kline[4]),
            volume: parseFloat(kline[5]),
        };
    }

    async getKlines(
        symbol: string,
        interval: KlineInterval,
        from: number,
        to: number,
    ) {
        const data: UnformattedSingleKline[] = await this.get(
            `/klines?${new URLSearchParams({
                symbol: symbol.toUpperCase(),
                interval: interval,
                startTime: from.toString(),
                endTime: to.toString(),
            })}`,
        );

        return data.map(kline => this.parseKline(kline));
    }

    async getPrice(symbol: string): Promise<string> {
        const data: TickerPrice = await this.get(
            `/ticker/price?${new URLSearchParams({
                symbol: symbol.toUpperCase(),
            })}`,
        );
        return data.price;
    }

    async getTime() {
        const data: BinanceServerTimeType = await this.get('/time');
        return data.serverTime;
    }
}
