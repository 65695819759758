import { makeAutoObservable } from 'mobx';
import {
    ModalsStoreType,
    WaitModalType,
    WaitPropsType,
} from '../ModalsStore.types';
import { getBaseModal } from './baseModal';

export const getWaitModal = (
    modals: ModalsStoreType,
    props: WaitPropsType,
): WaitModalType =>
    makeAutoObservable({ ...getBaseModal(modals, 'wait'), props });
