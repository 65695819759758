import { RootStoreType } from '@/RootStoreTypes';
import { FractualBets } from '@/view/FractualBets/FractualBets';
import { FractualBetsMobile } from '@/view/FractualBetsMobile/FractualBetsMobile';
import { NotFound } from '@/view/NotFound/NotFound';
import { Portfolio } from '@/view/Portfolio/Portfolio';
import { useStore } from '@/view/storeContext';
import { Wallet } from '@/view/Wallet/Wallet';
import { observer } from 'mobx-react-lite';
import { Route } from 'mobx-router';
import React, { Suspense } from 'react';

const PcPageWrapper = React.lazy(
    () => import(`@/view/PageWrapper/PageWrapper`),
);

const MobilePageWrapper = React.lazy(
    () => import(`@/view/PageWrapper/PageWrapperMobile`),
);

//TODO: create a better fallback component
const FallbackComponent = () => (
    <div className="h-screen w-screen bg-gradient-background" />
);

const TradePage = observer(() => {
    const store = useStore();

    if (store?.device.isMobile && MobilePageWrapper) {
        return (
            <Suspense fallback={<FallbackComponent />}>
                <MobilePageWrapper>
                    <FractualBetsMobile />
                </MobilePageWrapper>
            </Suspense>
        );
    }
    if (!store?.device.isMobile && PcPageWrapper) {
        return (
            <Suspense fallback={<FallbackComponent />}>
                <PcPageWrapper>
                    <FractualBets />
                </PcPageWrapper>
            </Suspense>
        );
    }
    return null;
});

const routes = {
    home: new Route<RootStoreType>({
        path: '/',
        component: <TradePage />,
        onEnter(_, __, store) {
            store.useCases.goDefaultMarket();
        },
    }),
    bets: new Route<RootStoreType, { symbol?: string } | undefined>({
        path: '/bets/:symbol',
        component: <TradePage />,
        onEnter(_, params, store) {
            if (store.ui.betsPage.market) {
                store.useCases.goMarketBySymbol(
                    store.ui.betsPage.market?.symbol,
                );
            } else {
                store.useCases.goMarketBySymbol(params?.symbol);
            }
        },
        onParamsChange(_, params, store) {
            store.useCases.goMarketBySymbol(params?.symbol);
        },
    }),
    portfolio: new Route<RootStoreType>({
        path: '/portfolio',
        component: (
            <MobilePageWrapper>
                <Portfolio />
            </MobilePageWrapper>
        ),
        onEnter(_, __, store) {
            if (!store.device.isMobile) {
                store.useCases.goToNotFound();
            }
            store.ui.nav.mobileNotification.setShow(false);
        },
    }),
    wallet: new Route<RootStoreType>({
        path: '/wallet',
        component: (
            <MobilePageWrapper>
                <Wallet />
            </MobilePageWrapper>
        ),
        onEnter(_, __, store) {
            if (!store.device.isMobile) {
                store.useCases.goToNotFound();
            }
        },
    }),
    notFound: new Route<RootStoreType>({
        path: '/not-found',
        component: <NotFound />,
    }),
};

export default routes;
