import { merge, Observable, of, Subject, switchMap } from 'rxjs';
import { BetParameters, BetsStoreType, BetType } from './BetsStore.types';

export class BetsStoreProxy implements BetsStoreType {
    get bets() {
        return this.target.bets;
    }
    get betsError() {
        return this.target.betsError;
    }
    add(params: BetParameters) {
        return this.target.add(params);
    }
    betResolved$: Observable<BetType>;
    betAdded$: Observable<BetType>;
    clear() {
        return this.target.clear();
    }
    target$ = new Subject<BetsStoreType>();
    constructor(private target: BetsStoreType) {
        const target$ = merge(of(this.target), this.target$);
        this.betResolved$ = target$.pipe(
            switchMap(target => target.betResolved$),
        );
        this.betAdded$ = target$.pipe(switchMap(target => target.betAdded$));
    }
    setTarget(target: BetsStoreType) {
        this.target.destroy();
        this.target = target;
        this.target$.next(target);
    }

    destroy(): void {
        this.target.destroy();
    }
}
