import { RootStoreType } from '@/RootStoreTypes';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { DeviceStoreType } from './DeviceStore.types';

export class DeviceStore implements DeviceStoreType {
    resize$ = fromEvent(window, 'resize');
    online$ = fromEvent(window, 'online');
    offline$ = fromEvent(window, 'offline');

    isMobile: boolean;
    isMobile$ = new Subject<boolean>();

    isOnline = true;
    isOnline$ = new Subject<boolean>();

    private subscriptions: Subscription[] = [];

    constructor(private root: RootStoreType) {
        this.setIsMobile();
        this.init();
    }

    private init() {
        this.resizeSubscription();
        this.onlineSubscription();
        this.offlineSubscription();
    }

    private get maxMobileWidth() {
        return this.root.config.defaultValues.device.maxMobileWidth;
    }
    private setIsMobile() {
        this.isMobile = window.innerWidth < this.maxMobileWidth;
        this.isMobile$.next(window.innerWidth < this.maxMobileWidth);
    }

    private resizeSubscription() {
        const subscription = this.resize$.subscribe(() => this.setIsMobile());
        this.subscriptions.push(subscription);
    }

    private onlineSubscription() {
        const subscription = this.online$.subscribe({
            next: () => this.setIsOnline(true),
        });
        this.subscriptions.push(subscription);
    }

    private offlineSubscription() {
        const subscription = this.offline$.subscribe({
            next: () => this.setIsOnline(false),
        });
        this.subscriptions.push(subscription);
    }

    private setIsOnline(value: boolean) {
        this.isOnline = value;
        this.isOnline$.next(value);
    }

    destroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
