import { joinClassnames } from '@/tools/css';

export const betInputStyles = {
    container: joinClassnames(['p-4', 'overflow-auto', 'h-full']),
    row: joinClassnames(['mb-6']),
    input: joinClassnames(['w-full']),
    grayButton: joinClassnames([
        'rounded-xl',
        'border',
        'border-greyscale-placeholder',
        'py-1',
        'px-2',
    ]),
    primaryButton: joinClassnames([
        'w-full',
        'rounded-full',
        'bg-primary-default',
        'py-2',
        'px-5',
        'text-greyscale-input',
    ]),
};
