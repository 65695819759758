import { joinClassnames } from '@/tools/css';

export const labeledStyles = {
    container: joinClassnames(['w-full', 'flex', 'flex-col']),
    labelWrapper: joinClassnames(['w-full', 'flex', 'justify-between', 'mb-2']),
    label: joinClassnames([
        'text-greyscale-placeholder',
        'text-xs',
        'font-medium',
    ]),
};
