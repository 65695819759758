import { useEffect, useState } from 'react';
import { useViewport } from './useViewport';

export const useDetectMobileKeyboard = () => {
    const [isKeyboardActive, setIsKeyboardActive] = useState(false);

    const { height, fullHeight, isLandscape } = useViewport();

    useEffect(() => {
        const inputs = document.querySelectorAll('input');

        const setActive = () => setIsKeyboardActive(true);
        const setNotActive = () => setIsKeyboardActive(false);

        inputs.forEach(input => {
            input.addEventListener('focus', setActive);
            input.addEventListener('blur', setNotActive);
        });

        return () => {
            inputs.forEach(input => {
                input.removeEventListener('focus', setActive);
                input.removeEventListener('blur', setNotActive);
            });
        };
    }, []);

    return {
        isKeyboardActive: isKeyboardActive && height < fullHeight,
        isLandscape,
    };
};
