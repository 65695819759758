import { BigNumberType, toBI, toBN } from './big-number';

export const max = (first: bigint, ...values: bigint[]): bigint =>
    values.reduce((acc, value) => (acc > value ? acc : value), first);

export const min = (first: bigint, ...values: bigint[]): bigint =>
    values.reduce((acc, value) => (acc < value ? acc : value), first);

export const abs = (t: bigint) => (t < 0n ? -t : t);

export const mulFloat = (
    a: bigint,
    b: bigint | number | string | BigNumberType,
): bigint => {
    return toBI(toBN(a).multipliedBy(toBN(b)).toFixed(0));
};
