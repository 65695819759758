import { ChartOverlay } from '@/components/ChartOverlay/ChartOverlay';
import { useStore } from '@/view/storeContext';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { StubWidget } from './Widget';

export const Chart: React.FC = observer(() => {
    const root = useStore();
    const chartContainerRef = useRef<HTMLDivElement | null>(null);
    const [symbol, setSymbol] = useState<string | null>(null);

    useEffect(() => {
        if (!symbol && root?.ui.betsPage.market?.symbol) {
            setSymbol(root?.ui.betsPage.market?.symbol);
        }
    }, [root?.ui.betsPage.market?.symbol, symbol]);

    useEffect(() => {
        const widgetInstance =
            chartContainerRef.current && root && symbol
                ? new StubWidget(root, chartContainerRef.current, symbol)
                : null;

        return () => widgetInstance?.remove();
    }, [root, symbol]);

    return (
        <>
            <div
                ref={chartContainerRef}
                id="tv_chart_container"
                className="h-full border-b border-greyscale-line"
            />
            <ChartOverlay
                visible={!root?.ui.betsPage.betInput.isPriceDefined}
            />
        </>
    );
});
