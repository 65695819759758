import { useClassName } from '@/tools/css';
import React, { FC } from 'react';
import { inputStyles } from './Input.css';
import { InputProps } from './Input.types';

export const Input: FC<InputProps> = ({
    append,
    className,
    error,
    ...props
}) => {
    const cssClassName = useClassName(inputStyles.container, className);
    const inputClassName = useClassName(
        inputStyles.main,
        error ? inputStyles.error : '',
    );

    return (
        <>
            <div className={cssClassName}>
                <input className={inputClassName} {...props} />
                {append && <span className={inputStyles.append}>{append}</span>}
            </div>
            {error && (
                <div className={inputStyles.errorContainer}>
                    <span className={inputStyles.errorText}>{error}</span>
                </div>
            )}
        </>
    );
};

Input.displayName = 'Input';
