import { className } from '@/tools/css';

export const favoriteMarketStyles = {
    wrapper: className(
        'relative',
        'border-r-greyscale-line border-r',
        'h-8 px-4',
    ),
    main: (isActive?: boolean) =>
        className(
            'h-full w-full',
            'flex items-center gap-3',
            isActive ? 'text-greyscale-primary' : 'text-greyscale-placeholder',
            'text-xs',
        ),

    win: className(
        'absolute w-full h-full left-0 top-0',
        'bg-gradient-to-b',
        'from-success-default/20 to-consts-black/0',
    ),
    lose: className(
        'absolute w-full h-full left-0 top-0',
        'bg-gradient-to-b',
        'from-error-default/20 to-consts-black/0',
    ),
    closeWrapper: className(
        'h-full',
        'absolute',
        'pl-6',
        'right-0 top-0',
        'opacity-0 hover:opacity-100',
        'bg-gradient-to-r from-greyscale-background/0 via-greyscale-background to-greyscale-background',
        'ease-in-out',
        'duration-150',
        'transition-all',
    ),
    close: className('h-full'),
};
