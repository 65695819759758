import { RootStoreType } from '@/RootStoreTypes';
import { AssetType } from '@/user/User.types';
import { ConnectorName } from '@/wallet/WalletStore.types';
import { getAccountDetailsMobileModal } from './modal/AccountDetailsMobile';
import { getApproveLogiumCoreModal } from './modal/ApproveLogiumCoreModalStore';
import { getConfirmWallet } from './modal/ConfirmWalletSelectionModal';
import { getCreateSignatureWalletModal } from './modal/CreateSignatureWalletModalStore';
import { getDepositModal } from './modal/DepositModalStore';
import { getMobileVersionModal } from './modal/MobileVersionModalStore';
import { getNewsletterModal } from './modal/NewsletterModalStore';
import { getOutOfServiceModal } from './modal/OutOfService';
import { getProductsMobileModal } from './modal/ProductsMobile';
import { getResetVirtualAccountModal } from './modal/ResetVirtualAccount';
import { getWalletSelectModal } from './modal/SelectWalletStore';
import { getStatusModal } from './modal/StatusModalStore';
import { getTopUpModal } from './modal/TopUpModalStore';
import { getWaitModal } from './modal/WaitModal';
import { getWalletConnectionStatusModal } from './modal/WalletConnectionStatusModal';
import { getWithdrawModal } from './modal/WithdrawModalStore';
import {
    AccountDetailsMobileModalType,
    AnyModal,
    ApproveLogiumCoreModalType,
    ConfirmWalletSelectionModal,
    CreateSignatureWalletModalType,
    DepositModalType,
    MobileVersionModalType,
    Modal,
    ModalsStoreType,
    NewsletterModalType,
    OutOfServiceModalType,
    ProductsMobileModalType,
    ResetVirtualAccountModalType,
    SelectWalletModalType,
    StatusModalType,
    StatusPropsType,
    TopUpModalType,
    WaitModalType,
    WaitPropsType,
    WalletConnectionStatusModalType,
    WithdrawModalType,
} from './ModalsStore.types';

export class ModalsStore implements ModalsStoreType {
    get activeModal() {
        return this.queue.length ? this.queue[0] : null;
    }

    queue: AnyModal[] = [];

    constructor(private root: RootStoreType) {}
    remove<T, R>(modal: Modal<T, R>) {
        const idx = this.queue.findIndex(
            candidate => candidate.id === modal.id,
        );
        if (idx >= 0) {
            this.queue.splice(idx, 1);
        } else {
            throw new Error('Modal not found in queue');
        }
    }

    add<M extends AnyModal>(modal: M): M {
        this.queue.push(modal);
        return modal;
    }

    selectWallet(): SelectWalletModalType {
        const connectors = this.root.config.supportedConnectors[this.root.mode];
        return this.add(getWalletSelectModal(this, connectors));
    }

    createSignatureWallet(): CreateSignatureWalletModalType {
        return this.add(getCreateSignatureWalletModal(this));
    }

    confirmWallet(wallet: ConnectorName): ConfirmWalletSelectionModal {
        return this.add(getConfirmWallet(this, wallet));
    }

    walletConnectionStatus(
        wallet: ConnectorName,
    ): WalletConnectionStatusModalType {
        return this.add(getWalletConnectionStatusModal(this.root, wallet));
    }

    approveLogiumCore(): ApproveLogiumCoreModalType {
        return this.add(getApproveLogiumCoreModal(this));
    }

    deposit(asset: AssetType, volume?: bigint): DepositModalType {
        return this.add(getDepositModal(this, asset, volume));
    }

    withdraw(asset: AssetType, volume?: bigint): WithdrawModalType {
        return this.add(getWithdrawModal(this, asset, volume));
    }

    topUp(asset: AssetType, volume?: bigint): TopUpModalType {
        return this.add(getTopUpModal(this, asset, volume));
    }

    wait(props: WaitPropsType): WaitModalType {
        return this.add(getWaitModal(this, props));
    }

    resetVirtualAccount(): ResetVirtualAccountModalType {
        return this.add(getResetVirtualAccountModal(this));
    }

    newsletter(): NewsletterModalType {
        return this.add(getNewsletterModal(this));
    }

    status(props: StatusPropsType): StatusModalType {
        return this.add(getStatusModal(this, props));
    }
    mobileVersion(): MobileVersionModalType {
        return this.add(getMobileVersionModal(this));
    }
    accountDetailsMobile(): AccountDetailsMobileModalType {
        return this.add(getAccountDetailsMobileModal(this));
    }
    productsMobile(): ProductsMobileModalType {
        return this.add(getProductsMobileModal(this));
    }
    outOfService(): OutOfServiceModalType {
        return this.add(getOutOfServiceModal(this));
    }
}
