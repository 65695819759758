import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ModalContainer } from '@/components/Modal/ModalContainer';
import { useStore } from '../storeContext';
import { useTranslation } from 'react-i18next';
import Logo from '@/assets/logo/logo.svg';
import linkIcon from '@/assets/icons/link.svg';
import { Link } from '@/ui/nav/products/ProductsStore.types';

const LinkView = ({ link: { action, text } }: { link: Link }) => {
    const { t } = useTranslation();
    return (
        <a
            href={typeof action === 'string' ? action : undefined}
            className="flex items-center text-mobile-inputs-medium-light text-greyscale-primary mb-4"
            target="_blank"
            rel="noreferrer">
            <img src={linkIcon} className="mr-2" alt="social" />
            {t(text).toString()}
        </a>
    );
};

export const ProductsMobile = observer(() => {
    const store = useStore();
    const { t } = useTranslation();
    const modal = store?.ui.modals.activeModal;

    if (!modal || modal.type !== 'productsMobile') return null;

    const products = store?.ui.nav.products;

    const onClose = useCallback(() => {
        if (modal) modal.resolve();
    }, [modal]);

    return (
        <ModalContainer
            header={t('products.mainTitle')}
            onClose={onClose}
            isMobile={true}>
            <div className="py-6 px-4 grid gap-4">
                <img src={Logo} alt="logo" />
                <p className="text-mobile-body-medium text-greyscale-placeholder">
                    {t('products.mobileDescription')}
                </p>
                <div>
                    {products.links.map(link => {
                        return <LinkView key={link.id} link={link} />;
                    })}
                </div>
            </div>
        </ModalContainer>
    );
});
