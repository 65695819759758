import { Input } from '@/components/Input';
import { Labeled } from '@/components/Labeled';
import { Options } from '@/components/Options';
import { formatVolume } from '@/tools/format';
import { useVolumeRifm } from '@/tools/rifm/volumeRifm';
import { useStore } from '@/view/storeContext';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAmount } from './Amount.hooks';

export const OptionsView = observer(() => {
    const store = useStore();

    const { t } = useTranslation();

    const betInput = store?.ui.betsPage.betInput;

    const volumePercentage = betInput?.volumePercentage ?? [];

    const active = betInput?.activeVolumePercentage ?? null;

    const items = volumePercentage.map(item => ({
        value: item,
        label: item === 1 ? t('betInput.max') : `${item * 100}%`,
        name: `volume-${item * 100}-pc`,
        key: item.toString(),
    }));

    return (
        <Options
            items={items}
            active={active}
            onClick={value => betInput?.setVolumeByAvailable(value)}
        />
    );
});

export const Amount = observer(() => {
    const store = useStore();
    const { t } = useTranslation();
    const errorText = useAmount(store);

    if (!store) {
        return null;
    }

    const betInput = store.ui.betsPage.betInput;

    const rightText = (
        <Trans
            i18nKey="betInput.amountRightLabel"
            values={{
                amount: formatVolume(
                    betInput.availableCollateral,
                    betInput.asset?.decimals,
                ),
            }}
            components={[<span key={0} className="text-consts-white" />]}
        />
    );

    return (
        <Labeled label={t('betInput.amount')} rightText={rightText}>
            <div className="gap-2 grid">
                <Input
                    append="USDC"
                    {...useVolumeRifm(betInput.volumeStr ?? '', v =>
                        betInput.setVolumeStr(v),
                    )}
                    placeholder="0.00"
                    error={errorText}
                    inputMode="decimal"
                />
                <OptionsView />
            </div>
        </Labeled>
    );
});
