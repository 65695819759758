import downArrow from '@/assets/icons/arrowDownRed.svg';
import upArrow from '@/assets/icons/arrowUpGreen.svg';
import clock from '@/assets/icons/clock.svg';
import { BetType } from '@/bets/BetsStore.types';
import { formatVolume } from '@/tools/format';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { betStyles } from './Bet.css';

export const Bet: React.FC<{ bet: BetType; isMobile?: boolean }> = observer(
    ({ bet, isMobile }) => {
        const { t } = useTranslation();

        const isProfit = bet.isWinning ?? bet.status === 'win';
        const isEnded = bet.status !== 'active';

        const arrow = bet.direction === 'up' ? upArrow : downArrow;

        return (
            <div className={betStyles.container}>
                {bet.isPriceDefined || isEnded ? (
                    <>
                        <div className={betStyles.row(isMobile)}>
                            <div className={betStyles.info}>
                                <img
                                    src={arrow}
                                    alt="arrow"
                                    className={betStyles.icon(isMobile)}
                                />
                                <span className={betStyles.symbol(isMobile)}>
                                    {bet.market?.formattedSymbol ?? '-'}
                                </span>
                                <span className={betStyles.payout(isMobile)}>
                                    +{bet.rate * 100}%
                                </span>
                            </div>
                            <div className={betStyles.period(isMobile)}>
                                {isEnded && (
                                    <img
                                        src={clock}
                                        alt="clock"
                                        className={betStyles.clockIcon}
                                    />
                                )}{' '}
                                {bet.endsIn}
                            </div>
                        </div>
                        <div className={betStyles.row(isMobile)}>
                            <div className={betStyles.item(isMobile)}>
                                <span className={betStyles.label('left')}>
                                    {t('bet.amount')}
                                </span>
                                <span className={betStyles.textValue()}>
                                    $
                                    {formatVolume(
                                        bet.volume,
                                        bet.asset?.decimals,
                                    )}
                                </span>
                            </div>
                            <div className={betStyles.item(isMobile)}>
                                <span className={betStyles.label('right')}>
                                    {t('bet.profit')}
                                </span>
                                <span
                                    className={betStyles.textValue(
                                        isProfit ? isProfit : undefined,
                                    )}>
                                    {isProfit
                                        ? '+$' +
                                          formatVolume(
                                              bet.mayWin,
                                              bet.asset?.decimals,
                                          )
                                        : '$0.00'}
                                </span>
                            </div>
                        </div>
                        <div
                            className={betStyles.line(isProfit)}
                            style={{ width: `${bet.progress * 100}%` }}
                        />
                    </>
                ) : (
                    <div className="text-error-hover font-medium flex justify-center py-4">
                        {t('binanceError.bet')}
                    </div>
                )}
            </div>
        );
    },
);
