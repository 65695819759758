import { RootStoreType } from '@/RootStoreTypes';
import { Subscription } from 'rxjs';
import {
    BetsTableDataType,
    BetsTableStoreType,
    BetsTableTypes,
} from './BetsTableStore.types';

export class BetsTableStore implements BetsTableStoreType {
    private expandWidth: number;

    private subscriptions: Subscription[] = [];

    isOpened = false;
    tabs: BetsTableDataType[] = [
        {
            id: 'open',
            value: 'tableTabs.open',
        },
        {
            id: 'closed',
            value: 'tableTabs.closed',
        },
    ];

    activeTab: BetsTableTypes = 'open';

    constructor(private root: RootStoreType) {
        this.expandWidth = this.root.config.defaultValues.breakpoints.fullHd;
        this.browserWidthSubscription();
    }

    get betsError() {
        return this.root.bets.betsError;
    }

    get bets() {
        const isOpenTabActive = this.activeTab === 'open';

        return this.root.bets.bets.filter(bet => {
            const isBetActive = bet.status === 'active';

            return isBetActive === isOpenTabActive;
        });
    }

    get quantities() {
        const open = this.root.bets.bets.filter(bet => {
            return bet.status === 'active';
        }).length;

        const closed = this.root.bets?.bets.length - open;

        return { open, closed };
    }

    browserWidthSubscription(): void {
        const subscription = this.root.layoutSize.width$.subscribe(
            (width: number) => {
                if (width >= this.expandWidth) this.setIsOpened(true);
                else this.setIsOpened(false);
            },
        );

        this.subscriptions.push(subscription);
    }

    setActiveTab(tab: BetsTableTypes) {
        this.activeTab = tab;
    }

    setIsOpened(isOpened: boolean) {
        this.isOpened = isOpened;
    }
    destroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }
}
