import InfoIcon from '@/assets/icons/info.svg';
import { TFuncKey } from 'i18next';
import { BetIncorrectAmountType, ToastsStoreType } from '../ToastsStore.types';
import { getBaseToast } from './baseToast';

export const betIncorrectAmount = (
    toasts: ToastsStoreType,
    min: bigint,
    max: bigint,
    decimals: number | undefined,
    timeoutDelay?: number,
): BetIncorrectAmountType => {
    const base = {
        type: 'betIncorrectAmount' as const,
        header: 'toasts.incorrentAmountHeader' as TFuncKey,
        icon: InfoIcon,
        borderColor: 'border-primary-medium',
        timeoutDelay,
    };

    return {
        ...getBaseToast(toasts, base),
        min,
        max,
        decimals,
    };
};
