import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow.svg';
import profileIcon from '@/assets/profileIcon.png';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { ProfileBadgeProps } from './ProfileBadge.types';

export const ProfileBadge = ({
    handleClick,
    isOpen,
    hasOpen,
    address,
}: ProfileBadgeProps) => {
    const { t } = useTranslation();

    return (
        <div onClick={handleClick}>
            {hasOpen ? (
                <Button variant="address" name="nav-profile-address">
                    <img src={profileIcon} alt="profile" className="mr-2" />
                    <span className="text-greyscale-primary">{address}</span>
                    <ArrowIcon
                        className={`ml-2 transition-all fill-greyscale-placeholder ${
                            isOpen ? 'rotate-180' : ''
                        }`}
                    />
                </Button>
            ) : (
                <Button name="nav-profile-connect">
                    <span>
                        {address ? address : t('profilePopup.connectWalletBtn')}
                    </span>
                </Button>
            )}
        </div>
    );
};
