import React from 'react';
import { ToastContainer } from '../ToastContainer';
import { BetIncorrectAmountProps } from './BetIncorrectAmount.types';

import { formatVolume } from '@/tools/format';
import { Trans } from 'react-i18next';

export const BetIncorrectAmount = ({
    min,
    max,
    borderColor,
    icon,
    header,
    resolve,
    decimals,
}: BetIncorrectAmountProps) => {
    return (
        <ToastContainer
            header={header}
            borderColor={borderColor}
            icon={icon}
            onClose={resolve}>
            <div className="mt-3 mb-1.5">
                <Trans
                    i18nKey="toasts.incorrentAmountText"
                    values={{
                        min: formatVolume(min, decimals),
                        max: formatVolume(max, decimals),
                    }}
                    components={[
                        <span key={0} className="text-consts-white" />,
                    ]}
                />
            </div>
        </ToastContainer>
    );
};
