import { joinClassnames } from '@/tools/css';

const roundedContainer = 'rounded-lg';

export const fractualBetsStyles = {
    container: (isExpanded?: boolean) =>
        joinClassnames([
            'grid',
            isExpanded
                ? 'grid-cols-expanded-trade-layout'
                : 'grid-cols-trade-layout',
            'grid-rows-trade-layout',
            'h-full',
            'gap-2',
            'transition-all',
            'overflow-hidden',
        ]),
    nav: joinClassnames(['col-span-full', roundedContainer]),
    favoriteAssets: joinClassnames([roundedContainer]),
    chart: joinClassnames([
        roundedContainer,
        'overflow-hidden',
        'row-start-2',
        'row-span-2',
        'relative',
    ]),
    betInput: (isExpanded?: boolean) =>
        joinClassnames([
            isExpanded ? 'row-span-3' : 'row-span-2',
            roundedContainer,
            'bg-greyscale-background',
            'overflow-hidden',
            'pb-4',
        ]),
    table: (isExpanded?: boolean) =>
        joinClassnames([
            roundedContainer,
            isExpanded ? 'row-span-3' : 'row-span-1',
            isExpanded ? 'col-start-3' : 'col-start-2',
            'overflow-hidden',
        ]),
};
