import { useClassName } from '@/tools/css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputAppendProps } from './InputAppend.types';

export const InputAppend = ({ isActive, onClick }: InputAppendProps) => {
    const { t } = useTranslation();

    return (
        <div className="flex items-center">
            <span className="text-greyscale-placeholder">{t('usdc')}</span>
            <div
                className={useClassName(
                    'flex items-center justify-center rounded-md  ml-2 h-8 w-8 cursor-pointer transition-all',
                    isActive
                        ? 'bg-greyscale-background'
                        : 'bg-greyscale-disabled',
                )}
                onClick={onClick}>
                %
            </div>
        </div>
    );
};
