import { useDetectMobileKeyboard } from '@/tools/hooks/useDetectMobileKeyboard';
import React from 'react';
import { Buttons } from '../FractualBets/Buttons/Buttons';
import { Chart } from '../FractualBets/Chart/Chart';
import { BetInput } from './BetInput/BetInput';
import { BetInputInfo } from './BetInputInfo/BetInputInfo';

export const FractualBetsMobile = () => {
    const { isKeyboardActive, isLandscape } = useDetectMobileKeyboard();

    return (
        <div className="h-full grid grid-rows-mobile-trade-layout">
            <div className="relative">
                <Chart />
            </div>
            <div
                className="p-4 flex flex-col bg-greyscale-background bottom-0 left-0 w-full z-10"
                style={{
                    position:
                        isKeyboardActive && !isLandscape ? 'fixed' : 'relative',
                }}>
                <BetInputInfo />
                <BetInput />
                <div className="mt-4">
                    <Buttons isMobile={true} />
                </div>
            </div>
        </div>
    );
};
