import { TradeMessage } from './BinanceStore.types';

export class Trade {
    constructor(public raw: TradeMessage) {}

    get price() {
        return this.raw.p;
    }

    get quantity() {
        return this.raw.q;
    }

    get transactionTime() {
        return this.raw.T;
    }
}
