import {
    EntityId,
    IChartWidgetApi,
    ILineDataSourceApi,
    PricedPoint,
    ShapePoint,
} from '@charting-library/';
import { LabelType } from './Label.types';

export class Label implements LabelType {
    private labelHeight = 32;
    private labelMargin = 10;

    shape: ILineDataSourceApi;
    points: PricedPoint[];

    constructor(private chart: IChartWidgetApi, private id: EntityId) {
        this.shape = this.chart.getShapeById(id);
        this.points = this.shape.getPoints();
    }

    private get priceForPixel() {
        const priceRange = this.chart
            .getPanes()[0]
            .getMainSourcePriceScale()
            ?.getVisiblePriceRange();

        const chartHeight = this.chart.getAllPanesHeight()[0];

        if (priceRange) {
            const delta = Math.abs(priceRange.from - priceRange.to);
            return delta / chartHeight;
        }
        return 0;
    }

    get width() {
        const { font: fontFamily, fontsize, text } = this.shape.getProperties();
        const padding = 16;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const font = `${fontsize}px ${fontFamily}`;

        if (context) {
            context.font = font;
            return context.measureText(text).width + padding + this.labelMargin;
        }
        return 0;
    }

    get height() {
        return (this.labelHeight + this.labelMargin) * this.priceForPixel;
    }

    setPoints(points: ShapePoint[]) {
        this.shape.setPoints(points);
    }
}
