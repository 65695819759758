import { joinClassnames } from '@/tools/css';

export const toastsStyles = {
    container: joinClassnames([
        'absolute',
        'bottom-[84px]',
        'left-[72px]',
        'grid',
        'gap-2',
    ]),
};
