import { Toast, ToastsStoreType } from '../ToastsStore.types';

let lastToastId = 0;

export const getBaseToast = <T>(
    toasts: ToastsStoreType,
    base: Omit<Toast<T>, 'id' | 'resolve' | 'timeoutId'>,
    passedId?: number,
): Toast<T> => {
    if (!passedId) lastToastId++;
    else if (passedId > lastToastId) lastToastId = passedId;

    const id = passedId ?? lastToastId;

    const removeToast = () => {
        toasts.clearTimeout(id);
        toasts.remove(id);
    };

    const delay = base.timeoutDelay
        ? base.timeoutDelay
        : toasts.defaultTimeoutDelay;

    return {
        id,
        ...base,
        resolve() {
            removeToast();
        },
        timeoutId: setTimeout(() => {
            removeToast();
        }, delay),
    };
};
