import { ModalContainer } from '@/components/Modal/ModalContainer';
import { WalletConnector } from '@/components/WalletConnector';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useStore } from '../storeContext';
import { LOGO_MAP } from './walletIcon';
import { WalletModalFooter } from './WalletModalFooter';

export const ConfirmWalletModal: React.FC = observer(() => {
    const store = useStore();
    const { t } = useTranslation();

    const modal = store?.ui.modals.activeModal;

    if (modal && modal.type === 'confirmWallet') {
        return (
            <ModalContainer
                header={t('confirmWallet.header')}
                onClose={() => modal.resolve(false)}
                footer={<WalletModalFooter />}>
                <WalletConnector
                    mode="selected"
                    name={modal.wallet}
                    onClick={() => modal.resolve(true)}
                    iconUrl={LOGO_MAP[modal.wallet]}
                />
                <div className="py-2">
                    <p className="text-greyscale-placeholder">
                        <Trans
                            i18nKey="confirmWallet.terms"
                            components={[
                                <a
                                    key="terms"
                                    className="underline"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://docs.logium.org/product-docs/external-links/terms-of-service"
                                />,
                            ]}
                        />
                    </p>
                </div>
            </ModalContainer>
        );
    } else {
        return null;
    }
});
