import { joinClassnames } from '@/tools/css';

export const payoutStyles = {
    container: joinClassnames([
        'flex',
        'h-16',
        'rounded-lg',
        'border',
        'border-greyscale-line',
        'px-2',
        'py-1',
    ]),
    top: joinClassnames(['w-full', 'mb-1']),
    bottom: joinClassnames(['w-full']),
    payout: joinClassnames(['w-3/5', 'border-r', 'border-r-greyscale-line']),
    time: joinClassnames(['ml-4', 'px-2']),

    payoutText: joinClassnames([
        'w-full',
        'text-xs',
        'text-greyscale-placeholder',
    ]),
    timeText: joinClassnames(['text-xs', 'text-greyscale-placeholder']),

    payoutValue: joinClassnames(['w-full', 'text-lg', 'text-success-default']),
    payoutPercentageValue: joinClassnames([
        'w-full',
        'text-xs',
        'text-success-default',
        'ml-1',
    ]),
    timeValue: joinClassnames(['text-consts-white', 'text-lg']),
};
