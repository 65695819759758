import { SerializableValue, StorageType } from './Storage.types';

export class RamStorage implements StorageType {
    constructor(private localStorage: Storage) {}

    getItem<T extends SerializableValue>(key: string): T | null {
        const str = this.localStorage.getItem(key);
        if (str === null) {
            return null;
        }
        return JSON.parse(str);
    }

    setItem<T extends SerializableValue>(key: string, value: T): void {
        this.localStorage.setItem(key, JSON.stringify(value));
    }
}
