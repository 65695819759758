import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabStyles } from './Tab.css';
import { TabProps } from './Tab.types';

export const Tab = <T extends string>({
    id,
    value,
    isActive,
    onClick,
    isMobile,
    quantity,
}: TabProps<T>) => {
    const { t } = useTranslation();

    const handleClick = () => {
        onClick?.(id);
    };

    return (
        <div
            className={TabStyles.tab(isActive, isMobile)}
            onClick={handleClick}>
            {t(value).toString()}
            {quantity ? <span> ({quantity})</span> : null}
        </div>
    );
};
