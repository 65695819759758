import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../storeContext';
import { Amount } from './Amount/Amount';
import { betInputStyles } from './BetInput.css';
import { Buttons } from './Buttons/Buttons';
import { Payout } from './Payout/Payout';
import { Time } from './Time/Time';

export const BetInput: React.FC = observer(() => {
    const store = useStore();

    if (!store) {
        return null;
    }

    return (
        <div className={betInputStyles.container}>
            <div className={betInputStyles.row}>
                <Time />
            </div>
            <div className={betInputStyles.row}>
                <Amount />
            </div>
            <div className={betInputStyles.row}>
                <Payout />
            </div>
            <div className={betInputStyles.row}>
                <Buttons />
            </div>
        </div>
    );
});
