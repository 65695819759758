import newsletterIcon from '@/assets/icons/newsletter.svg';
import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { ModalContainer } from '@/components/Modal/ModalContainer';
import { useClassName } from '@/tools/css';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../storeContext';

export const NewsletterModal: React.FC = observer(() => {
    const store = useStore();
    const { t } = useTranslation();

    const modal = store?.ui.modals.activeModal;
    const isMobile = store?.device.isMobile;

    if (modal && modal.type === 'newsletter') {
        const onClose = useCallback(() => {
            modal.resolve();
        }, [modal]);

        return (
            <ModalContainer
                header=""
                onClose={onClose}
                extended={true}
                isMobile={isMobile}
                headerBorder={false}>
                <div
                    id={`mlb2-${store.config.defaultValues.newsletter.id}`}
                    className={useClassName(
                        'ml-form-embedContainer ml-subscribe-form h-full flex items-center',
                        `ml-subscribe-form-${store.config.defaultValues.newsletter.id}`,
                        isMobile ? 'p-4' : '',
                    )}>
                    <div className="ml-form-align-center ">
                        <div className="ml-form-embedWrapper embedForm">
                            <div className="ml-form-embedBody ">
                                <div className="ml-form-embedContent flex flex-col justify-center items-center">
                                    <img
                                        src={newsletterIcon}
                                        alt="newsletter"
                                        className="mb-7 "
                                    />
                                    <h4 className="text-greyscale-primary font-semibold">
                                        {t('newsletter.heading')}
                                    </h4>
                                    <p className="text-center text-sm text-greyscale-placeholder font-medium my-2 px-8">
                                        {t('newsletter.text')}
                                    </p>
                                </div>
                                <form
                                    className="ml-block-form mt-6"
                                    action="https://static.mailerlite.com/webforms/submit/i7a2v4"
                                    data-code="i7a2v4"
                                    method="post"
                                    target="_blank">
                                    <div className="ml-form-formContent horozintalForm">
                                        <div
                                            className={`ml-form-horizontalRow grid gap-4 ${
                                                isMobile
                                                    ? ''
                                                    : 'grid-cols-newsletter'
                                            }`}>
                                            <div className="ml-input-horizontal flex-grow">
                                                <div
                                                    style={{ width: '100%' }}
                                                    className="horizontal-fields">
                                                    <div className="ml-field-group ml-field-email ml-validate-email ml-validate-required">
                                                        <Input
                                                            type="email"
                                                            className="h-10 text-sm"
                                                            data-inputmask=""
                                                            name="fields[email]"
                                                            placeholder="Enter email address.."
                                                            autoComplete="email"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ml-button-horizontal primary">
                                                <Button
                                                    type="submit"
                                                    className="primary h-full w-full px-11">
                                                    {t('newsletter.button')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        type="hidden"
                                        name="ml-submit"
                                        value="1"
                                    />
                                    <input
                                        type="hidden"
                                        name="anticsrf"
                                        value="true"
                                    />
                                </form>
                                <div className="border-t border-greyscale-line pt-6 mt-6">
                                    <div className="flex justify-between text-greyscale-primary text-sm font-semibold mb-4">
                                        <span>
                                            {t('newsletter.linkHeading')}
                                        </span>
                                        <a
                                            href={
                                                store.config.defaultValues
                                                    .logiumBets
                                            }
                                            target="_blank"
                                            rel="noreferrer">
                                            {'->'}
                                        </a>
                                    </div>
                                    <p className="text-xs text-greyscale-placeholder">
                                        {t('newsletter.linkDescription')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img
                        src="https://track.mailerlite.com/webforms/o/5847402/i7a2v4?v1672920117"
                        width="1"
                        height="1"
                        style={{
                            maxWidth: '1px',
                            maxHeight: '1px',
                            visibility: 'hidden',
                            padding: 0,
                            margin: 0,
                            display: 'block',
                        }}
                        alt="."
                    />
                </div>
            </ModalContainer>
        );
    } else {
        return null;
    }
});
