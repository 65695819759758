import ReadmeIcon from '@/assets/icons/readme.svg';
import { Button } from '@/components/Button';
import { ModalContainer } from '@/components/Modal/ModalContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../storeContext';

export const ResetVirtualAccount = () => {
    const store = useStore();
    const { t } = useTranslation();

    const modal = store?.ui.modals.activeModal;

    if (modal && modal.type !== 'resetVirtualAccount') return null;

    return (
        <ModalContainer
            isMobile={store?.device.isMobile}
            header={t('resetVirtualAccount.header')}
            footer={
                <Button
                    className="w-full py-2.5 font-bold"
                    onClick={() => modal?.resolve(true)}>
                    {t('resetVirtualAccount.button')}
                </Button>
            }
            onClose={() => modal?.resolve(false)}>
            <div className="w-full h-full flex flex-col flex-grow items-center justify-center px-4">
                <div className="flex justify-center items-center w-12 h-12 rounded-lg bg-greyscale-input mb-4">
                    <img src={ReadmeIcon} className="w-5 h-5" alt="readme" />
                </div>
                <p className="text-greyscale-primary font-bold text-xs mb-2 text-center">
                    {t('resetVirtualAccount.mainText')}
                </p>
                <p className="text-greyscale-placeholder text-xs text-center">
                    {t('resetVirtualAccount.text')}
                </p>
            </div>
        </ModalContainer>
    );
};
