import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../storeContext';
import { AccountDetailsMobile } from './AccountDetailsMobile';
import { ConfirmWalletModal } from './ConfirmWalletModal';
import { CreateSignatureWallet } from './CreateSignatureWallet';
import { DepositModal } from './DepositModal';
import { LogiumCoreAllowanceModal } from './LogiumCoreAllowanceModal';
import { MobileVersion } from './MobileVersionModal';
import { NewsletterModal } from './NewsletterModal';
import { OutOfServiceModal } from './OutOfServiceModal';
import { ProductsMobile } from './ProductsMobile';
import { ResetVirtualAccount } from './ResetVirtualAccountModal';
import { SelectWalletModal } from './SelectWalletModal';
import { StatusModal } from './StatusModal';
import { TopUpModal } from './TopUpModal';
import { WaitModal } from './WaitModal';
import { WalletConnectionStatusModal } from './WalletConnectionStatusModal';
import { WithdrawModal } from './WithdrawModal';

export const Modals: React.FC = observer(() => {
    const store = useStore();

    const activeBetType = store?.ui.modals.activeModal?.type ?? null;

    switch (activeBetType) {
        case 'selectWallet':
            return <SelectWalletModal />;
        case 'confirmWallet':
            return <ConfirmWalletModal />;
        case 'createSignatureWallet':
            return <CreateSignatureWallet />;
        case 'deposit':
            return <DepositModal />;
        case 'withdraw':
            return <WithdrawModal />;
        case 'topUp':
            return <TopUpModal />;
        case 'approveLogiumCore':
            return <LogiumCoreAllowanceModal />;
        case 'wait':
            return <WaitModal />;
        case 'walletConnectionStatus':
            return <WalletConnectionStatusModal />;
        case 'resetVirtualAccount':
            return <ResetVirtualAccount />;
        case 'newsletter':
            return <NewsletterModal />;
        case 'status':
            return <StatusModal />;
        case 'mobileVersion':
            return <MobileVersion />;
        case 'accountDetailsMobile':
            return <AccountDetailsMobile />;
        case 'productsMobile':
            return <ProductsMobile />;
        case 'outOfService':
            return <OutOfServiceModal />;
        default:
            return null;
    }
});

Modals.displayName = 'Modals';
