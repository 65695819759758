import React, { useCallback } from 'react';
import { ModalContainer } from '@/components/Modal/ModalContainer';
import { Button } from '@/components/Button';
import InfoIcon from '@/assets/icons/readme.svg';
import { useStore } from '../storeContext';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

export const MobileVersion = observer(() => {
    const { t } = useTranslation();
    const store = useStore();
    const modal = store?.ui.modals.activeModal;

    if (modal && modal.type === 'mobileVersion') {
        const onClose = useCallback(() => {
            modal?.resolve();
        }, [modal]);

        return (
            <ModalContainer
                header={t('mobileVersion.header')}
                onClose={onClose}
                extended={false}
                headerBorder={true}
                footer={
                    <Button className="w-full py-2.5" onClick={onClose}>
                        {t('mobileVersion.button')}
                    </Button>
                }>
                <div className="flex flex-1 items-center flex-col justify-center">
                    <div className="w-12 h-12 bg-greyscale-input rounded-lg flex justify-center items-center">
                        <img src={InfoIcon} className="w-5 h-5" alt="info" />
                    </div>
                    <p className="font-semibold text-greyscale-secondary text-sm mt-4 mb-2">
                        {t('mobileVersion.title')}
                    </p>
                    <span className=" text-xs text-greyscale-placeholder font-medium text-center">
                        {t('mobileVersion.description')}
                    </span>
                </div>
            </ModalContainer>
        );
    } else {
        return null;
    }
});
