import { If } from '@/components/If/If';
import { useStore } from '@/view/storeContext';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Bet } from './Bet/Bet';
import { ErrorState } from './ErrorState/ErrorState';
import { NoData } from './NoData/NoData';
import { tableStyles } from './Table.css';
import { TableNav } from './TableNav/TableNav';

export const Table: React.FC = observer(() => {
    const store = useStore();

    if (!store) return null;

    const betsTable = store.ui.betsPage.betsTable;

    return (
        <div className={tableStyles.table}>
            <TableNav />
            <div className={tableStyles.betBody}>
                <If condition={betsTable.betsError}>
                    <ErrorState />
                </If>
                <If condition={!betsTable.betsError}>
                    {betsTable.bets.map(bet => (
                        <Bet bet={bet} key={bet.id} />
                    ))}

                    <NoData
                        tab={betsTable.activeTab}
                        show={betsTable.bets.length === 0}
                    />
                </If>
            </div>
        </div>
    );
});
