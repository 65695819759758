import { joinClassnames } from '@/tools/css';

export const optionsStyles = {
    container: joinClassnames(['w-full', 'flex', 'gap-2']),
    option: (active: boolean) =>
        joinClassnames([
            'bg-greyscale-input',
            'w-11',
            'h-8',
            'flex',
            'justify-center',
            'items-center',
            'text-xs',
            'font-semibold',
            'rounded-md',
            'cursor-pointer',
            active ? 'border' : '',
            active ? 'border-primary-defaultDivergent' : '',
            active ? 'text-greyscale-primary' : 'text-greyscale-placeholder',
        ]),
};
