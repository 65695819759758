import React from 'react';

import desktop from '@/assets/icons/desktop.svg';
import { useTranslation } from 'react-i18next';
import { VirtualInfoMobileProps } from './VirtualInfoMobile.types';

export const VirtualInfoMobile = ({ className }: VirtualInfoMobileProps) => {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <div className="p-4 rounded-md bg-greyscale-input inline-flex">
                <img src={desktop} alt="desktop" />
            </div>
            <p className="text-greyscale-secondary text-mobile-body-large mt-4">
                {t('profilePopup.mobileInfo')}
            </p>
        </div>
    );
};
