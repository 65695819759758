import { joinClassnames } from '@/tools/css';

export const walletHeaderStyles = {
    container: joinClassnames([
        'w-full',
        'flex',
        'flex-col',
        'justify-between',
        'p-[8px]',
        'h-16',
        'rounded-lg',
        'bg-greyscale-input',
    ]),
    text: joinClassnames([
        'text-greyscale-secondary',
        'text-xs',
        'font-medium',
    ]),
    address: joinClassnames([
        'text-greyscale-primary',
        'text-base',
        'font-semibold',
    ]),
};
