import {
    AccountDetailsMobileModalType,
    ModalsStoreType,
} from '../ModalsStore.types';
import { getBaseModal } from './baseModal';

export const getAccountDetailsMobileModal = (
    modals: ModalsStoreType,
): AccountDetailsMobileModalType =>
    getBaseModal(modals, 'accountDetailsMobile');
