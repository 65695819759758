import { Button } from '@/components/Button';
import { ModalContainer } from '@/components/Modal/ModalContainer';
import { WalletConnector } from '@/components/WalletConnector';
import { Mode } from '@/components/WalletConnector/WalletConnector.types';
import {
    ConnectionStatus,
    WalletConnectionStatusModalType,
} from '@/modals/ModalsStore.types';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../storeContext';
import { LOGO_MAP } from './walletIcon';
import { NAMES_MAP } from './walletName';
import errInfo from '@/assets/icons/errInfo.svg';
import successInfo from '@/assets/icons/successInfo.svg';
import spinner from '@/assets/icons/spinner.svg';

import { ModalMessage } from '@/components/Modal/ModalMessage';
import { useTranslation } from 'react-i18next';

const Status2Mode: Record<ConnectionStatus, Mode> = {
    wait: 'selected',
    success: 'success',
    failed: 'error',
};

const SuccessFooter: React.FC<{ modal: WalletConnectionStatusModalType }> =
    observer(({ modal }) => {
        const { t } = useTranslation();
        return (
            <Button className="w-full" onClick={() => modal.goToTrade()}>
                {t('walletConnectionStatus.goToTrade')}
            </Button>
        );
    });

const ErrorFooter: React.FC<{ modal: WalletConnectionStatusModalType }> =
    observer(({ modal }) => {
        const { t } = useTranslation();
        return (
            <div className="flex flex-col gap-2">
                <Button className="w-full" onClick={() => modal.retry()}>
                    {t('walletConnectionStatus.retry')} {modal.wallet}
                </Button>
                <Button className="w-full" onClick={() => modal.tryOther()}>
                    {t('walletConnectionStatus.diffrentWallet')}
                </Button>
            </div>
        );
    });

const StatusBody: React.FC<{ modal: WalletConnectionStatusModalType }> = ({
    modal,
}) => {
    const { t } = useTranslation();
    switch (modal.status) {
        case 'wait':
            return (
                <ModalMessage
                    iconUrl={spinner}
                    iconSize={8}
                    spin={true}
                    title={t('walletConnectionStatus.waitTitle')}
                    message={t('walletConnectionStatus.waitMessage')}
                />
            );
        case 'success':
            return (
                <ModalMessage
                    iconUrl={successInfo}
                    iconBackground={true}
                    title={t('walletConnectionStatus.successTitle')}
                    message={t('walletConnectionStatus.successMessage')}
                />
            );
        case 'failed':
            return (
                <ModalMessage
                    iconUrl={errInfo}
                    iconBackground={true}
                    title={t('walletConnectionStatus.failedTitle')}
                    message={t('walletConnectionStatus.failedMessage')}
                />
            );
    }
};

const getStatusFooter = (modal: WalletConnectionStatusModalType) => {
    switch (modal.status) {
        case 'wait':
            return undefined;
        case 'success':
            return <SuccessFooter modal={modal} />;
        case 'failed':
            return <ErrorFooter modal={modal} />;
    }
};

export const WalletConnectionStatusModal = observer(() => {
    const store = useStore();
    const { t } = useTranslation();
    const modal = store?.ui.modals.activeModal;
    if (modal?.type !== 'walletConnectionStatus') {
        return null;
    }

    return (
        <ModalContainer
            header={t('walletConnectionStatus.header')}
            onClose={() => modal.resolve()}
            footer={getStatusFooter(modal)}>
            <WalletConnector
                iconUrl={LOGO_MAP[modal.wallet]}
                name={NAMES_MAP[modal.wallet] ?? ''}
                mode={Status2Mode[modal.status]}
            />
            <StatusBody modal={modal} />
        </ModalContainer>
    );
});
