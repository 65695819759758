import ErrorIcon from '@/assets/icons/error.svg';
import SuccessIcon from '@/assets/icons/success.svg';
import { BetType } from '@/bets/BetsStore.types';
import { TFuncKey } from 'i18next';
import {
    BetResolvedType,
    MultipleBetsResolvedType,
    ToastsStoreType,
} from '../ToastsStore.types';
import { getBaseToast } from './baseToast';

export const multipleBetsResolved = (
    toasts: ToastsStoreType,
    bet: BetType,
    lastToast: BetResolvedType | MultipleBetsResolvedType,
    count: number,
    decimals: number | undefined,
): MultipleBetsResolvedType => {
    const statusOptions = <W, L>(winOption: W, loseOption: L) => {
        return bet.status === 'win' ? winOption : loseOption;
    };
    const base = {
        type: 'multipleBetsResolved' as const,
        header: statusOptions(
            'toasts.multipleWinHeader',
            'toasts.multipleLoseHeader',
        ) as TFuncKey,
        headerValues: {
            count,
        },
        icon: statusOptions(SuccessIcon, ErrorIcon),
        borderColor: statusOptions(
            'border-success-default',
            '!border-error-default',
        ),
    };

    return {
        ...getBaseToast(toasts, base, lastToast.id),
        symbol: bet.market?.formattedSymbol ?? '-',
        isUp: bet.direction === 'up' ? true : false,
        volume: statusOptions(lastToast.volume + bet.mayWin, 0n),
        decimals,
        volumeText: 'toasts.multipleProfit',
    };
};
