import { RootStoreType } from '@/RootStoreTypes';
import { BinanceRestApi } from './BinanceRestApi';
import { ServerTimeStoreType } from './ServerTimeStore.types';

export class ServerTimeStore implements ServerTimeStoreType {
    private api: BinanceRestApi;
    private resolver: ((status: boolean) => void) | undefined = undefined;
    isReady: Promise<boolean>;
    serverTime = new Date().getTime();
    timeDiff = 0;

    constructor(root: RootStoreType) {
        this.api = new BinanceRestApi(root.config.defaultValues.binance.apiUrl);
        this.isReady = new Promise<boolean>(
            resolve => (this.resolver = resolve),
        );

        this.init();
    }

    private async init() {
        try {
            this.serverTime = await this.api.getTime().then(time => {
                this.resolver?.(true);
                return time;
            });

            this.timeDiff = new Date().getTime() - this.serverTime;
        } catch {
            this.resolver?.(false);
        }
    }

    get chartServerTime() {
        return this.serverTime / 1000;
    }

    getNow() {
        return new Date().getTime() - this.timeDiff;
    }

    getTime(timestamp: number) {
        return timestamp - this.timeDiff;
    }

    getOffsetFromNow(timestamp: number) {
        return timestamp - this.getNow();
    }
}
