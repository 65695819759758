import { joinClassnames } from '@/tools/css';

export const infoRowsStyles = {
    container: joinClassnames([
        'grid',
        'gap-2',
        'w-full',
        'p-2',
        'border',
        'border-greyscale-line',
        'rounded-md',
        'text-greyscale-placeholder',
        'text-xs',
        'font-semibold',
    ]),
    row: joinClassnames(['flex', 'justify-between']),
    value: (active: boolean) => (active ? 'text-greyscale-secondary' : ''),
};
