import {
    CreateSignatureWalletModalType,
    ModalsStoreType,
} from '../ModalsStore.types';
import { getBaseModal } from './baseModal';

export const getCreateSignatureWalletModal = (
    modals: ModalsStoreType,
): CreateSignatureWalletModalType => {
    return getBaseModal(modals, 'createSignatureWallet');
};
