import { merge, Observable, of, Subject, switchMap } from 'rxjs';
import { ServerInfoStoreType } from './ServerInfoStore.types';

export class ServerInfoProxy implements ServerInfoStoreType {
    get asset() {
        return this.target.asset;
    }

    get logiumV2CoreAddress() {
        return this.target.logiumV2CoreAddress;
    }

    get chainId() {
        return this.target.chainId;
    }

    updated$: Observable<void>;
    private target$ = new Subject<ServerInfoStoreType>();

    constructor(private target: ServerInfoStoreType) {
        const target$ = merge(of(this.target), this.target$);
        this.updated$ = target$.pipe(switchMap(target => target.updated$));
    }

    setTarget(target: ServerInfoStoreType) {
        this.target.destroy();
        this.target = target;
        this.target$.next(target);
    }
    destroy(): void {
        this.target.destroy();
    }
}
