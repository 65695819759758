import { RootStoreType } from '@/RootStoreTypes';
import { MarketType } from '@/markets/MarketsStore.types';
import { Subject } from 'rxjs';
import { BetsPageStoreType } from './BetsPageStore.types';
import { BetInputStoreTypes } from './betInput/BetInputStore.types';
import { BetsTableStoreType } from './betsTable/BetsTableStore.types';
import { FavoriteMarketsStoreType } from './favoriteMarkets/FavoriteMarketsStore.types';
import { HighlightSymbolType } from './highlightSymbol/HighlightSymbol.types';

export class BetsPageStore implements BetsPageStoreType {
    betInput: BetInputStoreTypes;
    favoriteMarkets: FavoriteMarketsStoreType;
    highlightSymbol: HighlightSymbolType;
    betsTable: BetsTableStoreType;

    market?: MarketType = undefined;

    market$ = new Subject<MarketType | undefined>();

    constructor(
        root: RootStoreType,
        betInputFactory: (root: RootStoreType) => BetInputStoreTypes,
        favoriteMarketsStoreFactory: (
            root: RootStoreType,
        ) => FavoriteMarketsStoreType,
        highlightSymbolFactory: (root: RootStoreType) => HighlightSymbolType,
        betsTableStoreFactory: (root: RootStoreType) => BetsTableStoreType,
    ) {
        this.betInput = betInputFactory(root);
        this.favoriteMarkets = favoriteMarketsStoreFactory(root);
        this.highlightSymbol = highlightSymbolFactory(root);
        this.betsTable = betsTableStoreFactory(root);
    }

    setMarket(market: MarketType) {
        this.market = market;
        this.market$.next(market);
    }
}
