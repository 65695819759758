import { MarketType } from '@/markets/MarketsStore.types';
import { RootStoreType } from '@/RootStoreTypes';
import { isDefined } from '@/tools/isDefined';
import { Subject, Unsubscribable } from 'rxjs';
import {
    FavoriteMarketsStoreType,
    FavoriteMarketType,
} from './FavoriteMarketsStore.types';

export class FavoriteMarketsStore implements FavoriteMarketsStoreType {
    private static StorageKey = 'FavoriteMarkets';
    addSelected$ = new Subject<void>();

    // Flag to determine if search modal is opened to add pair to Favorite Markets
    // or just to open different pair on chart
    addToFavoriteFlag = false;

    private symbols: string[] = [];

    private itemsMap = new Map<string, FavoriteMarketType>();

    get items(): FavoriteMarketType[] {
        return this.symbols
            .map(symbol => this.lazyCreateItem(symbol))
            .filter<FavoriteMarketType>(isDefined);
    }

    private lazyCreateItem(symbol: string) {
        const item = this.itemsMap.get(symbol);
        if (item) {
            return item;
        } else {
            const newItem = this.createItem(symbol);
            if (newItem) {
                this.itemsMap.set(symbol, newItem);
            }
            return newItem;
        }
    }

    private createItem(symbol: string) {
        const market = this.root.markets.markets.get(symbol);
        return market && this.favoriteMarketFactory(this.root, market);
    }

    private subscriptions: Unsubscribable[];

    constructor(
        private root: RootStoreType,
        private favoriteMarketFactory: (
            root: RootStoreType,
            market: MarketType,
        ) => FavoriteMarketType,
    ) {
        this.onMarketChange = this.onMarketChange.bind(this);
        this.symbols = this.getSymbols() ?? [];
        this.subscriptions = [
            this.root.markets.markets$.subscribe(this.onMarketChange),
        ];
    }

    private onMarketChange() {
        this.itemsMap.clear();
    }

    setAddToFavoriteFlag(value: boolean) {
        this.addToFavoriteFlag = value;
    }

    addBySymbol(symbol: string) {
        const market = this.root.markets.markets.get(symbol);

        if (this.addToFavoriteFlag && market) {
            this.add(market);
            this.addToFavoriteFlag = false;
        }
    }

    add(market: MarketType) {
        const id = this.symbols.indexOf(market.symbol);
        if (id < 0) {
            this.symbols.push(market.symbol);
            this.setSymbols(this.symbols);
        }
    }

    addSelected() {
        this.addSelected$.next();

        setTimeout(() => {
            this.setAddToFavoriteFlag(true);
        }, 0);
    }

    remove(market: MarketType) {
        const id = this.symbols.indexOf(market.symbol);
        if (id >= 0) {
            this.symbols.splice(id, 1);
            this.itemsMap.delete(market.symbol);
        }
        this.setSymbols(this.symbols);
    }
    destroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];
    }
    private getSymbols() {
        return this.root.storage.getItem<string[]>(
            FavoriteMarketsStore.StorageKey,
        );
    }

    private setSymbols(symbols: string[]) {
        return this.root.storage.setItem<string[]>(
            FavoriteMarketsStore.StorageKey,
            symbols,
        );
    }
}
