import React from 'react';
import { useTranslation } from 'react-i18next';
import { componentHeaderStyles } from './ComponentHeader.css';

export const ComponentHeader = () => {
    const { t } = useTranslation();
    return (
        <div className={componentHeaderStyles.container}>
            <span className={componentHeaderStyles.textStyles}>
                {t('sidebar.takeATrade')}
            </span>
        </div>
    );
};
