import InfoIcon from '@/assets/icons/info.svg';
import { BetType } from '@/bets/BetsStore.types';
import { TFuncKey } from 'i18next';
import { BetCreatedType, ToastsStoreType } from '../ToastsStore.types';
import { getBaseToast } from './baseToast';

export const betCreated = (
    toasts: ToastsStoreType,
    bet: BetType,
    decimals: number | undefined,
    timeoutDelay?: number,
): BetCreatedType => {
    const base = {
        type: 'betCreated' as const,
        header: 'toasts.placedHeader' as TFuncKey,
        icon: InfoIcon,
        borderColor: 'border-primary-medium',
        timeoutDelay,
    };

    return {
        ...getBaseToast(toasts, base),
        symbol: bet.market?.formattedSymbol ?? '-',
        isUp: bet.direction === 'up' ? true : false,
        volume: bet.volume,
        decimals,
        volumeText: 'toasts.amount',
    };
};
