import { ConfigStoreType } from './ConfigStore.types';
import { DefaultConfigType } from './DefaultConfig/DefaultConfig.types';

export class ConfigStore implements ConfigStoreType {
    defaultValues: DefaultConfigType;

    constructor(configFactory: () => DefaultConfigType) {
        this.defaultValues = configFactory();
    }

    supportedConnectors = {
        server: ['metamask'],
        local: ['metamask'],
    } as ConfigStoreType['supportedConnectors'];

    isVirtual = false;

    tRpc = {
        wsUrl: `wss://logium-v2-ws.staging.intranet.studentcoin.dev/trpc`,
        httpUrl: 'https://logium-v2.staging.intranet.studentcoin.dev/trpc',
    };

    web3 = {
        name: 'Logium Exchange',
        version: '1',
        gateWay: 'http://localhost:8545',
        chainId: '31337' as const,
        isVirtualMode: false,
    };
    contracts = {
        USDC: '0x55b8FB84DbE962994C5EC704e7d9333B1C3dbF1e',
        logiumCore: '0x1f88917dbcFAD02e2884bAE634A968527981bDc6',
    };

    analytics = {
        google: {
            trackingId: 'UA-228495756-7',
            url: 'https://www.googletagmanager.com/gtag/js?id=',
        },
        hotjar: {
            id: 3277405,
            url: 'https://static.hotjar.com/c/hotjar-',
        },
    };
}
