import { RootStoreType } from '@/RootStoreTypes';
import { IChartWidgetApi } from '@charting-library/';
import {
    concat,
    distinctUntilChanged,
    from,
    map,
    startWith,
    Subscription,
} from 'rxjs';
import { ChartSubscribeType } from '../StrikePrice/StrikePrice.types';
import {
    BarCallback,
    BetCallback,
    ChartSubscription,
    ChartSubscriptionType,
    EmptyCallback,
    StrikePriceUpdateType,
    SubscriptionType,
} from './StrikePriceUpdate.types';

export class StrikePriceUpdate implements StrikePriceUpdateType {
    subscriptions: Map<SubscriptionType, Subscription> = new Map();
    chartSubscriptions: Map<ChartSubscriptionType, ChartSubscription> =
        new Map();

    constructor(
        private root: RootStoreType,
        private chart: IChartWidgetApi,
        private subscribe: ChartSubscribeType,
        private unsubscribe: ChartSubscribeType,
    ) {}

    clearUpdate(type: SubscriptionType) {
        this.subscriptions.get(type)?.unsubscribe();
        this.subscriptions.delete(type);
    }

    private clearChartUpdate(type: ChartSubscriptionType) {
        const subObject = this.chartSubscriptions.get(type);

        if (subObject) {
            subObject.unsubscribe(null, subObject.subscribe);
            this.chartSubscriptions.delete(type);
        }
    }

    onChartPeriodChange(callback: EmptyCallback) {
        this.clearChartUpdate('periodChanged');

        this.chart.onIntervalChanged().subscribe(null, callback);

        this.chartSubscriptions.set('periodChanged', {
            subscribe: callback,
            unsubscribe: this.chart.onIntervalChanged().unsubscribe,
        });
    }

    onChartDataLoaded(callback: EmptyCallback) {
        this.chart.onDataLoaded().subscribe(null, callback, true);
    }

    onChartSymbolChange(callback: EmptyCallback) {
        this.clearChartUpdate('symbolChanged');

        this.chart.onSymbolChanged().subscribe(null, callback);

        this.chartSubscriptions.set('symbolChanged', {
            subscribe: callback,
            unsubscribe: this.chart.onSymbolChanged().unsubscribe,
        });
    }

    onChartPeriodChangeAndDataLoaded(callback: EmptyCallback) {
        this.onChartPeriodChange(() => {
            this.onChartDataLoaded(callback);
        });
    }

    onChartSymbolChangeAndDataLoaded(callback: EmptyCallback) {
        this.onChartSymbolChange(() => {
            this.onChartDataLoaded(callback);
        });
    }

    onNewBar(callback: BarCallback) {
        this.clearChartUpdate('onTick');

        this.subscribe('onTick', callback);

        this.chartSubscriptions.set('onTick', {
            subscribe: callback,
            unsubscribe: () => null,
        });
    }

    onBetAddedUpdate(callback: BetCallback) {
        this.clearUpdate('betAdded');

        const activeBets$ = from(
            this.root.bets.bets.filter(
                bet =>
                    bet.status === 'active' &&
                    bet.market?.symbol === this.root.ui.betsPage.market?.symbol,
            ),
        );

        const subscription = concat(
            activeBets$,
            this.root.bets.betAdded$,
        ).subscribe(callback);

        this.subscriptions.set('betAdded', subscription);
    }

    onBetResolvedUpdate(callback: BetCallback) {
        this.clearUpdate('betResolved');
        const subscription = this.root.bets.betResolved$.subscribe(callback);
        this.subscriptions.set('betResolved', subscription);
    }

    onVolumeUpdate(callback: EmptyCallback) {
        this.clearUpdate('volume');

        const subscription = this.root.ui.betsPage.betInput.volume$
            .pipe(
                startWith(this.root.ui.betsPage.betInput.volume),
                distinctUntilChanged(
                    (prev, temp) => typeof prev === typeof temp,
                ),
            )
            .subscribe(volume => {
                if (volume !== null) callback();
                else this.clearChartUpdate('onTick');
            });

        this.subscriptions.set('volume', subscription);
    }

    onPeriodUpdate(callback: EmptyCallback) {
        this.clearUpdate('period');

        const subscription = this.root.ui.betsPage.betInput.betConfig$
            .pipe(
                startWith(this.root.ui.betsPage.betInput.betConfig),
                map(betConfig => betConfig?.betDurationSec),
            )
            .subscribe(callback);

        this.subscriptions.set('period', subscription);
    }

    onChartZoom(callback: (barSpacing: number) => void) {
        this.chart.getTimeScale().barSpacingChanged().subscribe(null, callback);
        // somehow unsubscribe causes charting library error, it can be caused by getTimeScale() which is not well documented
        // and is causing a lot of problems.
    }

    destroy() {
        this.subscriptions.forEach(subs => subs.unsubscribe());

        this.chartSubscriptions.forEach(subs => {
            subs.unsubscribe(null, subs.subscribe);
        });
        this.subscriptions.clear();
        this.chartSubscriptions.clear();
    }
}
