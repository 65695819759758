import {
    createTRPCProxyClient,
    createWSClient,
    httpBatchLink,
    wsLink,
} from '@trpc/client';
import type { AppRouter } from 'logium-v2-server/src/api/trpc-router.service';
import superjson from 'superjson';
import { RootStoreType } from '../RootStore.types';

export const tRpcApiFactory = (root: RootStoreType) => {
    const cfg = root.config.tRpc;
    let jwt: string | undefined;
    let closed = false;

    class AuthedWebsocket extends WebSocket {
        constructor(url: string, protocols?: string | string[]) {
            if (protocols == null) protocols = [];
            else if (typeof protocols === 'string') protocols = [protocols];
            if (jwt) protocols.push('AUTH-' + jwt);
            super(url, protocols);
        }
    }

    const client = createWSClient({
        url: cfg.wsUrl,
        WebSocket: AuthedWebsocket as typeof WebSocket,
        onOpen: () => {
            // force user to refresh page to avoid sync issues
            if (closed) {
                root.useCases.outOfService();
            }
        },
        onClose: e => {
            if (e?.code !== 1005) closed = true; // 1005 is normal close code when switching accounts
        },
    });

    const reset = async (newJwt: string) => {
        if (jwt !== newJwt) {
            jwt = newJwt;
            client.getConnection().dispatchEvent(new Event('error'));
        }
    };

    const httpHeaders = () => {
        let headers = {};
        if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` };
        return headers;
    };

    return {
        client: createTRPCProxyClient<AppRouter>({
            links: [
                wsLink({
                    client,
                }),
                httpBatchLink({
                    url: cfg.httpUrl,
                    headers: httpHeaders,
                }),
            ],
            transformer: superjson,
        }),
        reset,
    };
};
