import { MarketType } from '@/markets/MarketsStore.types';
import { RootStoreType } from '@/RootStoreTypes';
import { resolutionToBinance } from '@/tools/chart';
import { filter, map, Observable, retry, share, switchMap } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { BinanceRestApi } from './BinanceRestApi';
import {
    BinanceStoreType,
    KlineMessage,
    TradeMessage,
} from './BinanceStore.types';
import { Kline } from './Kline';
import { Trade } from './Trade';

type RawTradeMessage = {
    data: TradeMessage;
};

export class BinanceAggregateStore implements BinanceStoreType {
    private webSocket$: Observable<RawTradeMessage>;
    private klineWebSocket$: Map<
        string,
        WebSocketSubject<{
            data: KlineMessage;
        }>
    > = new Map();
    private api: BinanceRestApi;

    constructor(private root: RootStoreType) {
        this.api = new BinanceRestApi(
            this.root.config.defaultValues.binance.apiUrl,
        );

        this.webSocket$ = this.root.markets.markets$.pipe(
            switchMap(markets => {
                return this.getWebSocket$([...markets.values()]);
            }),
            share(),
        );
    }

    private getWebSocket$(markets: MarketType[]) {
        return webSocket<RawTradeMessage>(
            this.getTradeWsUrl(markets.map(market => market.symbol)),
        );
    }
    private getWsUrl(names: string[]) {
        return (
            this.root.config.defaultValues.binance.websocketBaseUrl +
            '/stream?streams=' +
            names.join('/')
        );
    }

    private trade(symbol: string) {
        return symbol.toLowerCase() + '@trade';
    }

    private kline(symbol: string, resolution: number) {
        const k = resolutionToBinance(resolution);
        return symbol.toLowerCase() + '@kline_' + k;
    }

    private getTradeWsUrl(symbols: string[]) {
        const names = symbols.map(x => this.trade(x));
        return this.getWsUrl(names);
    }

    private getKlineWebSocket$(symbol: string, resolution: number) {
        const key = `${symbol}_${resolution}`;

        let ws$ = this.klineWebSocket$.get(key);

        if (ws$ === undefined) {
            ws$ = webSocket<{ data: KlineMessage }>(
                this.getKlineWsUrl(symbol, resolution),
            );
            this.klineWebSocket$.set(key, ws$);
        }

        return ws$;
    }

    private getKlineWsUrl(symbol: string, sec: number) {
        return this.getWsUrl([this.kline(symbol, sec)]);
    }

    async getBinanceServerTime() {
        return await this.api.getTime();
    }

    trade$(symbol: string) {
        return this.webSocket$
            .pipe(
                retry({
                    delay: this.root.config.defaultValues.binance
                        .reconnectDelay,
                }),
            )
            .pipe(
                filter(this.getMessageFilter(symbol)),
                map(m => new Trade(m.data)),
            );
    }

    kline$(symbol: string, period: number) {
        const ws$ = this.getKlineWebSocket$(symbol, period);

        return ws$
            .pipe(
                retry({
                    delay: this.root.config.defaultValues.binance
                        .reconnectDelay,
                }),
            )
            .pipe(map(({ data }) => new Kline(data)));
    }

    async getPrice(symbol: string) {
        return this.api.getPrice(symbol);
    }

    private getMessageFilter(symbol: string) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (message: any) =>
            message.data.s.toLowerCase() === symbol.toLowerCase();
    }
}
