import { Switch } from '@/components/Switch/Switch';
import { VirtualInfoMobile } from '@/components/VirtualInfoMobile/VirtualInfoMobile';
import { useStore } from '@/view/storeContext';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const VirtualAccount = observer(() => {
    const store = useStore();
    const { t } = useTranslation();

    const handleSwitchClick = useCallback(() => {
        store?.useCases.joinNewsletter();
    }, [store?.useCases]);

    return (
        <div className="bg-greyscale-background p-4 rounded-lg mt-4">
            <div className="flex justify-between text-mobile-buttons-medium-light pb-4 text-greyscale-primary border-b border-greyscale-line">
                <span>{t('virtualModeSwitch.label')}</span>
                <Switch
                    turnOn={true}
                    blackAndWhite={true}
                    onClick={handleSwitchClick}
                />
            </div>
            <VirtualInfoMobile className="pt-4" />
        </div>
    );
});
