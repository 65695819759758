import { joinClassnames } from '@/tools/css';
import { ButtonVariant } from './Button.types';

const WHITE = 'text-consts-white';

const TRANSITION = ['ease-in-out', 'duration-300'];

export const buttonStyles = {
    short: joinClassnames(['h-5', 'bg-greyscale-input', 'rounded-md']),
    primary: joinClassnames([
        'rounded-button',
        'bg-primary-default',
        'text-sm',
        'py-1.5',
        'px-2.5',
        'hover:bg-primary-medium',
        'disabled:bg-primary-disabled',
        WHITE,
        ...TRANSITION,
    ]),
    secondary: joinClassnames([
        'bg-greyscale-input',
        'text-greyscale-placeholder',
        'text-xs',
        'font-medium',
        'py-[5px]',
        'px-3',
        'rounded-md',
        'flex',
        'items-center',
    ]),
    address: joinClassnames([
        'border',
        'border-greyscale-line',
        'py-1.5',
        'px-2.5',
        'rounded-button',
        'text-greyscale-primary',
        'text-sm',
        'font-medium',
        'flex',
        'items-center',
    ]),
    trade: (isMobile: boolean) =>
        joinClassnames([
            'flex',
            'justify-center',
            'w-full',
            'rounded-button',
            'bg-primary-default',
            'text-sm',
            'py-1.5',
            'px-2.5',
            !isMobile ? 'mb-2' : '',
            'py-2.5',
            WHITE,
            ...TRANSITION,
        ]),
    grey: joinClassnames([
        'flex',
        'justify-center',
        'items-center',
        'rounded-button',
        'bg-greyscale-background',
        'border',
        'border-greyscale-line',
        'text-sm',
        'py-1.5',
        'px-2.5',
        WHITE,
        ...TRANSITION,
    ]),
    arrows: joinClassnames(['mr-2']),
    primaryMobile: joinClassnames([
        'rounded-mobileButton',
        'bg-primary-default',
        'py-3.75',
        'px-3',
        'hover:bg-primary-medium',
        'disabled:bg-primary-disabled',
        'text-base',
        'leading-4.5',
        'font-semibold',
        'w-full',
        WHITE,
        ...TRANSITION,
    ]),
    secondaryMobile: joinClassnames([
        'rounded-mobileButton',
        'py-3.75',
        'px-3',
        'bg-[#323A57]',
        'bg-opacity-0',
        'hover:bg-opacity-30',
        'text-base',
        'leading-4.5',
        'font-semibold',
        'w-full',
        'border',
        'border-greyscale-line',
        WHITE,
        ...TRANSITION,
    ]),
};

export const loadingStyles = {
    loadingWrapper: joinClassnames([
        'w-full',
        'h-full',
        'flex',
        'items-center',
        'justify-center',
    ]),
};

export const getButtonClassname = (
    variant: ButtonVariant,
    isMobile: boolean,
) => {
    switch (variant) {
        case 'primary':
            return buttonStyles.primary;
        case 'secondary':
            return buttonStyles.secondary;
        case 'short':
            return buttonStyles.short;
        case 'address':
            return buttonStyles.address;
        case 'trade':
            return buttonStyles.trade(isMobile);
        case 'grey':
            return buttonStyles.grey;
        case 'primaryMobile':
            return buttonStyles.primaryMobile;
        case 'secondaryMobile':
            return buttonStyles.secondaryMobile;
        default:
            return buttonStyles.primary;
    }
};
