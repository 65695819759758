import { useClassName } from '@/tools/css';
import React from 'react';
import { MobileOverlayProps } from './MobileOverlay.types';

export const MobileOverlay = ({ active }: MobileOverlayProps) => {
    return (
        <div
            className={useClassName(
                'absolute left-0 bottom-full w-full h-screen bg-modalOverlay transition-all z-0',
                active ? 'opacity-100 visible' : 'opacity-0 invisible',
            )}
        />
    );
};
