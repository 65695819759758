import { RootStoreType } from '@/RootStoreTypes';
import { ModalsStoreType } from '@/modals/ModalsStore.types';
import { ToastsStoreType } from '../toasts/ToastsStore.types';
import { UIStoreType } from './UIStore.types';
import { BetsPageStoreType } from './betsPageStore/BetsPageStore.types';
import { NavStoreType } from './nav/NavStore.types';

export class UIStore implements UIStoreType {
    nav: NavStoreType;
    betsPage: BetsPageStoreType;
    modals: ModalsStoreType;
    toasts: ToastsStoreType;

    constructor(
        root: RootStoreType,
        navStoreFactory: (root: RootStoreType) => NavStoreType,
        betsPageFactory: (root: RootStoreType) => BetsPageStoreType,
        modalsStoreFactory: (root: RootStoreType) => ModalsStoreType,
        toastsStoreFactory: (root: RootStoreType) => ToastsStoreType,
    ) {
        this.nav = navStoreFactory(root);
        this.betsPage = betsPageFactory(root);
        this.modals = modalsStoreFactory(root);
        this.toasts = toastsStoreFactory(root);
    }
}
