import { joinClassnames } from '@/tools/css';

export const tableStyles = {
    table: joinClassnames([
        'h-full',
        'bg-greyscale-background',
        'flex',
        'flex-col',
        'rounded-lg',
        'transition-all',
    ]),
    betBody: joinClassnames([
        'px-4',
        'py-3',
        'overflow-auto',
        'relative',
        'h-full',
    ]),
};
