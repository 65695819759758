import ErrorIcon from '@/assets/icons/errInfo.svg';
import SuccessIcon from '@/assets/icons/successInfo.svg';
import routes from '@/config/routes';
import { BetConfigType, MarketType } from '@/markets/MarketsStore.types';
import { StatusModalType, WaitModalType } from '@/modals/ModalsStore.types';
import { AppMode, RootStoreType } from '@/RootStoreTypes';
import { toBN } from '@/tools/big-number';
import { AssetType } from '@/user/User.types';
import { ConnectorName } from '@/wallet/WalletStore.types';
import { UseCasesStoreTypes } from './UseCasesStore.types';

export class UseCasesStore implements UseCasesStoreTypes {
    constructor(private root: RootStoreType) {}

    async connectWallet(walletName: ConnectorName) {
        const userConfirmation = await this.root.ui.modals.confirmWallet(
            walletName,
        ).done;
        if (!userConfirmation) {
            // not confirmed
            return;
        }

        const statusWallet =
            this.root.ui.modals.walletConnectionStatus(walletName);

        try {
            await this.root.wallet.connect(walletName);
            statusWallet.setStatus('success');
        } catch (e) {
            statusWallet.setStatus('failed');
        }

        await statusWallet.done;
    }
    async startWalletConnection() {
        const walletName = await this.root.ui.modals.selectWallet().done;

        if (walletName === null) {
            // canceled by user
            return;
        }

        return this.connectWallet(walletName);
    }

    getAsset() {
        const asset = this.root.serverInfo.asset;
        if (asset) {
            return asset;
        }
        throw new Error('Asset not found');
    }

    async startDeposit(volume?: bigint) {
        const asset = this.getAsset();
        const enableUSDCSuccess = await this.checkAndEnableUSDC();

        if (!enableUSDCSuccess) {
            return;
        }

        const amount = await this.root.ui.modals.deposit(asset, volume).done;

        if (amount > 0) {
            const wait = this.root.ui.modals.wait({
                modalHeading: 'depositModal.modalHeading',
                heading: 'depositModal.waitHeading',
                text: 'depositModal.waitText',
            });
            try {
                await this.root.user.deposit(amount);
                wait.resolve();
                this.depositSuccessStatusModal(amount, asset);
            } catch (e) {
                wait.resolve();
                this.depositErrorStatusModal();
            }
        }
    }

    private depositSuccessStatusModal(amount: bigint, asset: AssetType) {
        this.root.ui.modals.status({
            modalHeading: 'depositModal.modalHeading',
            icon: SuccessIcon,
            heading: 'depositModal.success',
            text: 'depositModal.successText',
            textParams: { amount: this.valueToString(amount, asset) },
            primaryBtn: {
                text: 'depositModal.goToTrade',
                callback: (modal: StatusModalType) => {
                    modal.resolve();
                },
            },
        });
    }

    private depositErrorStatusModal() {
        this.root.ui.modals.status({
            modalHeading: 'depositModal.modalHeading',
            icon: ErrorIcon,
            heading: 'depositModal.error',
            text: 'depositModal.errorText',
            primaryBtn: {
                text: 'depositModal.tryAgain',
                callback: (modal: StatusModalType) => {
                    modal.resolve();
                    this.startDeposit();
                },
            },
        });
    }

    valueToString(value: bigint, asset: AssetType) {
        return toBN(value).div(toBN(10).pow(asset.decimals)).toString();
    }

    async startWithdraw(volume?: bigint) {
        const asset = this.getAsset();

        const enableUSDCSuccess = await this.checkAndEnableUSDC();

        if (!enableUSDCSuccess) {
            return;
        }

        const amount = await this.root.ui.modals.withdraw(asset, volume).done;

        if (amount > 0) {
            const wait = this.root.ui.modals.wait({
                modalHeading: 'withdrawModal.header',
                heading: 'withdrawModal.waitHeading',
                text: 'withdrawModal.waitText',
            });
            try {
                await this.root.user.withdraw(amount, asset.id);
                wait.resolve();
                this.withdrawSuccessStatusModal(amount, asset);
            } catch (e) {
                wait.resolve();
                this.withdrawErrorStatusModal();
            }
        }
    }

    fetchUserInfoErrorModal() {
        this.root.ui.modals.status({
            modalHeading: 'fetchUserInfoError.header',
            icon: ErrorIcon,
            heading: 'fetchUserInfoError.error',
            text: 'fetchUserInfoError.text',
            primaryBtn: {
                text: 'fetchUserInfoError.button',
                callback: (modal: StatusModalType) => {
                    modal.resolve();
                },
            },
        });
    }

    signErrorStatusModal() {
        this.root.wallet.disconnect();

        const activeModal = this.root.ui.modals.activeModal;

        if (activeModal?.type === 'walletConnectionStatus') {
            activeModal.resolve();
        }

        this.root.ui.modals.status({
            modalHeading: 'walletSignError.header',
            icon: ErrorIcon,
            heading: 'walletSignError.error',
            text: 'walletSignError.text',
            primaryBtn: {
                text: 'walletSignError.button',
                callback: (modal: StatusModalType) => {
                    modal.resolve();
                    this.startWalletConnection();
                },
            },
        });
    }

    private withdrawSuccessStatusModal(amount: bigint, asset: AssetType) {
        this.root.ui.modals.status({
            modalHeading: 'withdrawModal.header',
            icon: SuccessIcon,
            heading: 'withdrawModal.success',
            text: 'withdrawModal.successText',
            textParams: { amount: this.valueToString(amount, asset) },
            primaryBtn: {
                text: 'withdrawModal.backToTrade',
                callback: (modal: StatusModalType) => {
                    modal.resolve();
                },
            },
        });
    }

    private withdrawErrorStatusModal() {
        this.root.ui.modals.status({
            modalHeading: 'withdrawModal.header',
            icon: ErrorIcon,
            heading: 'withdrawModal.error',
            text: 'withdrawModal.errorText',
            primaryBtn: {
                text: 'withdrawModal.tryAgain',
                callback: (modal: StatusModalType) => {
                    modal.resolve();
                    this.startWithdraw();
                },
            },
        });
    }

    private async enableUSDCErrorModal(): Promise<boolean> {
        let tryAgain = false;

        await this.root.ui.modals.status({
            modalHeading: 'allowanceModalError.modalHeading',
            icon: ErrorIcon,
            heading: 'allowanceModalError.heading',
            text: 'allowanceModalError.error',
            primaryBtn: {
                text: 'allowanceModalError.button',
                callback: (modal: StatusModalType) => {
                    modal.resolve();
                    tryAgain = true;
                },
            },
        }).done;

        return tryAgain;
    }

    async startTopUp(volume?: bigint) {
        const asset = this.getAsset();
        const amount = await this.root.ui.modals.topUp(asset, volume).done;

        if (amount > 0) {
            const wait = this.root.ui.modals.wait(null);
            this.root.user.deposit(amount);
            wait.resolve();
        }
    }

    private async checkAndEnableUSDC(): Promise<boolean> {
        let wait: WaitModalType | undefined = undefined;
        let success = false;

        try {
            const allowance =
                await this.root.contracts.ierc20Usdc.getLogiumCoreAllowance();

            if (allowance.eq(0)) {
                if (await this.root.ui.modals.approveLogiumCore().done) {
                    wait = this.root.ui.modals.wait(null);
                    await this.root.contracts.ierc20Usdc.approveLogiumCore();
                    wait.resolve();
                    success = true;
                }
            } else {
                success = true;
            }
        } catch (e) {
            wait?.resolve();

            const tryAgain = await this.enableUSDCErrorModal();

            if (tryAgain) {
                await this.checkAndEnableUSDC();
            }
        }

        return success;
    }

    async takeBet(
        market: MarketType,
        volume: bigint,
        betConfiguration: BetConfigType,
        isUp: boolean,
    ): Promise<void> {
        const isReady = await this.root.serverTime.isReady;
        const direction = isUp ? 'up' : 'down';

        if (isReady) {
            try {
                await this.root.bets.add({
                    volume,
                    market,
                    direction,
                    createAt: new Date(this.root.serverTime.getNow()),
                    betConfiguration,
                });
            } catch {
                if (this.root.mode !== 'local') {
                    this.root.ui.toasts.serverError();
                }
            }
        }
    }

    async showAccountDetailsModal() {
        const actionType = await this.root.ui.modals.accountDetailsMobile()
            .done;

        if (actionType === 'reset') {
            this.resetVirtualAccount();
        }
        if (actionType === 'topUp') {
            this.startTopUp();
        }
    }

    joinNewsletter() {
        this.root.newsletter.openModal();
    }

    async outOfService() {
        await this.root.ui.modals.outOfService().done;

        location.reload();
    }

    async resetVirtualAccount() {
        const done = await this.root.ui.modals.resetVirtualAccount().done;

        if (done) {
            this.root.user.resetVolume();
            this.root.bets.clear();
        }
    }

    showMobileVersionModal() {
        const isMobile =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent,
            );

        if (isMobile) this.root.ui.modals.mobileVersion();
    }

    async goDefaultMarket() {
        const markets = this.root.markets;
        const router = this.root.router;

        try {
            await markets.ready;

            if (markets.markets.size) {
                const symbol = markets.markets.values().next().value.symbol;
                router.goTo(routes.bets, { symbol });
            } else {
                // UC1.ERR1 Bet configurations is not available or empty
                this.betConfigErrorModal();
            }
        } catch (e) {
            // UC1.ERR1 Bet configurations is not available or empty
            this.betConfigErrorModal();
        }
    }
    async goMarketBySymbol(symbol?: string) {
        const markets = this.root.markets;
        const betsPage = this.root.ui.betsPage;
        const router = this.root.router;

        if (!symbol) {
            await this.goDefaultMarket();
        } else {
            try {
                await markets.ready;

                const market = markets.markets.get(symbol);

                if (market) {
                    betsPage.setMarket(market);
                    router.goTo(routes.bets, { symbol });
                } else {
                    router.goTo(routes.notFound);
                }
            } catch (e) {
                // UC1.ERR1 Bet configurations is not available or empty
                this.betConfigErrorModal();
            }
        }
    }

    goToNotFound() {
        const router = this.root.router;
        router.goTo(routes.notFound);
    }

    goToBets() {
        const router = this.root.router;
        router.goTo(routes.bets);
    }

    onlyMobilePage() {
        const portfolioPath = routes.portfolio.path;
        const walletPath = routes.wallet.path;

        const mobilePaths = [portfolioPath, walletPath];

        this.root.device.isMobile$.subscribe({
            next: isMobile => {
                const currentPath = this.root.router.currentPath;
                if (isMobile === false && mobilePaths.includes(currentPath))
                    this.goToBets();
            },
        });
    }

    async initialize() {
        this.root.analytics.initialize();
        this.root.useCases.onlyMobilePage();
    }

    async switchAppMode(): Promise<void> {
        if (import.meta.env.VITE_BACKEND_FEATURE === 'yes') {
            return this.setAppMode(
                this.root.mode === 'local' ? 'server' : 'local',
            );
        } else {
            return this.joinNewsletter();
        }
    }

    private async setAppMode(mode: AppMode) {
        await this.root.setMode(mode);
        await this.goDefaultMarket();
    }

    private betConfigErrorModal() {
        this.root.ui.modals.status({
            modalHeading: 'betConfigErrorModal.modalHeading',
            icon: ErrorIcon,
            heading: 'betConfigErrorModal.heading',
            text: 'betConfigErrorModal.error',
            primaryBtn: {
                text: 'betConfigErrorModal.reload',
                callback: (modal: StatusModalType) => {
                    modal.resolve();
                    location.reload();
                },
            },
        });
    }
}
