export const mayBeThen = <T, U>(t: T | undefined | null, h: (v: T) => U) => {
    if (t === undefined) {
        return undefined;
    }

    if (t === null) {
        return null;
    }
    return h(t);
};
