import {
    EntityId,
    IChartWidgetApi,
    ILineDataSourceApi,
    PricedPoint,
    ShapePoint,
} from '@charting-library/';
import { LineType } from './Line.types';

export class Line implements LineType {
    shape: ILineDataSourceApi | null = null;
    points: PricedPoint[] | null = null;

    constructor(private chart: IChartWidgetApi, private id: EntityId) {
        this.initialize(id);
    }

    setPoints(points: ShapePoint[]) {
        if (this.shape) this.shape.setPoints(points);
    }

    initialize(id: EntityId) {
        try {
            this.shape = this.chart.getShapeById(id);
            this.points = this.shape.getPoints();
        } catch (err) {
            this.shape = null;
            this.points = null;
        }
    }
}
