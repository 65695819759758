import {
    EntityId,
    IChartWidgetApi,
    ILineDataSourceApi,
    PricedPoint,
} from '@charting-library/';
import { DotType } from './Dot.types';

export class Dot implements DotType {
    shape: ILineDataSourceApi | null = null;
    points: PricedPoint[] | null = null;

    constructor(private chart: IChartWidgetApi, private id: EntityId) {
        this.initialize(id);
    }

    get point() {
        if (this.points) return this.points[0];
        return null;
    }

    initialize(id: EntityId) {
        try {
            this.shape = this.chart.getShapeById(id);
            this.points = this.shape.getPoints();
        } catch (err) {
            this.shape = null;
            this.points = null;
        }
    }
}
