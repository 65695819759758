import { Payout as PayoutComponent } from '@/components/Sidebar/Payout/Payout';
import { useStore } from '@/view/storeContext';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

export const Payout = observer(() => {
    const store = useStore();
    const rate = store?.ui.betsPage.market?.rateSymbol ?? 0;
    const time = store?.ui.betsPage.betInput.period ?? 0;
    const volume = store?.ui.betsPage.betInput.volume ?? 0n;

    return (
        <PayoutComponent
            {...{
                rate,
                time,
                volume,
                decimals: store?.ui.betsPage.betInput.asset?.decimals,
            }}
        />
    );
});
