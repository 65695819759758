import { ModalContainer } from '@/components/Modal/ModalContainer';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../storeContext';
import LoadingIcon from '@/assets/icons/loading.svg';

export const WaitModal: React.FC = observer(() => {
    const store = useStore();
    const { t } = useTranslation();

    const modal = store?.ui.modals.activeModal;

    if (modal && modal.type === 'wait') {
        const modalHeading =
            modal.props?.modalHeading ?? 'waitModal.modalHeading';
        const text = modal.props?.text ?? 'waitModal.text';
        const heading = modal.props?.heading ?? 'waitModal.heading';

        return (
            <ModalContainer header={t(modalHeading).toString()}>
                <div className="py-2 flex flex-1 items-center">
                    <div className="flex flex-col items-center px-7 w-full">
                        <div className="bg-greyscale-input w-12 h-12 flex items-center justify-center rounded-lg">
                            <img
                                src={LoadingIcon}
                                alt="status"
                                className="animate-spin"
                            />
                        </div>
                        <h3 className="text-greyscale-primary font-semibold mt-4 mb-2 text-xs">
                            {t(heading).toString()}
                        </h3>
                        <p className="text-center text-greyscale-placeholder text-xs font-medium">
                            {t(text).toString()}
                        </p>
                    </div>
                </div>
            </ModalContainer>
        );
    } else {
        return null;
    }
});
