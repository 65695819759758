import { Kline } from '@/binance/Kline';
import { Trade } from '@/binance/Trade';
import { MarketType } from '@/markets/MarketsStore.types';
import { RootStoreType } from '@/RootStoreTypes';
import {
    chartResolutionToBinance,
    getResolutionInSeconds,
} from '@/tools/chart';
import {
    Bar,
    ErrorCallback,
    HistoryCallback,
    IDatafeedChartApi,
    LibrarySymbolInfo,
    OnReadyCallback,
    PeriodParams,
    ResolutionString,
    ResolveCallback,
    SearchSymbolResultItem,
    SearchSymbolsCallback,
    ServerTimeCallback,
    SubscribeBarsCallback,
} from '@charting-library/';
import leven from 'leven';
import { map, merge, Subscription } from 'rxjs';
import { BinanceRestApi } from '../../../binance/BinanceRestApi';

type LevenSymbol = { formattedSymbol: string; symbol: string; leven: number };
export class DatafeedStub implements IDatafeedChartApi {
    private static BinanceItemsLimit = 500;
    private static configurationData = {
        supports_marks: false,
        supports_timescale_marks: false,
        supports_time: true,
        supported_resolutions: [
            '1S',
            '1',
            '3',
            '5',
            '15',
            '30',
            '60',
            '120',
            '240',
            '1D',
            '3D',
            '1W',
            '1M',
        ] as ResolutionString[],
    };
    private subscriptions: Record<string, Subscription> = {};

    api: BinanceRestApi;

    constructor(private root: RootStoreType) {
        this.api = new BinanceRestApi(
            this.root.config.defaultValues.binance.apiUrl,
        );
    }

    onReady(callback: OnReadyCallback) {
        // onReady` should return result asynchronously. Use `setTimeout` with 0 interval to execute the callback function.
        setTimeout(() => {
            callback(DatafeedStub.configurationData);
        }, 0);
    }
    searchSymbols(
        _userInput: string,
        _exchange: string,
        _symbolType: string,
        _onResult: SearchSymbolsCallback,
    ) {
        // reset flag everytime modal is displayed on the screen
        this.root.ui.betsPage.favoriteMarkets.setAddToFavoriteFlag(false);

        const indexedByLeven: LevenSymbol[] = [];

        const lowerVaseUserInput = _userInput.toLowerCase();
        for (const {
            formattedSymbol,
            symbol,
        } of this.root.markets.markets.values()) {
            indexedByLeven.push({
                formattedSymbol,
                symbol,
                leven: leven(lowerVaseUserInput, formattedSymbol.toLowerCase()),
            });
        }

        indexedByLeven.sort((a, b) => a.leven - b.leven);

        _onResult(indexedByLeven.map(this.market2SearchSymbolResultItem));
    }

    resolveSymbol(ticker: string, onResolve: ResolveCallback) {
        const market = this.getMarket(ticker);

        if (market) {
            // 'resolveSymbol` should return result asynchronously. Use `setTimeout` with 0 interval to execute the callback function
            setTimeout(() => {
                onResolve(this.market2LibrarySymbolInfo(market));
            }, 0);
        }
    }

    async getServerTime(callback: ServerTimeCallback) {
        const isReady = await this.root.serverTime.isReady;

        if (isReady) callback(this.root.serverTime.chartServerTime);
    }

    async getBars(
        symbolInfo: LibrarySymbolInfo,
        resolution: ResolutionString,
        periodParams: PeriodParams,
        onResult: HistoryCallback,
        onError: ErrorCallback,
    ) {
        const resolutionS = getResolutionInSeconds(resolution);

        const { to, countBack } = periodParams;

        const binanceResolution = chartResolutionToBinance(resolution);

        const chunksPeriods = this.getChunksPeriods(to, resolutionS, countBack);

        let ticker: string;

        if (symbolInfo.ticker) ticker = symbolInfo.ticker;
        else {
            onResult([], {
                noData: true,
            });
        }

        try {
            const chunks = await Promise.all(
                chunksPeriods.map(([from, to]) =>
                    this.api
                        .getKlines(
                            ticker,
                            binanceResolution,
                            from * 1000,
                            to * 1000,
                        )
                        .catch(() => []),
                ),
            );

            const data = chunks.flat(1);

            if (data.length > 0) {
                onResult(data);
            } else
                onResult([], {
                    noData: true,
                });
        } catch {
            onError('Klines data error');
        }
    }

    // https://github.com/student-coin/charting_library/wiki/JS-Api#note-about-periodparams
    private getChunksPeriods(
        to: number,
        resolution: number,
        countBack: number,
    ): [number, number][] {
        const chunks: [number, number][] = [];

        const from = to - countBack * resolution;

        while (from < to) {
            const next = Math.max(
                from,
                to - resolution * DatafeedStub.BinanceItemsLimit,
            );
            chunks.unshift([next, to]);
            to = next;
        }

        return chunks;
    }

    private lastUpdate?: number;

    subscribeBars(
        symbolInfo: LibrarySymbolInfo,
        resolution: ResolutionString,
        onTick: SubscribeBarsCallback,
        listenerGuid: string,
    ) {
        this.lastUpdate = undefined;

        if (this.subscriptions[listenerGuid]) {
            throw this.root.errors.err('ID_ALREADY_USED', listenerGuid);
        }

        const resolutionS = getResolutionInSeconds(resolution);

        if (symbolInfo.ticker) {
            const trade$ = this.root.binance
                .trade$(symbolInfo.ticker)
                .pipe(map(trade => this.trade2Bar(trade)));

            const kline$ = this.root.binance
                .kline$(symbolInfo.ticker, resolutionS)
                .pipe(map(r => this.kline2Bar(r)));

            const bar$ = merge(trade$, kline$);

            this.subscriptions[listenerGuid] = bar$.subscribe(bar => {
                if (
                    this.lastUpdate === undefined ||
                    bar.time >= this.lastUpdate
                ) {
                    this.lastUpdate = bar.time;
                    onTick(bar);
                }
            });
        }
    }

    private kline2Bar(kline: Kline): Bar {
        return {
            close: parseFloat(kline.close),
            high: parseFloat(kline.high),
            low: parseFloat(kline.low),
            open: parseFloat(kline.open),
            volume: parseFloat(kline.volume),
            time: kline.startTimeMs,
        };
    }

    private trade2Bar(trade: Trade): Bar {
        const price = parseFloat(trade.price);
        const quantity = parseFloat(trade.quantity);
        return {
            close: price,
            high: price,
            low: price,
            open: price,
            volume: quantity,
            time: trade.transactionTime,
        };
    }
    unsubscribeBars(listenerGuid: string) {
        if (this.subscriptions[listenerGuid]) {
            this.subscriptions[listenerGuid].unsubscribe();
            delete this.subscriptions[listenerGuid];
        } else {
            throw this.root.errors.err('NOT_FOUND_UNDER_ID', listenerGuid);
        }
    }

    private getMarket(symbolName: string) {
        return this.root.markets.markets.get(symbolName);
    }

    private market2LibrarySymbolInfo(market: MarketType): LibrarySymbolInfo {
        return {
            ticker: market.symbol,
            full_name: market.formattedSymbol,
            name: market.formattedSymbol,
            description: '',
            type: '?t',
            session: '24x7',
            timezone: 'Etc/UTC',
            exchange: 'Binance',
            minmov: 1,
            pricescale: 1000000,
            has_intraday: true,
            has_seconds: true,
            seconds_multipliers: ['1'],
            intraday_multipliers: ['1', '5', '15', '60', '360'],
            has_no_volume: true,
            has_weekly_and_monthly: true,
            supported_resolutions: [
                '1S',
                '1',
                '5',
                '15',
                '60',
                '360',
                '1D',
                '1W',
                '1M',
            ] as ResolutionString[],
            volume_precision: 2,
            data_status: 'streaming',
            listed_exchange: '',
            format: 'price',
            has_empty_bars: true,
        };
    }

    private market2SearchSymbolResultItem(
        market: LevenSymbol,
    ): SearchSymbolResultItem {
        return {
            symbol: market.formattedSymbol,
            full_name: market.formattedSymbol,
            description: ' ',
            exchange: ' ',
            ticker: market.symbol,
            type: 'crypto',
        };
    }
}
