import React from 'react';
import readme from '@/assets/icons/readme.svg';
import { useTranslation } from 'react-i18next';
export const WalletModalFooter = () => {
    const { t } = useTranslation();
    return (
        <a className="text-greyscale-placeholder text-sm flex justify-center gap-2">
            <img src={readme} alt={'readme'} />
            {t('walletModalFooter.learnMore')}
        </a>
    );
};
