import { mulFloat } from '@/tools/big-math';
import { formatVolume } from '@/tools/format';
import { useStore } from '@/view/storeContext';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const BetInputInfo = observer(() => {
    const store = useStore();
    const { t } = useTranslation();

    // In the data model rate (leverage) depends on the direction of the bet.
    // In the ui we may know the rate all the time before up or down click.
    // Thats wy we are using the rate from the market.
    const rate = store?.ui.betsPage.market?.rateSymbol ?? 0;

    const betInput = store?.ui.betsPage.betInput;
    const volume = betInput?.volume ?? 0n;
    const availableCollateral = betInput?.availableCollateral ?? 0;
    const payoutValue = formatVolume(
        mulFloat(volume, rate),
        betInput?.asset?.decimals,
    );
    const payoutPerc = rate * 100;

    return (
        <div className="text-mobile-inputs-medium-light text-greyscale-secondary">
            <div className="flex justify-between ">
                <span>{t('actionPanel.payout')}</span>
                <span>
                    ${payoutValue} ({payoutPerc}%)
                </span>
            </div>
            <div className="flex justify-between mt-4">
                <span>{t('actionPanel.balance')}</span>
                <span>
                    <Trans
                        i18nKey="profilePopup.balance"
                        values={{
                            balance: formatVolume(
                                availableCollateral,
                                betInput?.asset?.decimals,
                            ),
                        }}
                        components={[
                            <span key={0} className="text-greyscale-primary" />,
                        ]}
                    />
                </span>
            </div>
        </div>
    );
});
