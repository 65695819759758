import { joinClassnames } from '@/tools/css';

export const notFoundStyles = {
    container: (isMobile?: boolean) =>
        joinClassnames([
            'min-w-0',
            'grid',
            isMobile ? 'w-full' : 'grid-cols-not-found-layout',
            'text-greyscale-secondary',
            'h-screen',
            'bg-gradient-background',
        ]),
    textContainer: (isMobile?: boolean) =>
        joinClassnames([
            'flex',
            'flex-col',
            'justify-center',
            'items-start',
            isMobile ? 'p-4' : 'pl-16',
        ]),
    heading: joinClassnames(['text-[64px]', 'mb-4', 'font-bold']),
    text: joinClassnames(['text-base', 'font-medium', 'mb-6']),
    btn: joinClassnames(['py-3', 'px-5']),
    imageContainer: joinClassnames([
        'flex',
        'justify-center',
        'items-center',
        'rounded-lg',
        'overflow-hidden',
        'bg-primary-default',
        'relative',
    ]),
    lines: joinClassnames(['absolute', 'right-0', 'top-0', 'h-full']),
    textImage: joinClassnames(['absolute', 'w-2/3']),
};
