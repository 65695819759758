import { RootStoreType } from '@/RootStoreTypes';
import { BigNumberish, Overrides, providers, Signer } from 'ethers';
import { LogiumV2Core__factory } from 'logium-v2-contract/typechain-types';
import { Subscription } from 'rxjs';
import { LogiumV2CoreContractType } from './LogiumV2CoreContract.types';

export class LogiumV2CoreContract implements LogiumV2CoreContractType {
    private subscriptions: Subscription[] = [];

    constructor(private root: RootStoreType) {}

    private get isVirtualMode() {
        return this.root.config.web3.isVirtualMode;
    }

    private get overrides(): Overrides | undefined {
        if (this.isVirtualMode) {
            return {
                gasPrice: 0,
                gasLimit: 99999999999,
            };
        } else {
            return {};
        }
    }

    async deposit(volume: BigNumberish) {
        const target = this.root.wallet.address;

        if (!target) {
            throw this.root.errors.err('WALLET_NOT_CONNECTED');
        }

        const tx = await this.getContract(this.root.wallet.signer).depositTo(
            target,
            volume,
        );

        await tx.wait();
    }

    async withdraw(amount: bigint, assetId: number) {
        const signedMessage =
            await this.root.api.client.userState.withdraw.mutate({
                amount,
                assetId,
            });

        const { signedApproval, withdrawalData } = signedMessage;

        const tx = await this.getContract(this.root.wallet.signer).withdraw(
            withdrawalData,
            signedApproval,
            amount,
        );

        await tx.wait();
    }

    destroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }

    private getContract(signer?: Signer) {
        return LogiumV2Core__factory.connect(
            this.root.config.contracts.logiumCore,
            signer ? signer : this.getProvider(),
        );
    }

    private getProvider() {
        return new providers.JsonRpcProvider(
            this.root.config.web3.gateWay,
            parseInt(this.root.config.web3.chainId),
        );
    }
}
