import { RootStoreType } from '@/RootStoreTypes';
import { BetType } from '@/bets/BetsStore.types';
import { getResolutionInSeconds } from '@/tools/chart/chart';
import { formatVolume } from '@/tools/format';
import { IChartWidgetApi } from '@charting-library/';
import { StrikePoint } from '../StrikePoint/StrikePoint';
import { StrikePointType } from '../StrikePoint/StrikePoint.types';
import { StrikePriceUpdate } from '../StrikePriceUpdate/StrikePriceUpdate';
import { StrikePriceUpdateType } from '../StrikePriceUpdate/StrikePriceUpdate.types';
import { ChartSubscribeType, StrikePriceType } from './StrikePrice.types';

export class StrikePrice implements StrikePriceType {
    private strikePriceUpdate: StrikePriceUpdateType;
    private strikePoint: StrikePointType;
    private periodFrom = 0;
    private periodTo = 0;
    private timeouts: Map<string, ReturnType<typeof setTimeout>> = new Map();

    constructor(
        private root: RootStoreType,
        private chart: IChartWidgetApi,
        subscribe: ChartSubscribeType,
        unsubscribe: ChartSubscribeType,
    ) {
        this.strikePoint = new StrikePoint(this.root, this.chart);
        this.strikePriceUpdate = new StrikePriceUpdate(
            this.root,
            this.chart,
            subscribe,
            unsubscribe,
        );
    }

    get resolutionInSeconds() {
        return getResolutionInSeconds(this.chart.resolution());
    }

    get activeBets() {
        return this.root.bets.bets.filter(bet => bet.status === 'active');
    }

    private roundDateToCandlestick(time: number) {
        const rem = time % this.resolutionInSeconds;
        return time - rem;
    }

    private calcToPosition(from: number) {
        if (!this.root.ui.betsPage.betInput.period) return 0;

        const to = from + this.root.ui.betsPage.betInput.period;
        const resolution = this.resolutionInSeconds;

        if (this.root.ui.betsPage.betInput.period < resolution) return to;

        return this.roundDateToCandlestick(to);
    }

    private strikePeriodUpdate() {
        this.periodFrom = this.root.serverTime.getNow() / 1000;
        this.periodTo = this.calcToPosition(this.periodFrom);

        this.strikePoint.updateStrikePeriodPosition(
            this.periodFrom,
            this.periodTo,
        );
    }

    private betAddedUpdate(bet: BetType) {
        const created = bet.created.toISOString();
        const from = bet.created.getTime() / 1000;
        let to = this.roundDateToCandlestick(bet.deadline.getTime() / 1000);

        // we want to show only future dates, this condition make it sure
        if (to < this.root.serverTime.getNow() / 1000) {
            to = bet.deadline.getTime();
        }

        this.strikePoint.drawStrikePoint(
            created,
            from,
            to,
            bet.strikePrice.toNumber(),
            bet.direction,
            formatVolume(bet.volume, bet.asset?.decimals),
        );
    }

    private betResolvedUpdate(bet: BetType) {
        const created = bet.created.toISOString();
        this.strikePoint.updateLabelProperties(
            created,
            bet.status,
            formatVolume(bet.volume, bet.asset?.decimals),
        );
        this.strikePoint.clearStrikePointDot(created);
        const timeout = setTimeout(() => {
            this.strikePoint.clearStrikePointLabel(created);
            this.strikePoint.deleteStrikePoint(created);
            this.timeouts.delete(created);
        }, this.root.config.defaultValues.strikePoint.defaultDelay);

        this.timeouts.set(created, timeout);
    }

    private createPeriodPoint() {
        this.periodFrom = this.root.serverTime.getNow() / 1000;
        this.periodTo = this.calcToPosition(this.periodFrom);

        this.strikePoint.drawStrikePeriod(this.periodFrom, this.periodTo);
    }

    private addStrikePoints() {
        const bets = this.root.bets.bets.filter(
            bet =>
                bet.status === 'active' &&
                bet.market?.symbol === this.chart.symbol(),
        );

        if (bets && bets.length) {
            this.strikePoint.clearAllStrikePoints();
            bets.forEach(bet => {
                this.betAddedUpdate(bet);
            });
        }
    }

    drawStrikePrice() {
        this.strikePriceUpdate.onVolumeUpdate(() => {
            this.strikePriceUpdate.onBetAddedUpdate(bet => {
                this.betAddedUpdate(bet);
            });

            this.strikePriceUpdate.onBetResolvedUpdate(
                this.betResolvedUpdate.bind(this),
            );
            this.createPeriodPoint();

            this.strikePriceUpdate.onChartSymbolChangeAndDataLoaded(() => {
                setTimeout(() => {
                    this.createPeriodPoint();
                }, 0);

                setTimeout(() => {
                    this.addStrikePoints();
                }, 0);
            });

            this.strikePriceUpdate.onChartPeriodChangeAndDataLoaded(() => {
                this.strikePoint.clearStrikePeriod();

                setTimeout(() => {
                    this.createPeriodPoint();
                }, 0);

                setTimeout(() => {
                    this.addStrikePoints();
                }, 0);
            });

            this.strikePriceUpdate.onPeriodUpdate(
                this.strikePeriodUpdate.bind(this),
            );

            this.strikePriceUpdate.onNewBar(() => {
                setTimeout(() => this.strikePeriodUpdate(), 0);
            });

            this.strikePriceUpdate.onChartZoom(barSpacing => {
                this.strikePoint.updateLabelsPositions(barSpacing);
            });
        });
    }

    destroy() {
        this.strikePriceUpdate.destroy();
        this.strikePoint.destroy();
        this.timeouts.forEach(timeout => clearTimeout(timeout));
        this.timeouts.clear();
    }
}
