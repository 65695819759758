import { mulFloat } from '@/tools/big-math';
import { formatVolume } from '@/tools/format';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { payoutStyles } from './Payout.css';

export interface PayoutProps {
    time: number;
    rate: number;
    volume: bigint;
    decimals: number | undefined;
}

export const Payout: React.FC<PayoutProps> = ({
    rate,
    time,
    volume,
    decimals,
}) => {
    const { t } = useTranslation();
    return (
        <div className={payoutStyles.container}>
            <div className={payoutStyles.payout}>
                <div className={payoutStyles.top}>
                    <span className={payoutStyles.payoutText}>
                        {t('sidebar.payout')}
                    </span>
                </div>
                <div className={payoutStyles.bottom}>
                    <span className={payoutStyles.payoutValue}>
                        +${formatVolume(mulFloat(volume, rate), decimals)}
                    </span>
                    <span className={payoutStyles.payoutPercentageValue}>
                        +{rate * 100}%
                    </span>
                </div>
            </div>
            <div className={payoutStyles.time}>
                <div className={payoutStyles.top}>
                    <span className={payoutStyles.timeText}>Time</span>
                </div>
                <div className={payoutStyles.bottom}>
                    <span className={payoutStyles.timeValue}>{time}s</span>
                </div>
            </div>
        </div>
    );
};
