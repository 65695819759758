import { AnalyticsStoreType } from './AnalyticsStore.types';
import { RootStoreType } from '@/RootStoreTypes';
import { GoogleStoreType } from './google/GoogleStore.types';
import { HotjarStoreType } from './hotjar/Hotjar.types';
import { FirebaseStoreType } from './firebase/Firebase.types';

export class AnalyticsStore implements AnalyticsStoreType {
    private google: GoogleStoreType | null = null;
    private hotjar: HotjarStoreType | null = null;
    private firebase: FirebaseStoreType | null = null;

    constructor(
        root: RootStoreType,
        googleStoreFactory: (root: RootStoreType) => GoogleStoreType,
        hotjarStoreFactory: (root: RootStoreType) => HotjarStoreType,
        firebaseStoreFactory: (root: RootStoreType) => FirebaseStoreType,
    ) {
        try {
            this.google = googleStoreFactory(root);
            this.hotjar = hotjarStoreFactory(root);
            this.firebase = firebaseStoreFactory(root);
        } catch (e) {
            // It looks like ad is blocked
        }
    }

    initialize() {
        this.google?.initialize();
        this.hotjar?.initialize();
        this.firebase?.initialize();
    }

    destroy() {
        this.google?.destroy();
        this.hotjar?.destroy();
        this.firebase?.destroy();
    }
}
