import { ModalContainer } from '@/components/Modal/ModalContainer';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../storeContext';

export const CreateSignatureWallet: React.FC = observer(() => {
    const store = useStore();
    const { t } = useTranslation();

    const modal = store?.ui.modals.activeModal;

    if (modal && modal.type === 'createSignatureWallet') {
        return (
            <ModalContainer header={t('createSignatureWallet.header')}>
                <h1 className="title">{t('createSignatureWallet.title')}</h1>
                <button
                    className="button"
                    name={'modal-signatureWallet-sign'}
                    onClick={() => modal.resolve()}>
                    {t('createSignatureWallet.sign')}
                </button>
            </ModalContainer>
        );
    } else {
        return null;
    }
});
