import { observer } from 'mobx-react-lite';
import React from 'react';
import { Bet } from '../FractualBets/Table/Bet/Bet';
import { NoData } from '../FractualBets/Table/NoData/NoData';
import { TableNav } from '../FractualBets/Table/TableNav/TableNav';
import { useStore } from '../storeContext';

export const Portfolio = observer(() => {
    const store = useStore();

    if (!store) return null;

    const isMobile = store.device.isMobile;

    return (
        <div className="p-4 bg-gradient-background h-full overflow-auto">
            <div className="bg-greyscale-background rounded-lg h-full flex flex-col overflow-auto">
                <TableNav />
                <div className="relative flex-1 p-4 overflow-auto">
                    {store.ui.betsPage.betsTable.bets.map(bet => (
                        <Bet isMobile={isMobile} bet={bet} key={bet.id} />
                    ))}
                    <NoData
                        tab={store.ui.betsPage.betsTable.activeTab}
                        show={store.ui.betsPage.betsTable.bets.length === 0}
                    />
                </div>
            </div>
        </div>
    );
});
