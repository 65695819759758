import { IChartWidgetApi } from '@charting-library/';

export class CenterChart {
    private resizeObserver: ResizeObserver;

    constructor(
        private chart: IChartWidgetApi,
        private container: HTMLDivElement,
    ) {
        this.resizeObserver = new ResizeObserver(this.center.bind(this));
        this.resizeObserver.observe(this.container);
    }

    get candlestickWidth() {
        return Math.round(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.chart.getTimeScale().width() /
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.chart.getTimeScale().barSpacing(),
        );
    }

    center() {
        setTimeout(() => {
            this.chart
                .getTimeScale()
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                ._timeScale.setRightOffset(
                    Math.round(this.candlestickWidth / 2),
                );
        }, 0);
    }

    destroy() {
        this.resizeObserver.unobserve(this.container);
    }
}
