import { KlineInterval } from '@/binance/BinanceStore.types';
import { IntervalsType } from './chart.types';

const minute = 60;
const hour = 60 * minute;
const day = 24 * hour;

const intervals: IntervalsType = {
    '1S': '1s',
    '1': '1m',
    '3': '3m',
    '5': '5m',
    '15': '15m',
    '30': '30m',
    '60': '1h',
    '120': '2h',
    '240': '4h',
    '360': '6h',
    '480': '8h',
    '720': '12h',
    D: '1d',
    '1D': '1d',
    '3D': '3d',
    W: '1w',
    '1W': '1w',
    M: '1M',
    '1M': '1M',
};

const secIntervals: Record<number, KlineInterval> = {
    [1]: '1s',
    [60]: '1m',
    [60 * 3]: '3m',
    [60 * 5]: '5m',
    [60 * 15]: '15m',
    [60 * 30]: '30m',
    [60 * 60]: '1h',
    [60 * 60 * 2]: '2h',
    [60 * 60 * 4]: '4h',
    [60 * 60 * 6]: '6h',
    [60 * 60 * 8]: '8h',
    [60 * 60 * 12]: '12h',
    [60 * 60 * 24]: '1d',
    [60 * 60 * 24 * 3]: '3d',
    [60 * 60 * 24 * 7]: '1w',
    [60 * 60 * 24 * 30]: '1M',
};

export const getResolutionInSeconds = (resolution: string) => {
    const isMinute = !/[a-zA-Z]/g.test(resolution);

    const value = isMinute
        ? parseInt(resolution)
        : parseInt(resolution.slice(0, resolution.length - 1));

    const unit = isMinute ? 'MIN' : resolution[resolution.length - 1];

    switch (unit) {
        case 'S':
            return value;
        case 'MIN':
            return value * minute;
        case 'D':
            return value * day;
        case 'W':
            return value * 7 * day;
        case 'M':
            return value * 30 * day;
        default:
            return value;
    }
};

export const resolutionToBinance = (sec: number): KlineInterval => {
    const res = secIntervals[sec];
    if (res === undefined) {
        throw new Error('not supported');
    }
    return res;
};

export const chartResolutionToBinance = (
    chartResolution: string,
): KlineInterval => {
    const key = Object.keys(intervals).find(key => key === chartResolution);

    if (key) {
        return intervals[key];
    } else {
        return '1s';
    }
};
