import routes from '@/config/routes';
import { RootStoreType } from '@/RootStoreTypes';
import { TFuncKey } from 'i18next';
import { MobileNotificationStoreType } from './mobileNotification/MobileNotificationStore.types';
import { NavItemType, NavStoreType } from './NavStore.types';
import { ProductsStoreType } from './products/ProductsStore.types';
import { ProfileStoreType } from './profile/ProfileStore.types';

export class NavStore implements NavStoreType {
    profile: ProfileStoreType;
    products: ProductsStoreType;
    mobileNotification: MobileNotificationStoreType;

    constructor(
        private root: RootStoreType,
        productsStoreFactory: (root: RootStoreType) => ProductsStoreType,
        profileStoreFactory: (root: RootStoreType) => ProfileStoreType,
        mobileNotificationFactory: (
            root: RootStoreType,
        ) => MobileNotificationStoreType,
    ) {
        this.products = productsStoreFactory(this.root);
        this.profile = profileStoreFactory(this.root);
        this.mobileNotification = mobileNotificationFactory(this.root);
    }

    get navItems(): NavItemType[] {
        return this.root.device.isMobile
            ? this.mobileNavItems
            : this.pcNavItems;
    }

    private get mobileNavItems() {
        return [
            {
                id: 'trade',
                name: 'nav.trade' as TFuncKey,
                route: routes.bets,
            },
            {
                id: 'portfolio',
                name: 'nav.portfolio' as TFuncKey,
                route: routes.portfolio,
            },
            {
                id: 'wallet',
                name: 'nav.wallet' as TFuncKey,
                route: routes.wallet,
            },
        ];
    }

    private get pcNavItems() {
        return [
            {
                id: 'unknown',
                name: 'nav.trade' as TFuncKey,
            },
        ];
    }

    get showUnderlay() {
        return [this.products.isOpen, this.profile.isOpen].some(x => x);
    }
}
