import { RootStoreType } from '@/RootStoreTypes';
import { mulFloat } from '@/tools/big-math';
import { Subscription } from 'rxjs';
import { EventParams, GaWindow, GoogleStoreType } from './GoogleStore.types';

export class GoogleStore implements GoogleStoreType {
    private window;
    private trackingID;
    private trackingURL;
    private CHECKOUT_ACTION = 'checkout';
    private UX_EVENT_CATEGORY = 'UX';

    private subscriptions: Subscription[] = [];

    private script: HTMLScriptElement | null = null;

    private lastAddress: string | null = null;

    constructor(private root: RootStoreType, gaWindow: GaWindow) {
        this.trackingID = this.root.config.analytics.google.trackingId;
        this.trackingURL = this.root.config.analytics.google.url;

        this.walletSubscription();
        this.betCreatedSubscription();
        this.betResolvedSubscription();
        this.window = gaWindow;
    }

    walletSubscription(): void {
        const subscription = this.root.wallet.walletInfoChange$.subscribe({
            next: wallet => {
                if (wallet.address && wallet.address !== this.lastAddress) {
                    this.connectWallet();
                    this.lastAddress = wallet.address;
                }
            },
        });

        this.subscriptions.push(subscription);
    }

    betCreatedSubscription(): void {
        const subscription = this.root.bets.betAdded$.subscribe({
            next: bet => {
                this.takeBet(bet.direction, bet.volume);
            },
        });

        this.subscriptions.push(subscription);
    }

    betResolvedSubscription(): void {
        const subscription = this.root.bets.betResolved$.subscribe({
            next: bet => {
                this.resolveBet(bet.status, bet.volume, bet.rate);
            },
        });
        this.subscriptions.push(subscription);
    }

    initialize(): void {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `${this.trackingURL}${this.trackingID}`;
        script.async = true;
        const x = document.getElementsByTagName('script')[0];
        x?.parentNode?.insertBefore(script, x);
        this.script = script;

        this.window.dataLayer = this.window.dataLayer || [];
        this.window.gtag = function gtag() {
            // eslint-disable-next-line prefer-rest-params
            this.window.dataLayer.push(arguments);
        };
        this.window.gtag('js', new Date());
        this.window.gtag('config', this.trackingID);
    }

    event(action: string, params: EventParams): void {
        this.window.gtag('event', action, params);
    }

    connectWallet(): void {
        this.event(this.CHECKOUT_ACTION, {
            event_action: 'new_wallet_connected',
            event_category: this.UX_EVENT_CATEGORY,
            mode: this.root.mode,
        });
    }

    takeBet(direction: string, amount: bigint): void {
        const correctDirection = direction === 'up' ? 'higher' : 'lower';
        this.event(this.CHECKOUT_ACTION, {
            event_action: 'take_bet',
            event_category: this.UX_EVENT_CATEGORY,
            event_label: correctDirection,
            value: amount.toString(),
            mode: this.root.mode,
        });
    }

    resolveBet(status: string, amount: bigint, rate: number): void {
        const profit = status === 'lose' ? amount : mulFloat(amount, -rate);
        this.event(this.CHECKOUT_ACTION, {
            event_action: 'resolve_bet',
            event_category: this.UX_EVENT_CATEGORY,
            event_label: status,
            value: profit.toString(),
            mode: this.root.mode,
        });
    }

    destroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
        if (this.script) document.head.removeChild(this.script);
    }
}
