import { RootStoreType } from '@/RootStoreTypes';
import { IChartWidgetApi, PricedPoint } from '@charting-library/';
import { PointType } from '../StrikePoint/StrikePoint.types';
import { Dot } from './Dot/Dot';
import { Label } from './Label/Label';
import { LabelPositionType } from './LabelPosition.types';
import { Layouter } from './Layouter/Layouter';
import {
    LayouterType,
    PointType as LayoutPoint,
} from './Layouter/Layouter.types';
import { Line } from './Line/Line';
export class LabelPosition implements LabelPositionType {
    private candlestickWidth: number;
    private layouter: LayouterType;

    constructor(private root: RootStoreType, private chart: IChartWidgetApi) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.candlestickWidth = this.chart.getTimeScale().barSpacing(); // its exists in wiki and it works but there are not typings

        this.layouter = new Layouter();
    }

    generate(
        points: Map<string, PointType>,
        period: number,
        barSpacing?: number,
        force?: boolean,
    ) {
        if (barSpacing) this.candlestickWidth = barSpacing;

        let index = 0;
        const cloned = new Map(points);
        const converted = this.converter(cloned, period);
        const serverTime = this.root.serverTime.getNow() / 1000;
        const calculated = this.layouter.calcPositions(
            converted,
            serverTime,
            force,
        );

        cloned.forEach(point => {
            const label = new Label(this.chart, point.label);

            const line = new Line(this.chart, point.solidLine);

            const dot = new Dot(this.chart, point.dot);

            if (label.points.length > 0) {
                const newPoint = this.getNewPoint(
                    label.points,
                    calculated[index],
                );

                label.setPoints([newPoint, newPoint]);
                if (dot.point) line.setPoints([newPoint, dot.point]);
            }

            index++;
        });

        return cloned;
    }

    private converter(points: Map<string, PointType>, period: number) {
        const arr: LayoutPoint[] = [];
        points.forEach(point => {
            const label = new Label(this.chart, point.label);
            const singlePoint = label.points[0];

            const height = label.height;
            const width = (label.width / this.candlestickWidth) * period;

            const element = { ...singlePoint, width, height };
            arr.push(element);
        });
        return arr;
    }

    private getNewPoint(point: PricedPoint[], newPoint: LayoutPoint) {
        const labelPoint = point[0];
        const price = labelPoint.price;
        const newTime = newPoint.time;
        return { price, time: newTime };
    }
}
