import { RootStoreType } from '@/RootStoreTypes';
import { Link, Product, ProductsStoreType } from './ProductsStore.types';

export class ProductsStore implements ProductsStoreType {
    isOpen = false;

    sections: Product[] = [
        {
            id: 'binary',
            name: 'products.binaryTitle',
            isBeta: true,
            description: 'products.binaryDescription',
            links: [
                {
                    id: 'how-to-start',
                    text: 'products.howToStart',
                    action: 'https://docs.logium.org/logium-docs/logium-v2/how-to-use',
                },
                {
                    id: 'feedback',
                    text: 'products.feedback',
                    action: 'https://forms.gle/HFnfWUEVJr656jAG8',
                },
                {
                    id: 'discord',
                    text: 'products.discord',
                    action: 'https://discord.com/invite/pdDFFfxfWq',
                },
                {
                    id: 'landing',
                    text: 'products.landing',
                    action: 'https://logium.org/',
                },
            ],
        },
        {
            id: 'bets',
            name: 'products.betsTitle',
            description: 'products.betsDescription',
            link: 'https://app.logium.org/',
            links: [
                {
                    id: 'how-to-start',
                    text: 'products.howToStart',
                    action: 'https://docs.logium.org/logium-docs/logium-v1/introduction',
                },
                {
                    id: 'feedback',
                    text: 'products.feedback',
                    action: 'https://forms.gle/crVrWH9sQzj64z6C9',
                },
                {
                    id: 'discord',
                    text: 'products.discord',
                    action: 'https://discord.com/invite/pdDFFfxfWq',
                },
                {
                    id: 'landing',
                    text: 'products.landing',
                    action: 'https://logium.org/',
                },
            ],
        },
    ];

    links: Link[] = [
        {
            id: 'landing',
            text: 'products.landing',
            action: 'https://logium.org/',
        },
        {
            id: 'discord',
            text: 'products.discord',
            action: 'https://discord.com/invite/pdDFFfxfWq',
        },
        {
            id: 'how-to-start',
            text: 'products.howToUse',
            action: 'https://docs.logium.org/logium-docs/logium-v2/how-to-use',
        },
        {
            id: 'feedback',
            text: 'products.feedback',
            action: 'https://forms.gle/HFnfWUEVJr656jAG8',
        },
    ];

    constructor(private root: RootStoreType) {
        this.isOpen = false;
    }

    setOpen(isOpen: boolean) {
        this.isOpen = isOpen;
    }
}
