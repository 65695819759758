import { ResolutionString } from '@charting-library/';
import { DefaultConfigType } from './DefaultConfig.types';

export class DefaultConfig implements DefaultConfigType {
    binance = {
        websocketBaseUrl: 'wss://stream.binance.com:9443',
        apiUrl: 'https://api.binance.com/api/v3',
        reconnectDelay: 3000,
    };

    logiumBets = 'https://app.logium.org/';

    wallet = {
        signMessage: 'Never sign this message outside logium page',
    };

    endpoints = {
        eldwa: 'https://demo-api.logium.org/graphql',
    };

    newsletter = {
        endpoint:
            'https://static.mailerlite.com/js/w/webforms.min.js?vd4de52e171e8eb9c47c0c20caf367ddf',

        successScript: '/newsletterSuccess.js',
        id: '5847402',
    };
    strikePoint = {
        defaultDelay: 5000,
    };
    toasts = {
        defaultDelay: 5000,
    };
    user = {
        defaultVolume: 10_000_000_000n,
    };

    chart = {
        timeframe: '1S' as ResolutionString,
        type: 3, // Area = 3 More on charting_library/wiki/Overrides
        tradingviewResolutionKey:
            'tradingview.chart.lastUsedTimeBasedResolution',
        overrides: {
            wbtceth: {
                timeframe: '1' as ResolutionString,
            },
        },
    };

    breakpoints = {
        fullHd: 1920,
    };
    topUp = {
        limit: 10_000_000_000n,
    };
    tradingViewIframe = 'iframe[id^="tradingview_"]';
    pairHighlight = '#header-toolbar-symbol-search';
    highlight = {
        background: '#143a8799',
        delay: 1000,
    };
    firebase = {
        apiKey: 'AIzaSyBmiVH3JwUtf2eWXclR8UDHfvAS7OmCLHk',
        authDomain: 'lv2-demo.firebaseapp.com',
        projectId: 'lv2-demo',
        storageBucket: 'lv2-demo.appspot.com',
        messagingSenderId: '999346054352',
        appId: '1:999346054352:web:6c02688a20769555666ad5',
        measurementId: 'G-WLMRDY4933',
    };

    device = {
        maxMobileWidth: 767,
    };
}
