import React from 'react';
import { switchStyles } from './Switch.css';
import { SwitchProps } from './Switch.types';

export const Switch = ({
    turnOn,
    onClick,
    blackAndWhite = false,
}: SwitchProps) => {
    return (
        <div
            className={switchStyles.container(turnOn, blackAndWhite)}
            onClick={onClick}>
            <div className={switchStyles.indicator(turnOn, blackAndWhite)} />
        </div>
    );
};
