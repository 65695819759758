import { ComponentHeader } from '@/components/Sidebar/ComponentHeader/ComponentHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Toasts } from '../Toasts/Toasts';
import { useStore } from '../storeContext';
import { BetInput } from './BetInput';
import { Chart } from './Chart/Chart';
import { FavoriteAssets } from './FavoriteAssets';
import { fractualBetsStyles } from './FractualBets.css';
import { Table } from './Table/Table';

export const FractualBets: React.FC = observer(() => {
    const store = useStore();

    const isExpanded = store?.ui.betsPage.betsTable.isOpened;

    return (
        <div className={fractualBetsStyles.container(isExpanded)}>
            <div>
                <FavoriteAssets />
            </div>
            <div className={fractualBetsStyles.betInput(isExpanded)}>
                <ComponentHeader />
                <BetInput />
            </div>
            <div className={fractualBetsStyles.table(isExpanded)}>
                <Table />
            </div>
            <div className={fractualBetsStyles.chart}>
                <Chart />
                <Toasts />
            </div>
        </div>
    );
});
