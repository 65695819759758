import { Prisma } from "@prisma/client";
import { registerCustom } from "superjson";

import { BigNumber } from "./src/config/constants";

export const setup = () => {
  registerCustom<Prisma.Decimal, string>(
    {
      isApplicable: (v): v is Prisma.Decimal => Prisma.Decimal.isDecimal(v),
      serialize: (v) => v.toJSON(),
      deserialize: (v) => new Prisma.Decimal(v),
    },
    "@prisma/client/decimal"
  );
  registerCustom<BigNumber, string>(
    {
      isApplicable: (v): v is BigNumber => BigNumber.isBigNumber(v),
      serialize: (v) => v.toJSON(),
      deserialize: (v) => new BigNumber(v),
    },
    "BigNumber"
  );
};
