import { KlineMessage } from './BinanceStore.types';

export class Kline {
    constructor(public raw: KlineMessage) {}

    update(raw: KlineMessage) {
        this.raw = raw;
    }

    get open() {
        return this.raw.k.o;
    }

    get close() {
        return this.raw.k.c;
    }

    get high() {
        return this.raw.k.h;
    }

    get low() {
        return this.raw.k.l;
    }

    get volume() {
        return this.raw.k.v;
    }

    get startTimeMs() {
        return this.raw.k.t;
    }

    get closeTimeMs() {
        return this.raw.k.T;
    }

    get isClosed() {
        return this.raw.k.x;
    }
}
