import { Button } from '@/components/Button';
import { ModalContainer } from '@/components/Modal/ModalContainer';
import { VirtualInfoMobile } from '@/components/VirtualInfoMobile/VirtualInfoMobile';
import { WalletHeader } from '@/components/WalletHeader/WalletHeader';
import { formatVolume } from '@/tools/format';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../storeContext';

export const Footer = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();

    return (
        <button
            className="h-full w-full text-greyscale-primary text-mobile-buttons-small-light"
            onClick={onClick}>
            {t('profilePopup.topUpBtn')}
        </button>
    );
};

export const AccountDetailsMobile = observer(() => {
    const store = useStore();
    const { t } = useTranslation();
    const modal = store?.ui.modals.activeModal;

    const profile = store?.ui.nav.profile;
    const user = store?.user.user;

    if (!modal || modal.type !== 'accountDetailsMobile') return null;

    const onClose = useCallback(() => {
        if (modal) modal.resolve('close');
    }, [modal]);

    const onTopUpClick = useCallback(() => {
        if (modal) modal.resolve('topUp');
    }, [modal]);

    const onResetClick = useCallback(() => {
        if (modal) modal.resolve('reset');
    }, [modal]);

    return (
        <ModalContainer
            header={t('profilePopup.header')}
            footer={<Footer onClick={onTopUpClick} />}
            onClose={onClose}
            isMobile={true}>
            <>
                <div className="p-4 border-b border-greyscale-line">
                    <WalletHeader
                        address={profile?.getWalletAddressStr(t) ?? ''}
                    />
                    <Button
                        variant="grey"
                        className="mt-4 w-full py-2.5"
                        onClick={onResetClick}>
                        {t('profilePopup.resetBtn')}
                    </Button>
                </div>
                <div className="p-4 border-b border-greyscale-line">
                    <div className="flex justify-between text-mobile-inputs-medium-light">
                        <span className="text-greyscale-secondary">
                            {t('profilePopup.virtualAccountBalance')}
                        </span>
                        {profile?.availableAssets?.map(asset => (
                            <span
                                key={asset.id}
                                className="text-greyscale-primary">
                                $
                                {formatVolume(
                                    user?.getBalance(asset),
                                    asset.decimals,
                                )}
                            </span>
                        ))}
                    </div>
                </div>
                <VirtualInfoMobile className="p-4" />
            </>
        </ModalContainer>
    );
});
