import InfoIcon from '@/assets/icons/info.svg';
import { BetType } from '@/bets/BetsStore.types';
import { TFuncKey } from 'i18next';
import {
    BetCreatedType,
    MultipleBetsCreatedType,
    ToastsStoreType,
} from '../ToastsStore.types';
import { getBaseToast } from './baseToast';

export const multipleBetsCreated = (
    toasts: ToastsStoreType,
    bet: BetType,
    lastToast: BetCreatedType | MultipleBetsCreatedType,
    count: number,
    decimals: number | undefined,
): MultipleBetsCreatedType => {
    const base = {
        type: 'multipleBetsCreated' as const,
        header: 'toasts.multiplePlacedHeader' as TFuncKey,
        headerValues: {
            count,
        },
        icon: InfoIcon,
        borderColor: 'border-primary-medium',
    };

    return {
        ...getBaseToast(toasts, base, lastToast.id),
        symbol: bet.market?.formattedSymbol ?? '-',
        isUp: bet.direction === 'up' ? true : false,
        volume: lastToast.volume + bet.volume,
        decimals,
        volumeText: 'toasts.multipleAmount',
    };
};
