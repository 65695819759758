import { getMessage } from './ErrorMessageBuilder';
import { AppError, ErrorsMap } from './ErrorsStore.types';

export class AppErrorImpl<T extends keyof ErrorsMap>
    extends Error
    implements AppError<T>
{
    parameters: ErrorsMap[T];
    errorCode: T;
    constructor(errorCode: T, ...parameters: ErrorsMap[T]) {
        super(getMessage(errorCode, ...parameters));
        this.errorCode = errorCode;
        this.parameters = parameters;
    }
}
