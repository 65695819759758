import routes from '@/config/routes';
import { RootStoreType } from '@/RootStoreTypes';
import { ConnectorName } from '@/wallet/WalletStore.types';
import { makeAutoObservable } from 'mobx';
import {
    ConnectionStatus,
    WalletConnectionStatusModalType,
} from '../ModalsStore.types';
import { getBaseModal } from './baseModal';

export const getWalletConnectionStatusModal = (
    root: RootStoreType,
    wallet: ConnectorName,
): WalletConnectionStatusModalType => {
    return makeAutoObservable({
        ...getBaseModal(root.ui.modals, 'walletConnectionStatus'),
        status: 'wait' as ConnectionStatus,
        wallet,
        setStatus(status: ConnectionStatus) {
            this.status = status;
        },
        goToTrade() {
            this.resolve();
            root.router.goTo(routes.bets);
        },
        retry() {
            this.resolve();
            root.useCases.connectWallet(wallet);
        },
        tryOther() {
            this.resolve();
            root.useCases.startWalletConnection();
        },
    });
};
