import close from '@/assets/icons/close.svg';
import { formatProc, formatVolume } from '@/tools/format';
import React, { FC } from 'react';
import { If } from '../If/If';
import { favoriteMarketStyles } from './FavoriteMarket.css';
import { FavoriteMarketProps } from './FavoriteMarket.types';
export const FavoriteMarket: FC<FavoriteMarketProps> = ({
    name,
    isWinning,
    volumeChange,
    rate,
    isActive,
    decimals,
    onClick,
    onClose,
}) => {
    return (
        <div className={favoriteMarketStyles.wrapper}>
            <button
                name={`select-fav-market-${name}`}
                className={favoriteMarketStyles.main(isActive)}
                onClick={onClick}>
                <span>{name}</span>
                <If condition={isWinning === true}>
                    <div className={favoriteMarketStyles.win} />
                </If>
                <If condition={isWinning === false}>
                    <div className={favoriteMarketStyles.lose} />
                </If>

                <If condition={volumeChange === undefined}>
                    <span>{formatProc(rate)}</span>
                </If>
                <If condition={volumeChange !== undefined}>
                    <If condition={isWinning === true}>
                        <span className="text-success-default">
                            {' '}
                            +${formatVolume(volumeChange, decimals)}
                        </span>
                    </If>
                    <If condition={isWinning === false}>
                        <span className="text-error-default">
                            {' '}
                            -${formatVolume(volumeChange, decimals)}
                        </span>
                    </If>
                </If>
            </button>
            <div className={favoriteMarketStyles.closeWrapper}>
                <button
                    name={`remove-fav-market-${name}`}
                    className={favoriteMarketStyles.close}
                    onClick={e => {
                        onClose?.();
                        e.stopPropagation();
                    }}>
                    <img className="h-6 w-6" src={close} />
                </button>
            </div>
        </div>
    );
};

FavoriteMarket.displayName = 'FavoriteMarket';
