import React, { FC } from 'react';
import { labeledStyles } from './Labeled.css';
import { LabeledProps } from './Labeled.types';

export const Labeled: FC<LabeledProps> = ({ children, label, rightText }) => {
    return (
        <div className={labeledStyles.container}>
            <div className={labeledStyles.labelWrapper}>
                <span className={labeledStyles.label}>{label}</span>
                <span className={labeledStyles.label}>{rightText}</span>
            </div>
            {children}
        </div>
    );
};

Labeled.displayName = 'Labeled';
