import { joinClassnames } from '@/tools/css';

export const betToastStyles = {
    description: joinClassnames(['flex', 'mt-3', 'mb-1.5']),
    special: (color: 'green' | 'white') =>
        joinClassnames([
            color === 'green' ? 'text-success-default' : 'text-consts-white',
        ]),

    arrow: joinClassnames(['w-2.5', 'h-2.5', 'mt-1']),
};
