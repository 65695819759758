import { useClassName } from '@/tools/css';
import React from 'react';
import { Item } from './Item/Item';
import { NavigationTabsProps } from './NavigationTabs.types';

export const NavigationTabs = <T,>({
    items,
    active,
    onClick,
    getKey,
    className,
    isOpen,
}: NavigationTabsProps<T>) => {
    const elClassName = useClassName(
        'w-full overflow-hidden rounded-t-md transition-all',
        isOpen ? 'opacity-100 visible' : 'opacity-0 invisible',
        className,
    );

    return (
        <div className={elClassName}>
            {items.map((item, i) => {
                return (
                    <Item
                        key={getKey ? getKey(item.value) : i}
                        item={item}
                        active={active}
                        onClick={onClick}
                    />
                );
            })}
        </div>
    );
};
