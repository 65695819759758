import { useEffect, useState } from 'react';

export const useViewport = () => {
    const [height, setHeight] = useState(0);
    const [fullHeight, setFullHeight] = useState(0);
    const [isScrollEnabled, setIsScrollEnabled] = useState(false);
    const [isLandscape, setIsLandscape] = useState(false);

    useEffect(() => {
        const innerWidth = window.innerWidth;

        // enable app scroll only on landscape mode
        setIsScrollEnabled(innerWidth >= fullHeight);
    }, [fullHeight, height]);

    useEffect(() => {
        const handleResize = () => {
            const innerHeight = window.innerHeight;
            const innerWidth = window.innerWidth;

            setHeight(innerHeight);

            setIsLandscape(innerWidth > innerHeight);

            setFullHeight(prev => {
                if (prev < innerHeight) {
                    return innerWidth > innerHeight ? innerWidth : innerHeight;
                }

                return prev;
            });
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return { height, fullHeight, isScrollEnabled, isLandscape };
};
