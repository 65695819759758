import chartIcon from '@/assets/icons/chart.svg';
import React from 'react';
import { Trans } from 'react-i18next';
import { ChartOverlayProps } from './ChartOverlay.types';

export const ChartOverlay = ({ visible }: ChartOverlayProps) => {
    const activeClass = visible ? 'opacity-100 visible' : 'opacity-0 invisible';

    return (
        <div
            className={`absolute inset-0 flex items-center justify-center flex-col bg-greyscale-background transition-all ${activeClass}`}>
            <div className="w-10 h-10 rounded bg-greyscale-input flex items-center justify-center mb-4">
                <img src={chartIcon} alt="chart" />
            </div>
            <p className="text-greyscale-placeholder text-xs font-medium text-center">
                <Trans i18nKey="binanceError.chartOverlay" />
            </p>
        </div>
    );
};
