import { toBI, toBN } from '@/tools/big-number';
import { mayBeThen } from '@/tools/maybe';
import { AssetType } from '@/user/User.types';
import { makeAutoObservable } from 'mobx';
import { ModalsStoreType, VolumeInputModal } from '../ModalsStore.types';
import { getBaseModal } from './baseModal';

export const getVolumeInputModal = <T extends string>(
    modals: ModalsStoreType,
    name: T,
    asset: AssetType,
    value?: bigint,
): VolumeInputModal<T, bigint> => {
    const value2String = (value: bigint) =>
        toBN(value).div(toBN(10).pow(asset.decimals)).toString();

    const str2Value = (valueStr: string) => {
        try {
            return toBI(toBN(valueStr).times(toBN(10).pow(asset.decimals)));
        } catch (e) {
            return undefined;
        }
    };

    return makeAutoObservable({
        ...getBaseModal<T, bigint>(modals, name),
        value,
        asset,
        valueStr: mayBeThen(value, value2String) ?? undefined,
        setValueStr(valueStr) {
            this.valueStr = valueStr;
            this.value = str2Value(valueStr);
        },
        value2String,
    });
};
