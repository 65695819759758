import React from 'react';
import { Trans } from 'react-i18next';
import { ToastContainer } from '../ToastContainer';
import { ServerErrorToastProps } from './ServerErrorToast.types';

export const ServerErrorToast = ({
    icon,
    header,
    borderColor,
    resolve,
}: ServerErrorToastProps) => {
    return (
        <ToastContainer
            header={header}
            borderColor={borderColor}
            icon={icon}
            onClose={resolve}>
            <div className="mt-3 mb-1.5">
                <Trans i18nKey="toasts.serverErrorText" />
            </div>
        </ToastContainer>
    );
};
