import PlusIcon from '@/assets/icons/plus.svg';
import { Button } from '@/components/Button';
import { px } from '@/tools/css';
import { useStore } from '@/view/storeContext';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FavoriteAsset } from './favoriteAsset';
import { favoriteAssetsStyles } from './FavoriteAssets.css';

export const FavoriteAssets: FC = observer(() => {
    const { t } = useTranslation();
    const store = useStore();

    const favoriteMarkets = store?.ui.betsPage.favoriteMarkets;

    return (
        <div className={favoriteAssetsStyles.container}>
            {favoriteMarkets?.items.length == 0 ? (
                <span className={favoriteAssetsStyles.text}>
                    {t('favoriteAssets.addText')}
                </span>
            ) : null}
            {favoriteMarkets?.items.map(market => (
                <FavoriteAsset key={market.market.symbol} market={market} />
            ))}
            <Button
                variant="short"
                name="favorite-market-add"
                style={{ marginLeft: `${px(12)}` }}
                onClick={() => {
                    favoriteMarkets?.addSelected();
                }}>
                <img
                    src={PlusIcon}
                    className={favoriteAssetsStyles.icon}
                    alt="plus"
                />
            </Button>
        </div>
    );
});

FavoriteAssets.displayName = 'FavoriteAssets';
