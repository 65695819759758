import { className } from '@/tools/css';
import React from 'react';
import { If } from '../If/If';

export type ModalMessageProps = {
    iconUrl?: string;
    iconBackground?: boolean;
    spin?: boolean;
    iconSize?: number;
    title: string;
    message: string;
};

export const ModalMessage: React.FC<ModalMessageProps> = ({
    iconUrl,
    iconBackground,
    message,
    title,
    spin,
    iconSize = 5,
}) => {
    return (
        <div className="flex flex-col items-center justify-center h-full flex-shrink flex-grow gap-2">
            <If condition={!!iconUrl}>
                <div
                    className={className(
                        'w-12 h-12 rounded-md flex items-center justify-center',
                        iconBackground ? 'bg-greyscale-input' : null,
                    )}>
                    <img
                        src={iconUrl}
                        className={className(
                            `w-${iconSize} h-${iconSize}`,
                            spin ? 'animate-spin' : null,
                        )}
                    />
                </div>
            </If>
            <h1 className="text-greyscale-primary">{title}</h1>
            <p className="text-greyscale-placeholder">{message}</p>
        </div>
    );
};
