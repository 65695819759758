import { joinClassnames } from '@/tools/css';

export const modalContainerStyles = {
    wrapper: joinClassnames([
        'absolute',
        'inset-0',
        'flex',
        'items-center',
        'justify-center',
        'bg-modalOverlay',
        'z-modalUnderlay',
    ]),
    body: (extended: boolean) =>
        joinClassnames([
            'bg-greyscale-background',
            'min-h-modal',
            'rounded-md',
            'overflow-hidden',
            'flex',
            'flex-col',
            extended ? 'w-extendedModal' : 'w-modal',
        ]),
    bodyMobile: joinClassnames([
        'absolute',
        'inset-0',
        'bg-greyscale-background',
        'flex',
        'flex-col',
        'justify-between',
        'text-mobile-headers-small',
        'overflow-auto',
    ]),
    header: (border: boolean) =>
        joinClassnames([
            'flex',
            'justify-between',
            'items-center',
            'p-4',
            border ? 'border-b' : '',
            'border-greyscale-line',
        ]),
    headerTitle: joinClassnames([
        'text-greyscale-primary',
        'text-sm',
        'font-semibold',
    ]),
    closeBtn: 'cursor-pointer',
    content: (isMobile: boolean) =>
        `h-full flex-shrink flex-grow flex flex-col ${!isMobile && 'p-4'}`,
    footer: joinClassnames(['border-t', 'border-greyscale-line', 'p-4']),
};
