import errorIcon from '@/assets/icons/error.svg';
import { TFuncKey } from 'i18next';
import { ToastsStoreType } from '../ToastsStore.types';
import { getBaseToast } from './baseToast';

export const serverError = (toasts: ToastsStoreType) => {
    const base = {
        type: 'serverError' as const,
        header: 'toasts.serverErrorHeader' as TFuncKey,
        icon: errorIcon,
        borderColor: 'border-error-default',
    };

    return {
        ...getBaseToast(toasts, base),
    };
};
