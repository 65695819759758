import { getBaseModal } from './baseModal';
import {
    ModalsStoreType,
    ConfirmWalletSelectionModal,
} from '../ModalsStore.types';
import { ConnectorName } from '@/wallet/WalletStore.types';

export const getConfirmWallet = (
    modals: ModalsStoreType,
    wallet: ConnectorName,
): ConfirmWalletSelectionModal => {
    return {
        ...getBaseModal(modals, 'confirmWallet'),
        wallet,
    };
};
