// Workaround:
// The server uses Prisma.Decimal in the API
// We want to be sure the version of Decimals is the same in the frontend
// It should be removed as only server stop using Prisma.Decimal in the API
import { Prisma } from '@prisma/client';

// The server uses BigNumber in the API
// Also server uses specific BigNumber configuration for calculations
// There is no global configuration of BigNumber to import
// So we need to import it from the server
// The frontend should not use BigNumber directly
import { BigNumber } from 'logium-v2-server/src/config/constants';

export type BigNumberableTypes =
    | string
    | number
    | bigint
    | Prisma.Decimal
    | BigNumber;

export type BigNumberType = BigNumber;

export const toBN = (value: BigNumberableTypes): BigNumber => {
    if (BigNumber.isBigNumber(value)) {
        return value;
    } else if (Prisma.Decimal.isDecimal(value)) {
        return new BigNumber(value.toFixed());
    } else if (typeof value === 'bigint') {
        return new BigNumber(value.toString());
    } else {
        return new BigNumber(value);
    }
};

export const toBI = (value: BigNumberableTypes): bigint => {
    if (BigNumber.isBigNumber(value)) {
        return BigInt(value.toFixed());
    } else if (Prisma.Decimal.isDecimal(value)) {
        return BigInt(value.toFixed());
    } else if (typeof value === 'bigint') {
        return value;
    } else {
        return BigInt(value);
    }
};
