export class Api {
    constructor(protected apiUrl: string) {}

    async get(endpoint: string) {
        const response = await fetch(`${this.apiUrl}${endpoint}`);

        if (!response.ok) return Promise.reject();

        return await response.json();
    }

    async post(endpoint: string, data: object) {
        const response = await fetch(`${this.apiUrl}${endpoint}`, {
            method: 'post',
            body: JSON.stringify(data),
        });

        if (!response.ok) return Promise.reject();

        return await response.json();
    }
}
