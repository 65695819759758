import React from 'react';
import { useTranslation } from 'react-i18next';
import { walletHeaderStyles } from './WalletHeader.css';
import { WalletHeaderProps } from './WalletHeader.types';

export const WalletHeader = ({ address }: WalletHeaderProps) => {
    const { t } = useTranslation();

    return (
        <div className={walletHeaderStyles.container}>
            <span className={walletHeaderStyles.text}>
                {t('walletHeader.header')}
            </span>
            <span className={walletHeaderStyles.address}>{address}</span>
        </div>
    );
};
