import { Button } from '@/components/Button';
import { InfoRows } from '@/components/InfoRows/InfoRows';
import { Input } from '@/components/Input';
import { Labeled } from '@/components/Labeled';
import { ModalContainer } from '@/components/Modal/ModalContainer';
import { WalletHeader } from '@/components/WalletHeader/WalletHeader';
import { formatVolume } from '@/tools/format';
import { useVolumeRifm } from '@/tools/rifm/volumeRifm';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../storeContext';

export const TopUpModal: React.FC = observer(() => {
    const store = useStore();
    const { t } = useTranslation();

    if (!store) return null;

    const modal = store.ui.modals.activeModal;
    const user = store.user.user;

    const topUpLimit = store.config.defaultValues.topUp.limit;

    if (!modal || modal.type !== 'topUp') return null;

    const handleButton = () => {
        if (!modal.value) return;
        if (modal.value <= topUpLimit) return modal.resolve(modal.value);
    };

    return (
        <ModalContainer
            isMobile={store.device.isMobile}
            header={t('topUp.header').toString()}
            onClose={() => modal.resolve(0n)}
            footer={
                <Button
                    className="w-full py-2.5"
                    name={'modals-topUp--topUp'}
                    disabled={!modal.value}
                    onClick={handleButton}>
                    {t('topUp.button')}
                </Button>
            }>
            <div className="grid gap-4 p-4">
                <WalletHeader
                    // This is a hack. Component should not use external ui state.
                    // It may be fixed by moving getWalletAddressStr to modal store.
                    address={store.ui.nav.profile.getWalletAddressStr(t) ?? '-'}
                />
                <Labeled label={t('topUp.inputLabel').toString()}>
                    <Input
                        {...useVolumeRifm(modal.valueStr ?? '', v =>
                            modal.setValueStr(v),
                        )}
                        inputMode="decimal"
                        append="USDC"
                        placeholder="0.00/10,000"
                    />
                </Labeled>
                <InfoRows
                    rows={[
                        {
                            label: t('topUp.inputLabel').toString(),
                            value: !modal.value
                                ? '0.00'
                                : formatVolume(
                                      modal.value,
                                      modal.asset.decimals,
                                  ),
                            currency: 'USDC',
                        },
                        {
                            label: t('topUp.accoutBalance').toString(),
                            value: formatVolume(
                                user?.getBalance(modal.asset),
                                modal.asset.decimals,
                            ),
                            currency: 'USDC',
                        },
                    ]}
                />
            </div>
        </ModalContainer>
    );
});
