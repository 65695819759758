import React from 'react';
import ErrorIcon from '@/assets/icons/errInfo.svg';
import { Button } from '@/components/Button';
import { useTranslation } from 'react-i18next';

export const ErrorState = () => {
    const { t } = useTranslation();

    const refetch = () => location.reload();

    return (
        <div className="w-full h-full flex justify-center items-center">
            <div className="flex flex-col items-center">
                <div className="bg-error-background w-12 h-12 flex items-center justify-center rounded-lg">
                    <img src={ErrorIcon} alt="status" />
                </div>
                <p className="text-greyscale-primary font-semibold text-xs my-2">
                    {t('tableErrorState.title')}
                </p>
                <Button variant="secondary" onClick={refetch}>
                    {t('tableErrorState.button')}
                </Button>
            </div>
        </div>
    );
};
