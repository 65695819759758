import { joinClassnames } from '@/tools/css';

export const inputStyles = {
    main: joinClassnames([
        'w-full',
        'bg-greyscale-input',
        'outline-none',
        'text-consts-white',
        'font-semibold',
        'text-xs',
        'h-full',
        'rounded-md',
        'p-2',
    ]),
    error: `border border-error-default`,
    container: joinClassnames(['h-8', 'w-full', 'relative']),
    append: joinClassnames([
        'absolute',
        'top-1/2',
        'right-2',
        '-translate-y-2/4',
        'text-xs',
        'text-greyscale-primary',
        'font-medium',
    ]),
    errorContainer: joinClassnames(['flex', 'justify-end']),
    errorText: joinClassnames([
        'text-mobile-labels-small',
        'text-error-default',
        'text-right',
    ]),
};
