import close from '@/assets/icons/close.svg';
import React from 'react';
import { If } from '../If/If';
import { modalContainerStyles } from './ModalContainer.css';
import { ModalContainerProps } from './ModalContainer.types';

export const ModalContainer: React.FC<ModalContainerProps> = ({
    header,
    onClose,
    children,
    footer,
    extended = false,
    headerBorder = true,
    isMobile = false,
}) => {
    return (
        <div className={modalContainerStyles.wrapper}>
            <div
                className={
                    isMobile
                        ? modalContainerStyles.bodyMobile
                        : modalContainerStyles.body(extended)
                }>
                <div className={modalContainerStyles.header(headerBorder)}>
                    <span className={modalContainerStyles.headerTitle}>
                        {header}
                    </span>
                    {onClose && (
                        <button name="close-modal" onClick={onClose}>
                            <img
                                src={close}
                                alt="close"
                                className={modalContainerStyles.closeBtn}
                            />
                        </button>
                    )}
                </div>
                <div className={modalContainerStyles.content(isMobile)}>
                    {children}
                </div>
                <If condition={footer !== undefined}>
                    <div className={modalContainerStyles.footer}>{footer}</div>
                </If>
            </div>
        </div>
    );
};

ModalContainer.displayName = 'ModalContainer';
