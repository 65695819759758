import { RootStoreType } from '@/RootStoreTypes';
import { AppErrorImpl } from './AppError';
import { AppError, ErrorsMap, ErrorsStoreType } from './ErrorsStore.types';

export class ErrorsStore implements ErrorsStoreType {
    constructor(private Froot: RootStoreType) {}

    err<T extends keyof ErrorsMap>(
        name: T,
        ...args: ErrorsMap[T]
    ): AppError<T> {
        return new AppErrorImpl(name, ...args);
    }
}
