import { RootStoreType } from '@/RootStoreTypes';
import { WalletInfo } from '@/wallet/WalletStore.types';
import { BigNumber, ethers, Overrides, providers, Signer, utils } from 'ethers';
import { IERC20, IERC20__factory } from 'logium-v2-contract/typechain-types';
import {
    filter,
    from,
    fromEvent,
    map,
    mergeWith,
    Observable,
    of,
    Subscription,
    switchMap,
} from 'rxjs';
import { EventsMap, Ierc20UsdcType } from './Ierc20UsdcContract.types';

class Ierc20AddressObservables {
    approval$: Observable<EventsMap['Approval']>;

    transfer$: Observable<EventsMap['Transfer']>;

    ballance$: Observable<BigNumber>;

    constructor(private contract: IERC20, private address: string) {
        this.approval$ = fromEvent(this.contract, 'Approval');
        this.transfer$ = fromEvent(this.contract, 'Transfer');
        this.ballance$ = this.getBallance$();
    }

    getBallance$() {
        return this.transfer$.pipe(
            filter(
                ([from, to]) => this.address === from || this.address === to,
            ),
            mergeWith(of(null)),
            switchMap(() => from(this.contract.balanceOf(this.address))),
        );
    }
}

export class Ierc20Usdc implements Ierc20UsdcType {
    private subscribtions: Subscription[] = [];

    feeData: ethers.providers.FeeData | null;

    mainWalletBallance: BigNumber | null = null;

    constructor(private root: RootStoreType) {
        this.onMainWalletBallance = this.onMainWalletBallance.bind(this);

        const walletInfo$ = this.root.wallet.walletInfo$();

        this.subscribtions.push(
            this.getBallance$(walletInfo$).subscribe(
                this.onMainWalletBallance.bind(this),
            ),
        );
        this.subscribtions.push(
            this.feeData$(walletInfo$).subscribe(this.setFeeData.bind(this)),
        );
    }

    private onMainWalletBallance(ballance: BigNumber | null) {
        this.mainWalletBallance = ballance;
    }

    destroy() {
        this.subscribtions.forEach(s => s.unsubscribe());
        this.subscribtions = [];
    }

    private get isVirtualMode() {
        return this.root.config.web3.isVirtualMode;
    }

    get overrides(): Overrides {
        if (this.isVirtualMode)
            return {
                gasPrice: 0,
            };

        return {};
    }

    get signer() {
        return this.root.wallet.signer;
    }

    get address() {
        return this.root.wallet.address;
    }

    get logiumCoreAddress() {
        return this.root.config.contracts.logiumCore;
    }
    get usdcAddress() {
        return this.root.config.contracts.USDC;
    }

    getIerc20(signer?: Signer) {
        return IERC20__factory.connect(
            this.usdcAddress,
            signer ? signer : this.getProvider(),
        );
    }

    private getProvider() {
        // code duplication?
        return new providers.JsonRpcProvider(
            this.root.config.web3.gateWay,
            parseInt(this.root.config.web3.chainId),
        );
    }

    get ierc20() {
        if (!this.signer) {
            throw this.root.errors.err('WALLET_NOT_CONNECTED');
        }
        return this.getIerc20(this.signer);
    }

    getLogiumCoreAllowance(): Promise<BigNumber> {
        if (!this.address) {
            throw this.root.errors.err('WALLET_NOT_CONNECTED');
        }

        return this.ierc20.allowance(this.address, this.logiumCoreAddress);
    }

    async approveLogiumCore() {
        const tx = await this.ierc20.approve(
            this.logiumCoreAddress,
            ethers.constants.MaxUint256,
        );
        await tx.wait();
    }

    private getBallance$(walletInfo$: Observable<WalletInfo>) {
        return walletInfo$.pipe(
            switchMap(walletInfo => {
                if (walletInfo.address) {
                    const ierc20AddressObservables =
                        new Ierc20AddressObservables(
                            this.getIerc20(),
                            walletInfo.address,
                        );
                    return ierc20AddressObservables.ballance$;
                } else {
                    return of(null);
                }
            }),
        );
    }

    private async setFeeData(feeData: ethers.providers.FeeData | null) {
        this.feeData = feeData;
    }

    private feeData$(walletInfo$: Observable<WalletInfo>) {
        return walletInfo$.pipe(
            map(walletInfo => walletInfo.signer),
            switchMap(signer => {
                if (signer) {
                    return from(signer.getFeeData());
                } else {
                    return of(null);
                }
            }),
        );
    }

    get networkFee() {
        return this.feeData
            ? utils.formatUnits(this.feeData.maxFeePerGas ?? 0, 'gwei')
            : undefined;
    }
}
