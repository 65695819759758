import { Button } from '@/components/Button';
import { ButtonPropsType, StatusModalType } from '@/modals/ModalsStore.types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type StatusModalFooterProps = {
    modal: StatusModalType;
    primaryBtn: ButtonPropsType<StatusModalType>;
    secondaryBtn?: ButtonPropsType<StatusModalType>;
};

export const StatusModalFooter = ({
    modal,
    primaryBtn,
    secondaryBtn,
}: StatusModalFooterProps) => {
    const { t } = useTranslation();

    const handlePrimaryClick = useCallback(() => {
        primaryBtn.callback?.(modal);
    }, [modal, primaryBtn]);

    const handleSecondaryClick = useCallback(() => {
        secondaryBtn?.callback?.(modal);
    }, [modal, secondaryBtn]);

    return (
        <>
            <Button
                href={primaryBtn.link}
                onClick={handlePrimaryClick}
                className="w-full mb-2 py-3">
                {t(primaryBtn.text).toString()}
            </Button>
            {secondaryBtn && (
                <Button
                    href={secondaryBtn.link}
                    onClick={handleSecondaryClick}
                    variant="grey"
                    className="w-full py-3">
                    {t(secondaryBtn.text).toString()}
                </Button>
            )}
        </>
    );
};
