import { makeAutoObservable } from 'mobx';
import { ModalsStoreType, NewsletterModalType } from '../ModalsStore.types';
import { getBaseModal } from './baseModal';

export const getNewsletterModal = (
    modals: ModalsStoreType,
): NewsletterModalType =>
    makeAutoObservable({
        ...getBaseModal(modals, 'newsletter'),
    });
