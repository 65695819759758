import { RootStoreType } from '@/RootStoreTypes';
import {
    doc,
    Firestore,
    getFirestore,
    runTransaction,
} from 'firebase/firestore';
import { Subscription } from 'rxjs';
import { FirebaseStoreType } from './Firebase.types';

import { getAuth, signInAnonymously, UserCredential } from 'firebase/auth';

export class FirebaseStore implements FirebaseStoreType {
    static WALLETS_DOCUMENT = 'wallets';
    db: Firestore;
    user: Promise<UserCredential>;

    walletSubscription?: Subscription;

    constructor(private root: RootStoreType) {
        const auth = getAuth(this.root.firebase);
        this.user = signInAnonymously(auth);
        this.db = getFirestore(root.firebase);
    }

    private async addWallet(address: string) {
        return runTransaction(this.db, async tx => {
            const walletDocRef = doc(this.db, 'wallets', address);
            const walletDoc = await tx.get(walletDocRef);
            if (!walletDoc.exists()) {
                await tx.set(walletDocRef, { address });
            }
        });
    }

    initialize() {
        const wallet = this.root.wallet;
        this.walletSubscription = wallet.walletInfo$().subscribe({
            next: async ({ address, connectorName }) => {
                if (connectorName === 'metamask' && address) {
                    try {
                        await this.user;
                        await this.addWallet(address);
                    } catch (e) {
                        console.error(e);
                    }
                }
            },
        });
    }

    destroy() {
        this.walletSubscription?.unsubscribe();
    }
}
