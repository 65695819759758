import { LayoutSizeStoreType } from './LayoutSizeStore.types';
import { RootStoreType } from '@/RootStoreTypes';
import { fromEvent, map, merge, Observable, of } from 'rxjs';

export class LayoutSizeStore implements LayoutSizeStoreType {
    private window;
    width$: Observable<number>;
    constructor(private root: RootStoreType, private resizeWindow: Window) {
        this.window = resizeWindow;

        this.width$ = this.getSize();
    }

    private getSize() {
        return merge(
            fromEvent(window, 'resize').pipe(map(() => this.window.innerWidth)),
            of(this.window.innerWidth),
        );
    }
}
