import { ethers, providers } from 'ethers';
import { getAddress } from 'ethers/lib/utils';
import { from, fromEvent, map, merge, Observable } from 'rxjs';
import { WalletConnector } from './WalletStore.types';

export class MetamaskConnector implements WalletConnector<'metamask'> {
    provider: providers.Web3Provider;

    name = 'metamask' as const;

    chainId$: Observable<number | undefined>;

    account$: Observable<string | undefined>;

    constructor() {
        this.provider = this.getProvider();
        this.chainId$ = this.getChainId$();
        this.account$ = this.getAccount$();
    }

    private getChainId$() {
        return merge(
            this.getProviderEventStream<string>('chainChanged').pipe(
                map(parseInt),
            ),
            from(this.provider.getSigner().getChainId()),
        );
    }

    private getAccount$() {
        return merge(
            this.getProviderEventStream<string[]>('accountsChanged').pipe(
                map(accs => getAddress(accs[0])),
            ),
            from(
                this.isAuthorized().then(async isAuthorized =>
                    isAuthorized
                        ? getAddress(await this.signer.getAddress())
                        : undefined,
                ),
            ),
        );
    }
    private getProviderEventStream<T>(eventName: string) {
        // ExternalProvider is not typed good
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return fromEvent<T>(this.provider.provider as any, eventName);
    }

    private getProvider() {
        return new ethers.providers.Web3Provider(window.ethereum);
    }

    get signer() {
        return this.provider.getSigner();
    }

    async connect() {
        await this.provider?.send('eth_requestAccounts', []);
        const address = await this.signer.getAddress();
        const chainId = await this.signer.getChainId();

        return {
            address,
            chainId,
        };
    }

    async isAuthorized() {
        const resp = await this.provider?.send('eth_accounts', []);
        return resp?.length > 0;
    }
}
