// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

import { RootStoreType } from '@/RootStoreTypes';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export const firebaseAppFactory = (root: RootStoreType) => {
    // Initialize Firebase
    const app = initializeApp(root.config.defaultValues.firebase);
    getAnalytics(app);
    return app;
};
