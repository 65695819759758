import collapseIcon from '@/assets/icons/collapse.svg';
import expandIcon from '@/assets/icons/expand.svg';
import { Button } from '@/components/Button';
import { Tab } from '@/components/Tab/Tab';
import { BetsTableTypes } from '@/ui/betsPageStore/betsTable/BetsTableStore.types';
import { useStore } from '@/view/storeContext';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { tableNavStyles } from './TableNav.css';

export const TableNav = observer(() => {
    const store = useStore();

    if (!store) return null;

    const activeItem = store?.ui.betsPage.betsTable.activeTab;
    const isOpened = store?.ui.betsPage.betsTable.isOpened;
    const isMobile = store.device.isMobile;
    const quantities = store?.ui.betsPage.betsTable.quantities;

    const handleTabClick = (id: BetsTableTypes) => {
        store?.ui.betsPage.betsTable.setActiveTab(id);
    };

    const handleBtnClick = () => {
        store?.ui.betsPage.betsTable.setIsOpened(!isOpened);
    };

    const CTAIcon = isOpened ? collapseIcon : expandIcon;

    return (
        <div className={tableNavStyles.container}>
            <div className={tableNavStyles.tabs}>
                {store?.ui.betsPage.betsTable.tabs.map(tab => (
                    <Tab
                        key={tab.id}
                        id={tab.id}
                        value={tab.value}
                        onClick={handleTabClick}
                        isActive={activeItem === tab.id}
                        isMobile={isMobile}
                        quantity={isMobile ? quantities[tab.id] : 0}
                    />
                ))}
            </div>
            {!isMobile && (
                <Button
                    variant="short"
                    onClick={handleBtnClick}
                    name={'expand-table-nav'}>
                    <div className={tableNavStyles.iconWrapper}>
                        <img src={CTAIcon} alt="expand" />
                    </div>
                </Button>
            )}
        </div>
    );
});
