import { RootStoreType } from '@/RootStoreTypes';
import { providers, Signer, Wallet } from 'ethers';
import { from, Observable } from 'rxjs';
import { WalletConnector } from './WalletStore.types';

export class LogiumWalletConnector implements WalletConnector<'logiumWallet'> {
    static UNSAFE_KEY_STORAGE_KEY = 'UnsafeWalletKey';

    name = 'logiumWallet' as const;

    private wallet: Wallet;

    chainId$: Observable<number>;

    account$: Observable<string>;

    constructor(private root: RootStoreType) {
        const unsafeKey = this.getKey();
        if (unsafeKey === null) {
            this.wallet = Wallet.createRandom();
            this.saveKey(this.wallet.privateKey);
        } else {
            this.wallet = new Wallet(unsafeKey);
        }
        this.wallet = this.wallet.connect(this.getProvider());
        this.chainId$ = from(this.wallet.getChainId());
        this.account$ = from(this.wallet.getAddress());
    }

    private saveKey(key: string) {
        this.storage.setItem<string>(
            LogiumWalletConnector.UNSAFE_KEY_STORAGE_KEY,
            key,
        );
    }

    private getKey() {
        return this.storage.getItem<string>(
            LogiumWalletConnector.UNSAFE_KEY_STORAGE_KEY,
        );
    }

    private getProvider() {
        return new providers.JsonRpcProvider(
            this.config.web3.gateWay,
            parseInt(this.config.web3.chainId),
        );
    }

    get signer(): Signer {
        return this.wallet;
    }

    get config() {
        return this.root.config;
    }

    get storage() {
        return this.root.storage;
    }

    async connect(): Promise<{ chainId: number; address: string }> {
        const address = this.wallet.address;
        const chainId = await this.wallet.getChainId();

        return {
            address,
            chainId,
        };
    }

    async isAuthorized() {
        return true;
    }
}
