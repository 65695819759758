import { RootStoreType } from '@/RootStoreTypes';
import { HighlightSymbolType } from './HighlightSymbol.types';

export class HighlightSymbol implements HighlightSymbolType {
    private timeouts: Map<string, ReturnType<typeof setTimeout>> = new Map();

    constructor(private root: RootStoreType) {}

    private clearTimeout(id: string) {
        const element = this.timeouts.get(id);

        if (element) clearTimeout(element);
    }

    highlight(iframe: string, element: string): void {
        this.clearTimeout(element);

        const iframeContainer = document.querySelector(
            iframe,
        ) as HTMLIFrameElement;

        if (iframeContainer.contentWindow) {
            const symbolContainer =
                iframeContainer.contentWindow.document.querySelector(
                    element,
                ) as HTMLDivElement;

            symbolContainer.setAttribute(
                'style',
                `background: ${this.root.config.defaultValues.highlight.background}`,
            );

            const timeout = setTimeout(() => {
                symbolContainer.setAttribute('style', 'background: none;');
            }, this.root.config.defaultValues.highlight.delay);

            this.timeouts.set(element, timeout);
        }
    }

    destroy(): void {
        this.timeouts.forEach(timeout => clearTimeout(timeout));
    }
}
