import { Labeled } from '@/components/Labeled';
import { Options } from '@/components/Options';
import { BetConfigType } from '@/markets/MarketsStore.types';
import { getPeriodLabel } from '@/tools/getPeriodLabel/getPeriodLabel';
import { useStore } from '@/view/storeContext';
import { observer } from 'mobx-react-lite';
import React from 'react';

const getName = (value: number) => `period-${value}`;

export const Time = observer(() => {
    const store = useStore();

    if (!store) {
        return null;
    }

    const betInput = store.ui.betsPage.betInput;

    const items = betInput?.betConfigs.map(item => {
        return {
            value: item,
            key: item.id,
            label: getPeriodLabel(item.betDurationSec),
            name: getName(item.betDurationSec),
        };
    });

    const handleClick = (value: BetConfigType) => {
        betInput.setBetConfig(value);
    };

    return (
        <Labeled label="Time">
            <Options
                items={items}
                onClick={handleClick}
                active={betInput.betConfig}
            />
        </Labeled>
    );
});
