import arrowDownIcon from '@/assets/icons/arrowDown.svg';
import arrowUpIcon from '@/assets/icons/arrowUp.svg';
import { Button } from '@/components/Button';
import { buttonStyles } from '@/components/Button/Button.css';
import { useStore } from '@/view/storeContext';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsProps } from './Buttons.types';

export const Buttons = observer(({ isMobile = false }: ButtonsProps) => {
    const store = useStore();
    const { t } = useTranslation();

    const clasName = isMobile ? 'grid grid-cols-2 gap-2' : '';

    if (!store) {
        return null;
    }
    const betInput = store.ui.betsPage.betInput;
    const disabledBtn =
        !betInput.isPriceDefined ||
        betInput.user?.fetchUserFailed ||
        !betInput.canTakeBet;

    return (
        <div className={clasName}>
            <Button
                variant="trade"
                name="bet-up"
                isMobile={store.device.isMobile}
                disabled={disabledBtn}
                className={`bg-success-default hover:bg-success-hover ${
                    disabledBtn ? 'grayscale' : ''
                }`}
                onMouseEnter={() => betInput.onBetButtonHover('up')}
                onMouseLeave={() => betInput.onBetButtonHover(null)}
                onClick={() => betInput.takeBetUp()}>
                {arrowUpIcon && (
                    <img
                        src={arrowUpIcon}
                        alt={'Arrow up'}
                        width={16}
                        height={16}
                        className={buttonStyles.arrows}
                    />
                )}
                {t('betInput.higher')}
            </Button>
            <Button
                variant="trade"
                name="bet-down"
                isMobile={store.device.isMobile}
                disabled={disabledBtn}
                className={`!bg-error-default  hover:!bg-error-hover ${
                    disabledBtn ? 'grayscale' : ''
                }`}
                onMouseEnter={() => betInput.onBetButtonHover('down')}
                onMouseLeave={() => betInput.onBetButtonHover(null)}
                onClick={() => betInput.takeBetDown()}>
                {arrowDownIcon && (
                    <img
                        src={arrowDownIcon}
                        alt={'Arrow down'}
                        width={16}
                        height={16}
                        className={buttonStyles.arrows}
                    />
                )}
                {t('betInput.lower')}
            </Button>
        </div>
    );
});
