import React from 'react';
import { optionsStyles } from './Options.css';
import { OptionsProps } from './Options.types';

export const Options = <T,>({ items, onClick, active }: OptionsProps<T>) => {
    return (
        <div className={optionsStyles.container}>
            {items.map(item => {
                return (
                    <button
                        key={item.key}
                        name={item.name}
                        className={optionsStyles.option(active === item.value)}
                        onClick={() => onClick(item.value)}>
                        {item.label}
                    </button>
                );
            })}
        </div>
    );
};

Options.displayName = 'Options';
