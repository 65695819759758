import { observer } from 'mobx-react-lite';
import React from 'react';
import { Amount } from './Amount/Amount';
import { Period } from './Period/Period';

export const BetInput = observer(() => {
    return (
        <div className="grid grid-cols-mobile-input gap-2 mt-4">
            <Amount />
            <Period />
        </div>
    );
});
