import { Modal, ModalsStoreType } from '../ModalsStore.types';
let lastModalId = 0;
export const getBaseModal = <T, R>(
    modals: ModalsStoreType,
    type: T,
): Modal<T, R> => {
    let _resolver: (status: R) => void;

    const done = new Promise<R>(resolver => {
        _resolver = resolver;
    });

    return {
        id: lastModalId++,
        type,
        resolve(status: R) {
            _resolver(status);
            modals.remove(this);
        },
        done,
    };
};
