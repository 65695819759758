import { init } from '@elastic/apm-rum';

init({
    active: true,
    serviceName: 'logium-v2-frontend',
    serverUrl: 'https://rum.logium.org',
    serviceVersion: __GIT_HASH__ + ':v2demo',
    distributedTracing: true,
    distributedTracingOrigins: ['http', 'https'],
    apiVersion: 3,
    serverUrlPrefix: '/gin',
    environment: 'development',
    breakdownMetrics: true,
    centralConfig: false,
});
