import { makeAutoObservable } from 'mobx';
import {
    ModalsStoreType,
    StatusModalType,
    StatusPropsType,
} from '../ModalsStore.types';
import { getBaseModal } from './baseModal';

export const getStatusModal = (
    modals: ModalsStoreType,
    props: StatusPropsType,
): StatusModalType =>
    makeAutoObservable({
        ...getBaseModal(modals, 'status'),
        props,
    });
