import { RootStoreType } from '@/RootStoreTypes';
import { BetType } from '@/bets/BetsStore.types';
import { Subscription } from 'rxjs';
import {
    AnyToast,
    BetCreatedType,
    BetResolvedType,
    MultipleBetsCreatedType,
    MultipleBetsResolvedType,
    ToastsStoreType,
} from './ToastsStore.types';
import { betCreated } from './toast/betCreated';
import { betIncorrectAmount } from './toast/betIncorrectAmount';
import { betInsufficientFunds } from './toast/betInsufficientFunds';
import { betResolved } from './toast/betResolved';
import { multipleBetsCreated } from './toast/multipleBetsCreated';
import { multipleBetsResolved } from './toast/multipleBetsResolved';
import { serverError } from './toast/serverError';

export class ToastsStore implements ToastsStoreType {
    defaultTimeoutDelay;
    queue: AnyToast[] = [];
    counters = new Map<number, number>();

    subscriptions: Subscription[] = [];

    constructor(private root: RootStoreType) {
        this.defaultTimeoutDelay =
            this.root.config.defaultValues.toasts.defaultDelay;
        this.createSubscription();
        this.resolveSubscription();
    }

    private createSubscription(): void {
        const subscription = this.root.bets?.betAdded$.subscribe({
            next: bet => {
                const lastBetCreated = this.getToastOfSameType<BetCreatedType>(
                    'betCreated',
                    bet,
                );

                const lastMultipleBetsCreated =
                    this.getToastOfSameType<MultipleBetsCreatedType>(
                        'multipleBetsCreated',
                        bet,
                    );

                const lastToast = lastBetCreated ?? lastMultipleBetsCreated;

                if (lastToast) {
                    this.remove(lastToast.id);
                    this.add(
                        multipleBetsCreated(
                            this,
                            bet,
                            lastToast,
                            this.getCount(lastToast.id),
                            bet.asset?.decimals,
                        ),
                    );
                } else {
                    this.add(betCreated(this, bet, bet.asset?.decimals));
                }
            },
        });

        this.subscriptions.push(subscription);
    }

    private resolveSubscription(): void {
        const subscription = this.root.bets?.betResolved$.subscribe({
            next: bet => {
                const lastBetResolved =
                    this.getToastOfSameType<BetResolvedType>(
                        'betResolved',
                        bet,
                    );

                const lastMultipleBetsResolved =
                    this.getToastOfSameType<MultipleBetsResolvedType>(
                        'multipleBetsResolved',
                        bet,
                    );

                const lastToast = lastBetResolved ?? lastMultipleBetsResolved;

                if (lastToast) {
                    this.remove(lastToast.id);
                    this.add(
                        multipleBetsResolved(
                            this,
                            bet,
                            lastToast,
                            this.getCount(lastToast.id),
                            bet.asset?.decimals,
                        ),
                    );
                } else {
                    this.add(betResolved(this, bet, bet.asset?.decimals));
                }
            },
        });
        this.subscriptions.push(subscription);
    }

    private getCount(id: number) {
        let counter = this.counters.get(id);

        if (!counter) counter = 2;
        else counter++;

        this.counters.set(id, counter);

        return counter;
    }

    private getToastOfSameType<T extends AnyToast>(
        type:
            | 'betCreated'
            | 'multipleBetsCreated'
            | 'betResolved'
            | 'multipleBetsResolved',
        bet: BetType,
    ) {
        const isUp = bet.direction === 'up';

        return this.queue.find(
            toast =>
                toast.type === type &&
                toast.symbol === bet.market?.formattedSymbol &&
                toast.isUp === isUp,
        ) as T;
    }

    add<T extends AnyToast>(toast: T) {
        this.queue.push(toast);
    }

    remove(id: number) {
        const idx = this.queue.findIndex(candidate => candidate.id === id);

        if (idx >= 0) {
            clearTimeout(this.queue[idx].timeoutId);

            this.queue.splice(idx, 1);
        } else {
            throw new Error('Toast not found in queue');
        }
    }

    clearTimeout(id: number) {
        const idx = this.queue.findIndex(candidate => candidate.id === id);

        if (idx >= 0) {
            clearTimeout(this.queue[idx].timeoutId);
        }
    }

    destroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }

    betIncorrectAmount(min: bigint, max: bigint, decimals: number) {
        return this.add(betIncorrectAmount(this, min, max, decimals));
    }

    betInsufficientFunds(needed: bigint, decimals: number) {
        return this.add(betInsufficientFunds(this, needed, decimals));
    }

    serverError() {
        return this.add(serverError(this));
    }
}
