import newsletterStatusIcon from '@/assets/icons/newsletterStatus.svg';
import {
    NewsletterModalType,
    StatusModalType,
} from '@/modals/ModalsStore.types';
import { RootStoreType } from '@/RootStoreTypes';
import { attachScript } from '@/tools/attachScript';
import { NewsletterStoreType, NewsletterWindow } from './NewsletterStore.types';

export class NewsletterStore implements NewsletterStoreType {
    newsletterModal: NewsletterModalType | undefined;
    scriptElements: HTMLScriptElement[] = [];

    constructor(private root: RootStoreType, private window: NewsletterWindow) {
        this.attachScript(
            this.root.config.defaultValues.newsletter.successScript,
        );
        this.attachScript(this.root.config.defaultValues.newsletter.endpoint);
        this.attachSuccessFunction();
    }

    private attachSuccessFunction() {
        this.window.newsletterResolve = () => {
            this.newsletterModal?.resolve();

            this.root.ui.modals.status({
                modalHeading: 'newsletterStatus.modalHeading',
                icon: newsletterStatusIcon,
                heading: 'newsletterStatus.heading',
                text: 'newsletterStatus.text',
                primaryBtn: {
                    text: 'newsletterStatus.primaryBtn',
                    callback: (modal: StatusModalType) => {
                        modal.resolve();
                    },
                },
                secondaryBtn: {
                    text: 'newsletterStatus.secondaryBtn',
                    link: this.root.config.defaultValues.logiumBets,
                },
            });
        };
    }

    private attachScript(url: string) {
        this.scriptElements.push(attachScript(url));
    }

    openModal() {
        this.newsletterModal = this.root.ui.modals.newsletter();
    }

    destroy() {
        this.scriptElements.forEach(script => {
            document.body.removeChild(script);
        });
    }
}
