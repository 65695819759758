import React from 'react';
import { infoRowsStyles } from './InfoRows.css';
import { InfoRowsProps } from './InfoRows.types';

export const InfoRows = ({ rows }: InfoRowsProps) => {
    return (
        <div className={infoRowsStyles.container}>
            {rows.map(row => (
                <div className={infoRowsStyles.row} key={row.label?.toString()}>
                    <span>{row.label}</span>
                    <div>
                        <span
                            className={infoRowsStyles.value(
                                row.value !== '-' && row.value !== undefined,
                            )}>
                            {row.value ?? '-'}
                        </span>
                        {row.currency && <span> {row.currency}</span>}
                    </div>
                </div>
            ))}
        </div>
    );
};
