import { useMemo } from 'react';

export const px = (n: number) => `${n / 16}rem`;

export const joinClassnames = (classnames: string[]) => {
    return classnames.join(' ');
};

export const className = (...args: Array<string | null | undefined>) =>
    args.filter(x => !!x).join(' ');

export const useClassName = (...args: Array<string | null | undefined>) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => className(...args), args);
