import { startRouter } from 'mobx-router';
import { setup } from '../../server/setup';
import routes from './config/routes';
import { rootStoreFactory } from './di-mobx';
import './rum';
import { ui } from './view';

(async () => {
    setup();

    const store = await rootStoreFactory();

    window.store = store;

    startRouter(routes, store, {
        notfound() {
            store.router.goTo(routes.notFound);
        },
    });

    ui(store);

    store.useCases.initialize();
})();
