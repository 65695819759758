import { RootStoreType } from '@/RootStoreTypes';
import { BetStatus, Direction } from '@/bets/BetsStore.types';
import { getResolutionInSeconds } from '@/tools/chart';
import { EntityId, IChartWidgetApi, ShapePoint } from '@charting-library/';
import { LabelPosition } from '../LabelPosition/LabelPosition';
import { LabelPositionType } from '../LabelPosition/LabelPosition.types';
import { PointType, StrikePointType } from './StrikePoint.types';

export class StrikePoint implements StrikePointType {
    private firstLine: EntityId | null = null;
    private secondLine: EntityId | null = null;
    private originalStrikePoints: Map<string, PointType> = new Map();
    private strikePoints: Map<string, PointType> = new Map();
    private labelPosition: LabelPositionType;

    private consts = (zOrder: 'top' | 'bottom' = 'bottom') => {
        return {
            lock: true,
            disableSelection: true,
            disableSave: true,
            disableUndo: true,
            showInObjectsTree: false,
            zOrder,
        };
    };

    constructor(private root: RootStoreType, private chart: IChartWidgetApi) {
        this.labelPosition = new LabelPosition(this.root, this.chart);
    }

    get period() {
        return getResolutionInSeconds(this.chart.resolution());
    }

    private getLabelTime(time: number) {
        return time - 6 * this.period;
    }

    private getColor(isPositive: boolean) {
        return isPositive ? '#37AD80' : '#EB2E2E';
    }

    private getDirectionIcon(direction: Direction) {
        return direction === 'up' ? '▲' : '▼';
    }

    private getStatusIcon(isWinning: boolean) {
        return isWinning ? '✓' : '✕';
    }

    private getShape(id: EntityId) {
        return this.chart.getShapeById(id);
    }

    private getShapePoints(id: EntityId) {
        return this.getShape(id).getPoints();
    }

    private setShapePoints(id: EntityId, points: ShapePoint[]) {
        this.getShape(id).setPoints(points);
    }

    private setShapeProperties(id: EntityId, properties: object) {
        this.getShape(id).setProperties(properties);
    }

    private getLabelPoint(time: number, price: number) {
        return {
            time: this.getLabelTime(time),
            price,
        };
    }

    private createVerticalLine(
        time: number,
        color: string,
        linestyle: number,
    ): EntityId | null {
        return this.chart.createMultipointShape(
            [
                {
                    time,
                },
            ],
            {
                shape: 'vertical_line',
                ...this.consts(),
                overrides: {
                    showTime: false,
                    linecolor: color,
                    linestyle,
                },
            },
        );
    }

    private createDot(time: number, price: number): EntityId | null {
        return this.chart.createMultipointShape([{ time, price }], {
            shape: 'icon',
            ...this.consts('top'),
            overrides: {
                size: 9,
                color: '#ffffff',
                icon: '0xf111',
            },
        });
    }

    private createLabel(
        time: number,
        price: number,
        color: string,
        icon: string,
        text: string,
    ): EntityId | null {
        const labelPoint = this.getLabelPoint(time, price);

        return this.chart.createMultipointShape([labelPoint, labelPoint], {
            shape: 'callout',
            ...this.consts('top'),
            overrides: {
                backgroundColor: color,
                bordercolor: color,
                drawBorder: true,
                linewidth: 0,
                transparency: 0,
                fontSize: 10,
                font: 'Arial',
                text: `${icon} $${text}`,
            },
        });
    }

    private createSolidLine(
        from: number,
        to: number,
        price: number,
        color: string,
    ) {
        return this.chart.createMultipointShape(
            [this.getLabelPoint(from, price), { time: to, price }],
            {
                shape: 'trend_line',
                ...this.consts('top'),
                overrides: {
                    linestyle: 0,
                    linecolor: color,
                },
            },
        );
    }

    private createDashedLine(from: number, price: number, color: string) {
        return this.chart.createMultipointShape([{ time: from, price }], {
            shape: 'horizontal_ray',
            ...this.consts('top'),
            overrides: {
                linestyle: 2,
                linecolor: color,
            },
        });
    }

    private removeStrikePoint(sp: PointType) {
        this.chart.removeEntity(sp.dot);
        this.chart.removeEntity(sp.dashedLine);
        this.chart.removeEntity(sp.solidLine);
        this.chart.removeEntity(sp.label);
    }

    drawStrikePeriod(from: number, to: number) {
        this.clearStrikePeriod();
        this.firstLine = this.createVerticalLine(from, '#5F72A5', 2);
        this.secondLine = this.createVerticalLine(to, '#6073A5', 0);
    }

    drawStrikePoint(
        id: string,
        from: number,
        to: number,
        price: number,
        direction: Direction,
        text: string,
    ) {
        const color = this.getColor(direction === 'up');
        const icon = this.getDirectionIcon(direction);

        const solidLine = this.createSolidLine(from, to, price, color);
        const dashedLine = this.createDashedLine(to, price, color);
        const label = this.createLabel(from, price, color, icon, text);
        const dot = this.createDot(to, price);

        if (dot && label && solidLine && dashedLine)
            this.originalStrikePoints.set(id, {
                dot,
                label,
                solidLine,
                dashedLine,
            });

        this.strikePoints = this.labelPosition.generate(
            this.originalStrikePoints,
            this.period,
        );
    }

    updateStrikePointPosition(
        id: string,
        from: number,
        to: number,
        price: number,
    ) {
        const strikePoint = this.strikePoints.get(id);

        if (strikePoint && this.getShapePoints(strikePoint.dot).length) {
            this.setShapePoints(strikePoint.dashedLine, [{ time: to, price }]);

            this.setShapePoints(strikePoint.dot, [{ time: to, price }]);
        }
    }

    updateStrikePeriodPosition(from: number, to: number) {
        if (this.firstLine && this.getShapePoints(this.firstLine).length) {
            this.setShapePoints(this.firstLine, [{ time: from }]);
        }
        if (this.secondLine && this.getShapePoints(this.secondLine).length) {
            this.setShapePoints(this.secondLine, [{ time: to }]);
        }
    }

    clearStrikePeriod() {
        if (this.firstLine) {
            this.chart.removeEntity(this.firstLine);
            this.firstLine = null;
        }
        if (this.secondLine) {
            this.chart.removeEntity(this.secondLine);
            this.secondLine = null;
        }
    }

    updateLabelProperties(id: string, status: BetStatus, text: string) {
        const strikePoint = this.strikePoints.get(id);

        if (strikePoint) {
            const border = this.getColor(status === 'win');
            const icon = this.getStatusIcon(status === 'win');

            this.setShapeProperties(strikePoint.label, {
                backgroundColor: '#1C1F28',
                bordercolor: border,
                text: `${icon} $${text}`,
            });
        }
    }

    clearStrikePointDot(id: string) {
        const strikePoint = this.strikePoints.get(id);

        if (strikePoint) {
            this.chart.removeEntity(strikePoint.dot);
            this.chart.removeEntity(strikePoint.dashedLine);
            this.chart.removeEntity(strikePoint.solidLine);
        }
    }

    clearStrikePointLabel(id: string) {
        const strikePoint = this.strikePoints.get(id);

        if (strikePoint) {
            this.chart.removeEntity(strikePoint.label);
        }
    }

    deleteStrikePoint(id: string) {
        this.originalStrikePoints.delete(id);
        this.strikePoints.delete(id);
    }

    clearStrikePoint(id: string) {
        const strikePoint = this.strikePoints.get(id);

        if (strikePoint) {
            this.removeStrikePoint(strikePoint);
            this.deleteStrikePoint(id);
        }
    }

    clearAllStrikePoints() {
        this.strikePoints.forEach(sp => {
            this.removeStrikePoint(sp);
        });
        this.strikePoints.clear();
        this.originalStrikePoints.clear();
    }

    updateLabelsPositions(barSpacing?: number) {
        this.strikePoints = this.labelPosition.generate(
            this.originalStrikePoints,
            this.period,
            barSpacing,
            true,
        );
    }

    destroy() {
        try {
            this.clearStrikePeriod();
            this.clearAllStrikePoints();
        } catch {
            // do nothing
        }
    }
}
