import { Dropdown } from '@/components/Dropdown/Dropdown';
import { MobileOverlay } from '@/components/MobileOverlay/MobileOverlay';
import { NavigationTabs } from '@/components/NavigationTabs/NavigationTabs';
import { BetConfigType } from '@/markets/MarketsStore.types';
import { getPeriodLabel } from '@/tools/getPeriodLabel/getPeriodLabel';
import { useOnClickOutside } from '@/tools/hooks/useOnClickOutside';
import { useStore } from '@/view/storeContext';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef, useState } from 'react';

export const Period = observer(() => {
    const store = useStore();
    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState(false);

    if (!store) {
        return null;
    }

    const betInput = store.ui.betsPage.betInput;

    const activePeriodLabel = betInput.period
        ? getPeriodLabel(betInput.period)
        : null;

    const items = betInput?.betConfigs.map(item => {
        return {
            value: item,
            label: getPeriodLabel(item.betDurationSec),
        };
    });

    const switchOpen = useCallback(() => {
        setOpen(prev => !prev);
    }, []);

    const handleClick = useCallback(
        (value: BetConfigType) => {
            switchOpen();
            betInput.setBetConfig(value);
        },
        [betInput, switchOpen],
    );

    useOnClickOutside(ref, () => {
        setOpen(false);
    });

    return (
        <div ref={ref}>
            <Dropdown
                activeEl={activePeriodLabel}
                onClick={switchOpen}
                isOpen={isOpen}
            />
            <NavigationTabs
                items={items}
                onClick={handleClick}
                active={betInput.betConfig}
                getKey={item => item.id}
                isOpen={isOpen}
                className="absolute bottom-full left-0 z-10"
            />
            <MobileOverlay active={isOpen} />
        </div>
    );
});
