import { joinClassnames } from '@/tools/css';

export const favoriteAssetsStyles = {
    container: joinClassnames([
        'px-4',
        'bg-greyscale-background',
        'w-full',
        'h-full',
        'flex',
        'items-center',
        'rounded-lg',
    ]),
    text: joinClassnames([
        'text-greyscale-placeholder',
        'text-xs',
        'font-normal',
        'mr-3',
    ]),
    icon: joinClassnames(['w-4', 'h-4', 'mx-0.5']),
};
