import { ConnectorName } from '@/wallet/WalletStore.types';
import { ModalsStoreType, SelectWalletModalType } from '../ModalsStore.types';
import { getBaseModal } from './baseModal';

export const getWalletSelectModal = (
    modals: ModalsStoreType,
    wallets: ConnectorName[],
): SelectWalletModalType => {
    return {
        ...getBaseModal(modals, 'selectWallet'),
        wallets,
    };
};
