import InfoIcon from '@/assets/icons/info.svg';
import { TFuncKey } from 'i18next';
import {
    BetInsufficientFundsType,
    ToastsStoreType,
} from '../ToastsStore.types';
import { getBaseToast } from './baseToast';

export const betInsufficientFunds = (
    toasts: ToastsStoreType,
    needed: bigint,
    decimals: number,
    timeoutDelay?: number,
): BetInsufficientFundsType => {
    const base = {
        type: 'betInsufficientFunds' as const,
        header: 'toasts.insufficientFundsHeader' as TFuncKey,
        icon: InfoIcon,
        borderColor: 'border-primary-medium',
        timeoutDelay,
    };

    return {
        ...getBaseToast(toasts, base),
        needed,
        decimals,
    };
};
