import { RootStoreType } from '@/RootStoreTypes';
import { merge, Subscription } from 'rxjs';
import { MobileNotificationStoreType } from './MobileNotificationStore.types';

export class MobileNotificationStore implements MobileNotificationStoreType {
    show = false;
    subscriptions: Subscription[] = [];

    constructor(private root: RootStoreType) {
        this.betSubscription();
    }

    setShow(show: boolean) {
        this.show = show;
    }

    private betSubscription() {
        const subscription = merge(
            this.root.bets.betAdded$,
            this.root.bets.betResolved$,
        ).subscribe({
            next: () => this.setShow(true),
        });
        this.subscriptions.push(subscription);
    }

    destroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }
}
