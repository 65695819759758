import { FavoriteMarket } from '@/components/FavoriteMarket';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FavoriteAssetProps } from './favoriteAsset.types';

export const FavoriteAsset = observer<FavoriteAssetProps>(({ market }) => {
    return (
        <FavoriteMarket
            name={market.market.formattedSymbol}
            onClick={() => market.select()}
            onClose={() => market.remove()}
            rate={market.market.rateSymbol}
            isActive={market.isSelected}
            isWinning={market.isWinning}
            volumeChange={market.volumeChange}
            decimals={market.market.asset?.decimals}
        />
    );
});

FavoriteAsset.displayName = 'FavoriteAsset';
