import { ProfileBadge } from '@/components/ProfileBadge/ProfileBadge';
import { formatVolume } from '@/tools/format';
import { useStore } from '@/view/storeContext';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const YourWallet = observer(() => {
    const store = useStore();
    const { t } = useTranslation();

    const profile = store?.ui.nav.profile;
    const user = store?.user.user;

    const handleClick = useCallback(async () => {
        store?.useCases.showAccountDetailsModal();
    }, [store?.useCases]);

    return (
        <div className="bg-greyscale-background p-4 rounded-lg">
            <span className="text-mobile-headers-medium text-greyscale-primary">
                {t('walletMobile.heading')}
            </span>
            <div className="my-6">
                <ProfileBadge
                    address={profile?.getWalletAddressStr(t)}
                    hasOpen={!!profile?.address || profile?.isDemo}
                    isOpen={profile?.isOpen}
                    handleClick={handleClick}
                />
            </div>
            {profile?.availableAssets?.map(asset => (
                <div
                    key={asset.id}
                    className="flex justify-between text-mobile-buttons-medium-light pt-6 pb-2 border-t border-greyscale-line text-greyscale-secondary">
                    <span className="text-left">
                        {t('walletMobile.balance')}
                    </span>
                    <div className="text-right">
                        <Trans
                            i18nKey="profilePopup.balance"
                            values={{
                                balance: formatVolume(
                                    user?.getBalance(asset),
                                    asset.decimals,
                                ),
                            }}
                            components={[
                                <span
                                    key={0}
                                    className="text-greyscale-primary"
                                />,
                            ]}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
});
