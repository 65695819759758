import { joinClassnames } from '@/tools/css';

const getContainerColor = (turnOn: boolean, blackAndWhite: boolean) => {
    if (turnOn && blackAndWhite) return 'bg-primary-defaultDivergent';
    if (turnOn) return 'bg-primary-medium';
    else return 'bg-greyscale-disabled';
};

const getIndicatorColor = (turnOn: boolean, blackAndWhite: boolean) => {
    if (turnOn && blackAndWhite) return 'bg-greyscale-background';
    if (turnOn) return 'bg-consts-white';
    else return 'bg-greyscale-background';
};

export const switchStyles = {
    container: (turnOn: boolean, blackAndWhite: boolean) =>
        joinClassnames([
            'flex',
            'items-center',
            turnOn ? 'justify-end' : 'justify-start',
            getContainerColor(turnOn, blackAndWhite),
            'relative',
            'w-8',
            'h-5',
            'p-0.5',
            'rounded-full',
            'cursor-pointer',
        ]),
    indicator: (turnOn: boolean, blackAndWhite) =>
        joinClassnames([
            'absolute',
            getIndicatorColor(turnOn, blackAndWhite),
            'w-4',
            'h-4',
            'rounded-full',
        ]),
};
