import { RootStoreType } from '@/RootStoreTypes';
import { HotjarStoreType, HotjarWindow } from './Hotjar.types';

/* eslint-disable prefer-rest-params */
export class HotJarStore implements HotjarStoreType {
    private window;
    private document;

    private hotjarId;
    private hotjarUrl;

    private script: HTMLScriptElement | null = null;

    constructor(
        private root: RootStoreType,
        hotjarWindow: HotjarWindow,
        hotjarDocument: Document,
    ) {
        this.window = hotjarWindow;
        this.document = hotjarDocument;
        this.hotjarId = this.root.config.analytics.hotjar.id;
        this.hotjarUrl = this.root.config.analytics.hotjar.url;
    }

    initialize(): void {
        ((h, o, t) => {
            h.hj =
                h.hj ||
                function () {
                    (h.hj.q = h.hj.q || []).push(arguments);
                };
            h._hjSettings = { hjid: this.hotjarId, hjsv: 6 };

            const head = o.getElementsByTagName('head')[0];
            const script = o.createElement('script');
            script.async = true;
            script.src =
                t + h._hjSettings.hjid + '.js?sv=' + h._hjSettings.hjsv;

            this.script = script;
            head.appendChild(script);
        })(this.window, this.document, this.hotjarUrl);
    }

    destroy(): void {
        if (this.script) document.head.removeChild(this.script);
    }
}
