import { joinClassnames } from '@/tools/css';

const getValueColor = (prefix: string, isProfit?: boolean) => {
    switch (isProfit) {
        case true:
            return `${prefix}-success-default`;
        case false:
            return `!${prefix}-error-default`;
        default:
            return `${prefix}-consts-white`;
    }
};

export const betStyles = {
    container: joinClassnames([
        'relative',
        'overflow-hidden',
        'bg-greyscale-input',
        'rounded-md',
        'text-consts-white',
        'font-medium',
        'text-xs',
        'p-3',
        'mb-2',
    ]),
    row: (isMobile: boolean | undefined) =>
        joinClassnames([
            'flex',
            'justify-between',
            isMobile ? 'first:mb-6' : 'first:mb-3',
        ]),
    info: joinClassnames(['flex']),
    symbol: (isMobile: boolean | undefined) =>
        joinClassnames([
            'font-semibold',
            'mr-1',
            'uppercase',
            isMobile ? 'text-mobile-labels-medium' : '',
        ]),
    icon: (isMobile: boolean | undefined) =>
        joinClassnames([isMobile ? 'w-3.5' : 'w-2', 'mr-1']),
    payout: (isMobile: boolean | undefined) =>
        joinClassnames([
            'text-greyscale-placeholder',
            isMobile ? 'text-mobile-labels-medium-table' : '',
        ]),
    item: (isMobile: boolean | undefined) =>
        joinClassnames([
            'flex',
            'flex-col',
            isMobile ? 'text-mobile-labels-medium-table' : '',
        ]),
    label: (align: 'left' | 'right') =>
        joinClassnames([
            'text-[#6B728E]',
            'mb-1',
            align === 'left' ? 'text-left' : 'text-right',
        ]),
    textValue: (isProfit?: boolean) => getValueColor('text', isProfit),
    line: (isProfit: boolean) =>
        joinClassnames([
            'absolute',
            'bottom-0',
            'left-0',
            'h-0.5',
            getValueColor('bg', isProfit),
            'transition-all',
        ]),
    period: (isMobile: boolean | undefined) =>
        joinClassnames([
            'flex',
            'items-center',
            isMobile ? 'text-mobile-labels-medium-table' : '',
        ]),
    clockIcon: 'mr-1',
};
