import { merge, Observable, of, Subject, switchMap } from 'rxjs';
import { MarketsStoreType, MarketType } from './MarketsStore.types';

export class MarketsStoreProxy implements MarketsStoreType {
    get markets() {
        return this.target.markets;
    }

    markets$: Observable<Map<string, MarketType>>;

    get marketsByFormattedSymbol() {
        return this.target.marketsByFormattedSymbol;
    }

    getMarketByConfigId(id: string) {
        return this.target.getMarketByConfigId(id);
    }

    destroy() {
        this.target.destroy();
    }

    get ready(): Promise<void> {
        return this.target.ready;
    }

    private target$ = new Subject<MarketsStoreType>();

    constructor(private target: MarketsStoreType) {
        this.markets$ = merge(of(this.target), this.target$).pipe(
            switchMap(target => target.markets$),
        );
    }

    setTarget(target: MarketsStoreType) {
        this.target.destroy();
        this.target = target;
        this.target$.next(target);
    }
}
