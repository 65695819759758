/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../common";
import type {
  Constants,
  ConstantsInterface,
} from "../../../contracts/libraries/Constants";

const _abi = [
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IERC20",
            name: "USDC",
            type: "address",
          },
        ],
        internalType: "struct Constants.ConstantsStruct",
        name: "constants",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
] as const;

const _bytecode =
  "0x60a0604052348015600f57600080fd5b506040516100f43803806100f4833981016040819052602c91603d565b516001600160a01b0316608052609f565b600060208284031215604e57600080fd5b604051602081016001600160401b0381118282101715607d57634e487b7160e01b600052604160045260246000fd5b60405282516001600160a01b0381168114609657600080fd5b81529392505050565b608051603f6100b560003960005050603f6000f3fe6080604052600080fdfea2646970667358221220e0a1a29309fc9121c7d84d589d006b11ceaf448622d1a9846ada85c28d253e0c64736f6c63430008110033";

type ConstantsConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: ConstantsConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class Constants__factory extends ContractFactory {
  constructor(...args: ConstantsConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    constants: Constants.ConstantsStructStruct,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<Constants> {
    return super.deploy(constants, overrides || {}) as Promise<Constants>;
  }
  override getDeployTransaction(
    constants: Constants.ConstantsStructStruct,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(constants, overrides || {});
  }
  override attach(address: string): Constants {
    return super.attach(address) as Constants;
  }
  override connect(signer: Signer): Constants__factory {
    return super.connect(signer) as Constants__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): ConstantsInterface {
    return new utils.Interface(_abi) as ConstantsInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): Constants {
    return new Contract(address, _abi, signerOrProvider) as Constants;
  }
}
