import { makeAutoObservable } from 'mobx';
import { MobileVersionModalType, ModalsStoreType } from '../ModalsStore.types';
import { getBaseModal } from './baseModal';

export const getMobileVersionModal = (
    modals: ModalsStoreType,
): MobileVersionModalType =>
    makeAutoObservable({
        ...getBaseModal(modals, 'mobileVersion'),
    });
