import { merge, Observable, of, Subject, switchMap } from 'rxjs';
import { AssetType, UserType } from './User.types';
import { UserStoreType } from './UserStore.types';

export class UserStoreProxy implements UserStoreType {
    private target$ = new Subject<UserStoreType>();

    constructor(private target: UserStoreType) {
        const target$ = merge(of(this.target), this.target$);
        this.user$ = target$.pipe(switchMap(target => target.user$));
    }

    setTarget(target: UserStoreType) {
        this.target.destroy();
        this.target = target;
        this.target$.next(target);
    }
    get user() {
        return this.target.user;
    }

    user$: Observable<UserType | null>;

    getBalance(asset: AssetType) {
        return this.target.getBalance(asset);
    }

    destroy() {
        this.target.destroy();
    }

    deposit(volume: bigint) {
        return this.target.deposit(volume);
    }

    withdraw(volume: bigint, assetId: number) {
        return this.target.withdraw(volume, assetId);
    }

    resetVolume() {
        this.target.resetVolume();
    }
}
