import { observer } from 'mobx-react-lite';
import React from 'react';
import { VirtualAccount } from './VirtualAccount/VirtualAccount';
import { YourWallet } from './YourWallet/YourWallet';

export const Wallet = observer(() => {
    return (
        <div className="bg-gradient-background h-full p-4 overflow-auto">
            <YourWallet />
            <VirtualAccount />
        </div>
    );
});
