import { ModalContainer } from '@/components/Modal/ModalContainer';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useStore } from '../storeContext';
import { StatusModalFooter } from './StatusModalFooter';

export const StatusModal: React.FC = observer(() => {
    const store = useStore();
    const { t } = useTranslation();

    const modal = store?.ui.modals.activeModal;

    if (modal && modal.type === 'status') {
        const {
            modalHeading,
            icon,
            heading,
            text,
            primaryBtn,
            secondaryBtn,
            textParams,
        } = modal.props;

        const onClose = useCallback(() => {
            modal.resolve();
        }, [modal]);

        return (
            <ModalContainer
                header={t(modalHeading).toString()}
                onClose={onClose}
                isMobile={store.device.isMobile}
                footer={
                    <StatusModalFooter
                        modal={modal}
                        primaryBtn={primaryBtn}
                        secondaryBtn={secondaryBtn}
                    />
                }>
                <div className="py-2 flex flex-1 items-center">
                    <div className="flex flex-col items-center px-7 w-full">
                        <div className="bg-greyscale-input w-12 h-12 flex items-center justify-center rounded-lg">
                            <img src={icon} alt="status" />
                        </div>
                        <h3 className="text-greyscale-primary font-semibold mt-4 mb-2 text-xs">
                            {t(heading).toString()}
                        </h3>
                        <p className="text-center text-greyscale-placeholder text-xs font-medium">
                            <Trans i18nKey={text} values={textParams} />
                        </p>
                    </div>
                </div>
            </ModalContainer>
        );
    } else {
        return null;
    }
});
