import { joinClassnames } from '@/tools/css';

export const noDataStyles = {
    container: (show: boolean) =>
        joinClassnames([
            'absolute',
            'inset-0',
            'bg-greyscale-background',
            'flex',
            'items-center',
            'justify-center',
            'flex-col',
            'transition-opacity',
            'p-4',
            show ? 'opacity-100' : 'opacity-0',
            show ? 'visible' : 'invisible',
        ]),
    iconContainer: joinClassnames([
        'w-10',
        'h-10',
        'rounded',
        'bg-greyscale-input',
        'flex',
        'items-center',
        'justify-center',
        'mb-4',
    ]),
    text: joinClassnames([
        'text-[#6B728E]',
        'text-xs',
        'font-medium',
        'text-center',
    ]),
};
