import { RootStoreType } from '@/RootStoreTypes';
import {
    combineLatest,
    mergeWith,
    Observable,
    of,
    share,
    Subject,
    Subscription,
    switchMap,
} from 'rxjs';
import { LogiumWalletConnector } from './LogiumWalletConnector';
import { MetamaskConnector } from './MetamaskConnector';
import {
    ConnectorName,
    WalletConnector,
    WalletInfo,
    WalletStoreType,
} from './WalletStore.types';

export class WalletStore implements WalletStoreType {
    static PreferredConnectorNameKey = 'PreferredConnectorName';

    static DefaultConnectorName = 'logiumWallet';

    address?: string = undefined;

    chainId?: number = undefined;

    walletInfoChange$: Observable<WalletInfo>;

    get signer() {
        return this.connector?.signer;
    }

    get name() {
        return this.connector?.name;
    }

    private connector?: WalletConnector<ConnectorName> = undefined;

    private connector$ = new Subject<
        WalletConnector<ConnectorName> | undefined
    >();

    private address$: Observable<string | undefined>;

    private chainId$: Observable<number | undefined>;

    private subscriptions: Subscription[];

    constructor(private root: RootStoreType) {
        this.address$ = this.connector$.pipe(
            switchMap(connector => connector?.account$ ?? of(undefined)),
        );
        this.chainId$ = this.connector$.pipe(
            switchMap(connector => connector?.chainId$ ?? of(undefined)),
        );
        this.walletInfoChange$ = this.connector$.pipe(
            switchMap(connector =>
                combineLatest({
                    signer: of(connector?.signer),
                    connectorName: of(connector?.name),
                    address: connector?.account$ ?? of(undefined),
                    chainId: connector?.chainId$ ?? of(undefined),
                }),
            ),
            share(),
        );

        this.subscriptions = [
            this.address$.subscribe(addr => this.setAddress(addr)),
            this.chainId$.subscribe(id => this.setChainId(id)),
        ];

        this.autoConnect();
    }

    private get walletInfo(): WalletInfo {
        return {
            connectorName: this.name,
            signer: this.signer,
            address: this.address,
            chainId: this.chainId,
        };
    }

    walletInfo$() {
        return this.walletInfoChange$.pipe(mergeWith(of(this.walletInfo)));
    }

    private async autoConnect() {
        const name = this.getPreferredConnectorName();
        const connector = this.getConnectorByName(name);
        if (await connector.isAuthorized()) {
            await this.setConnector(connector);
        }
    }
    getPreferredConnectorName() {
        return (
            this.root.storage.getItem<string>(
                WalletStore.PreferredConnectorNameKey,
            ) ?? WalletStore.DefaultConnectorName
        );
    }

    setPreferredConnectorName(name: string) {
        return this.root.storage.setItem(
            WalletStore.PreferredConnectorNameKey,
            name,
        );
    }

    isConnectorAllowed(name: string) {
        return this.root.config.supportedConnectors[this.root.mode].includes(
            name as ConnectorName,
        );
    }
    getConnectorByName(name: string) {
        if (!this.isConnectorAllowed(name)) {
            throw new Error('Connector is not allowed ' + name);
        }
        switch (name) {
            case 'logiumWallet':
                return new LogiumWalletConnector(this.root);
            case 'metamask':
                return new MetamaskConnector();
            default:
                throw new Error('Connector is not supported ' + name);
        }
    }

    destroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }

    private setAddress(address: string | undefined) {
        this.address = address;
    }

    private setChainId(chainId: number | undefined) {
        this.chainId = chainId;
    }

    disconnect() {
        this.connector = undefined;
        this.connector$.next(this.connector);
    }

    async connect(name: ConnectorName) {
        return this.setConnector(this.getConnectorByName(name));
    }
    async setConnector<N extends ConnectorName>(connector: WalletConnector<N>) {
        this.connector = connector;
        this.connector$.next(connector);
        await connector.connect();
        this.setPreferredConnectorName(connector.name);
    }
}
