import { joinClassnames } from '@/tools/css';

export const tableNavStyles = {
    container: joinClassnames([
        'pr-4',
        'flex',
        'items-center',
        'justify-between',
        'border-b',
        'border-greyscale-line',
    ]),
    tabs: joinClassnames(['flex', 'h-full']),
    iconWrapper: joinClassnames(['w-6', 'flex', 'justify-center']),
};
